import { api } from "./axios";

// Deposit req
export const APIMakeDepositRequest = async (payment_method_id, amount) => {
  var formData = new FormData();
  formData.append("payment_method_id", payment_method_id);
  formData.append("amount", amount);

  try {
    const res = await api.post("/player/deposit", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.status === 200) {
      return res;
    }
  } catch (e) {
    return e;
    if (
      e.response &&
      e.response.data.errors &&
      e.response.data.errors.attachment_url
    ) {
      return "ERR_FILE_FORMAT_INVALID";
    }
    // else if (
    //     e.response &&
    //     e.response.data.message === "PENDING_TRANSACTION"
    // ) {
    //     return "WAIT_PLEASE";
    // }
    else if (e.response && e.response.data.message === "IMAGE_STORING_FAILED") {
      return "IMAGE STORING FAILED";
    }
  }
  // return null;
};

// Al Transaction
export const APIGetAllTransactions = async (filterData, page, is_withdraw) => {
  try {
    let endpoint = `player/transactions?isWithdraw=${is_withdraw}`;
    const params = { page };
      //  console.log("dateee : ", filterData)
    if (filterData) {
      if (filterData.startDate && filterData.endDate) {
        params.from_date = filterData.startDate;
        params.to_date = filterData.endDate;
      }

      if (filterData.status) {
        params.status = filterData.status;
      }
    }

    const res = await api.get(endpoint, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });

    if (res?.data) {
      //  console.log("response : ", res)
      return res;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
  return null;
};

export const APICheckTransaction = async (transactionId) => {
  try {
    const res = await api.get("/account/transaction_status/" + transactionId, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.data && res.data) {
      if (res.data.account) {
        return true;
      }
      return false;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const APILatestTransaction = async () => {
  try {
    const res = await api.get("/account/latest_transaction", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res && res.data) {
      return res;
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

// Single Transaction
export const APIGetSingleTransaction = async (transactionId) => {
  try {
    const res = await api.get(`player/transactions/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
  return false;
};
export const depositAllowed = async () => {
  try {
    const res = await api.get("player/check/allow/deposit", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    console.log("e is", e);
    return e;
  }
};

// for deposit payment categories and methods
export const paymentMethodsAndCategories = async () => {
  try {
    const res = await api.get("player/payment/methods", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    console.log("e is", e);
    return e;
  }
};

// check withdraw allowed
export const withdrawAllowed = async () => {
  try {
    const res = await api.get("player/check/allow/withdraw", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    console.log("e is", e);
    return e;
  }
};

export const WithdrawAPI = async (user_payment_method_id, amount) => {
  var formData = new FormData();
  formData.append("user_payment_method_id", user_payment_method_id);
  formData.append("amount", amount);

  const token = localStorage.getItem("auth_token");
  try {
    const res = await api.post("/player/withdraw", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data) {
      return res;
    }
  } catch (e) {
    // console.log('e is' , e)
    return e;
    // if (e.response.data?.message === "PLAYER_NOT_ALLOWED_TO_WITHDRAW") {
    //     return "PLAYER_NOT_ALLOWED_TO_WITHDRAW";
    // }
    // if (e.response.data?.message === "INSUFFICIENT_BALANCE") {
    //     return "INSUFFICIENT_BALANCE";
    // } else if (e.response?.status === 403) {
    //     return "WAIT_PLEASE";
    // } else if (e.response?.status === 401) {
    //     return "MAKE_DEPOSIT_REQUEST_FIRST";
    // } else {
    //     // console.log(e);
    // }
  }
  return null;
};

export const WithdrawStatusAPI = async () => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = await api.get("/player/otp/withdraw/check-first", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data) {
      return res;
    }
  } catch (e) {
    return e;
  }
  return null;
};

export const GenerateOtpAPI = async ({ body }) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = await api.post("/player/generate-otp", body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data) {
      return res;
    }
  } catch (e) {
    return e;
  }
  return null;
};

export const VerifyOtpAPI = async ({ body }) => {
  const token = localStorage.getItem("auth_token");
  try {
    const res = await api.post("/player/verify-otp", body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.data) {
      return res;
    }
  } catch (e) {
    return e;
  }
  return null;
};

// for add Wallet apis

export const paymentCategoriesApi = async () => {
  try {
    const res = await api.get("player/payment/categories", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    console.log("e is", e);
    return e;
  }
};

export const paymentMethodsApi = async (categoryId) => {
  try {
    const res = await api.get(`player/bank/codes/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    console.log("e is", e);
    return e;
  }
};

export const APIMakePaymentMethod = async (
  payment_category_id,
  bank_code_id,
  account_number
) => {
  var formData = new FormData();
  formData.append("bank_code_id", Number(bank_code_id));
  formData.append("payment_category_id", Number(payment_category_id));
  formData.append("account_number", account_number);

  try {
    const res = await api.post("player/user/payment/method/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    return e;
  }
};

// for withdraw
export const getWithdrawPaymentMethodApi = async () => {
  try {
    const res = await api.get("player/user/payment/method/list", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    console.log("e is", e);
    return e;
  }
};

export const singleTransactionStatusCheck = async (id) => {
  try {
    const res = await api.get(`/account/transaction/status/check/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200 && res.data) {
      return res.data;
    }
  } catch (e) {
    // console.log(e);
    // return e
  }
  return false;
};
