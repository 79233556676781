import React, { useCallback, useEffect, useState } from "react";
import styles from "./SearchBox.module.css"; // Make sure to import your CSS file
import { FaSearch } from "react-icons/fa"; // Using react-icons for the search icon
import CustomSearchDropDown from "./Filter/CustomSearchDropDown";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const SearchBox = ({
  platforms,
  multipleSelectedPlatforms,
  setMulitpleSelectedPlatforms,
  selectedPlatforms,
  setSelectedPlatforms,
  setSearchData,
  setGames,
  setUpdateAllPlatForm,
  HandleSearchButton,
  searchData,
}) => {
  // const [searchWord, setSearchWord] = useState();
  const HandleSearch = (value) => {
    if (value !== "") {
      // debouncedSearch(value);
      setSearchData(value);
      //   HandleSearchButton(value);
      // setGames([]);
    } else {
      setSearchData(value);
      HandleSearchButton();
      // setGames(displayGames);
      // setSelectedPlatforms([]);
    }
  };

  useEffect(() => {
    debouncedSearch(searchData);
  }, [searchData]);

  const debouncedSearch = useCallback(debounce(HandleSearchButton, 500), []);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      HandleSearchButton(e.target.value); // Call the API when Enter key is pressed
    }
  };
  return (
    <div
      className={styles.searchboxMainWrapper}
      style={{ justifyContent: platforms?.length < 5 ? "flex-end" : "" }}
    >
      <div className={styles.searchBox}>
        <FaSearch className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search for games..."
          value={searchData}
          onChange={(e) => HandleSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          allowClear
        />
      </div>
      {/* {platforms.length > 0 && (
        <CustomSearchDropDown
          title={"Provider"}
          platforms={platforms}
          multipleSelectedPlatforms={multipleSelectedPlatforms}
          setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
          selectedPlatforms={selectedPlatforms}
          setSelectedPlatforms={setSelectedPlatforms}
          setSearchData={setSearchData}
          setGames={setGames}
          setUpdateAllPlatForm={setUpdateAllPlatForm}
        />
      )} */}
    </div>
  );
};

export default SearchBox;
