import { api } from "./axios";
// Account providers List API

export const getCheckLevelBasedOnPoints = async () => {
  try {
    const res = await api.get(`player/level/checkLevelBasedOnPoints`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};
