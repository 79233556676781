import { api } from "./axios";
import axios from "axios";
let cancelTokens = {};

const createCancelToken = (apiName) => {
  cancelTokens[apiName] = axios.CancelToken.source();
};

const cancelPreviousRequest = (apiName) => {
  if (cancelTokens[apiName]) {
    cancelTokens[apiName].cancel("Operation canceled due to new request.");
  }
};

const getRequestConfig = (apiName) => {
  return {
    cancelToken: cancelTokens[apiName].token,
  };
};

// Home providers List API
export const getGameProviders = async (gameCategoryId, currency_id) => {
  const apiName = "getGameProviders";
  cancelPreviousRequest(apiName);
  createCancelToken(apiName);
  try {
    const res = await api.get(
      `providers/getbycategory/${gameCategoryId}?currency=${currency_id}`,
      getRequestConfig(apiName)
    );
    if (res.data && res.data) {
      return res.data;
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return [];
    } else if (e?.response?.status === 500) {
      return "NETWORK_ERROR";
    }
    return e;
  }
  return null;
};

// Home category List API
export const getGameCategories = async (id) => {
  try {
    const res = await api.get(`player/game_categories?currency=${id}`);
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    if (e.response && e.response.status === 500) {
      return "NETWORK_ERROR";
    }
    return e;
  }
  return null;
};

export const getCatergoriesWithGames = async (
  gameCategoryId,
  currency,
  page,
  platformId,
  gameName
) => {
  const apiName = "getCatergoriesWithGames";
  cancelPreviousRequest(apiName);
  createCancelToken(apiName);

  const queryParams = {
    currency: currency,
    category_id: gameCategoryId,
    page: page,
  };

  if (platformId) {
    queryParams.platform_ids = platformId;
  }

  if (gameName) {
    queryParams.game_name = gameName;
  }

  try {
    const res = await api.get(`player/game_category_details?is_desktop=1`, {
      params: queryParams,
      ...getRequestConfig(apiName),
    });
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return null;
    } else if (e.response && e.response.status === 500) {
      return "NETWORK_ERROR";
    }
    return e;
  }
  return null;
};

// Play Game Request API
export const APIPlayGame = async (id) => {
  try {
    const res = await api.post(
      "player/game/login",
      { game_id: id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    return e;
    // if (e.response.data.message == "INSUFFICIENT_BALANCE") {
    //     return "insufficientBalance";
    // }else if (e.response.data.message == "PENDING_TRANSACTION") {
    //     return "PENDING_TRANSACTION";
    // } else if (e.response.data.message == "BALANCE_NETWORK_ERROR") {
    //     return "BALANCE_NETWORK_ERROR";
    // }  else {
    //     console.log(e);
    // }
  }
  return null;
};

export const getGameItem = async (categoryId, platformId) => {
  try {
    const res = await api.post("player/gameItem", {
      categoryId,
      platformId,
    });
    return res.data;
  } catch (e) {
    console.log(e);
  }
  return null;
};
