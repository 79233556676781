import React from "react";
import styles from "./PageNotFound.module.css";
import PageNotFoundImg from "../../../assets/images/PageNotFound/pagenotfound.png";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoBackHome = () => {
    navigate("/");
    window.location.reload();
  };
  return (
    <div className={`${styles.modalOverlay}`}>
      <div className={styles.pagenotfoundSectionWrapper}>
        <div>
          <div className={styles.imgWrapper}>
            <img src={PageNotFoundImg} alt="logo" />
          </div>
          <div className={styles.goBackHomePageText} onClick={handleGoBackHome}>
            GO BACK TO HOME PAGE
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
