import React, { useEffect, useState } from "react";
import styles from "./OtpPopup.module.css";
import InputField from "../../../shared-components/inputField/InputField";
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";
import editPng from "../../../../assets/Edit@2x.png";
import { GenerateOtpAPI, VerifyOtpAPI } from "../../../../Api/TransactionApi";
import PhoneIcon from "../../../../assets/Language.png";
import BangladeshIcon from "../../../../assets/images/flags/Bangladesh.png";
import { IoArrowBack } from "react-icons/io5";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useTranslation } from "react-i18next";
import clientConfig from "../../../../config.json";
import { useSelector } from "react-redux";

const inputBox = {
  width: "100%",
  height: "50px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontSize: "18px",
  lineHeight: "19px",
  fontFamily: "Roboto",
  letterSpacing: 0,
  color: "#FFFFFF",
};

const otpInputStyle = {
  width: "50px",
  height: "50px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "center",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontSize: "18px",
  lineHeight: "19px",
  fontFamily: "Roboto",
  letterSpacing: 0,
  color: "#FFFFFF",
  marginRight: "10px",
};

const flagIcons = {
  PHP: PhoneIcon,
  BDT: BangladeshIcon,
};

const OtpPopupModal = ({ hideModal, phone, setPhone, setFinalSuccess }) => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const country_code =
    userInfoReduxData?.currency === "BDT"
      ? "+880"
      : userInfoReduxData?.currency === "PHP"
      ? "+63"
      : clientConfig?.countryCode;
  const [isEdit, setIsEdit] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [timerExpired, setTimerExpired] = useState(false);
  const { t } = useTranslation();
  const areAllOtpValuesFilled = otpValues.every((value) => value !== "");

  const getFlagIcon = () => {
    if (userInfoReduxData?.currency === "BDT") {
      return flagIcons["BDT"];
    } else if (userInfoReduxData?.currency === "PHP") {
      return flagIcons["PHP"];
    }
    return null;
  };

  const handleInputChange = (name, value) => {
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  const handleGenerateOtp = async () => {
    setLoading(true);
    setTimer();
    setError("");
    let body = {
      phone_number: `${country_code.slice(1)}${phone}`,
    };
    try {
      const data = await GenerateOtpAPI({ body });

      setLoading(false);
      if (data.status) {
        setTimer(59);
        setOtpSent(true);
      } else {
        // console.log("x", data);
        if (data?.response?.data?.message === "NUMBER_ALREADY_REGISTERED") {
          setError(t(`Number is already registered!`));
        } else {
          setError(t("Failed to send OTP, please try again."));
          setTimeout(() => {
            hideModal();
            setFinalSuccess(false);
            setOtpSent(false);
            setOtpValues(["", "", "", ""]);
            setTimer(59);
            setError("");
            setSuccessMessage("");
          }, 2000);
        }
      }
    } catch (error) {
      console.log("s", error);
      setLoading(false);
      setError(t("Failed to send OTP, please try again."));
      setTimeout(() => {
        hideModal();
        setFinalSuccess(false);
        setOtpSent(false);
        setOtpValues(["", "", "", ""]);
        setTimer(59);
        setError("");
        setSuccessMessage("");
      }, 2000);
      console.error("Error generating OTP", error);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    const otp = otpValues.join("");
    let body = {
      phone_number: `${country_code.slice(1)}${phone}`,
      otp: otp,
    };

    try {
      const data = await VerifyOtpAPI({ body });
      if (data.status) {
        setSuccessMessage(t("Verified successfully"));
        setLoading(true);
        setTimeout(() => {
          hideModal();
          setFinalSuccess(true);
          setOtpSent(false);
          setOtpValues(["", "", "", ""]);
          setTimer(59);
          setError("");
          setSuccessMessage("");
        }, 2000);
      } else {
        setLoading(false);
        setError(t("Invalid OTP, please try again."));

        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error verifying OTP", error);
    }
  };

  const handleResendOtp = async () => {
    setTimerExpired(false);
    await handleGenerateOtp();
    setOtpValues(["", "", "", ""]);

    setResendMessage(t("Code sent again!"));
    setTimeout(() => {
      setResendMessage("");
    }, 2000);
  };

  const handleOtpChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);

      // Move to next input field if a digit was entered
      if (value && index < 3) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleOtpKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otpValues[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    let interval = null;

    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setError(t("OTP expired, please request a new code."));
      setTimerExpired(true);
      // setLoading(true);
      // setTimeout(() => {
      //   setOtpSent(false);
      //   setLoading(false);
      //   setOtpValues(["", "", "", ""]);
      // }, 1000);
    }

    return () => clearInterval(interval);
  }, [otpSent, timer]);

  return (
    <div className={`${styles.modalOverlay} fixed z-50 inset-0 `}>
      <div className={styles.errorContainer}>
        <div className={`${styles.modalContentWrapper} relative`}>
          {loading ? (
            <div className="flex justify-center items-center absolute bg-black opacity-20 h-full w-full rounded-[25px]">
              <FaSpinner
                className="animate-spin text-white opacity-100"
                size={24}
              />
            </div>
          ) : null}

          <div className="text-[22px] text-white">Verification</div>
          <FaTimes
            className="absolute top-4 right-4 cursor-pointer text-white"
            onClick={() => {
              hideModal();
              setOtpSent(false);
              setError("");
              setOtpValues(["", "", "", ""]);
              setResendMessage("");
              setSuccessMessage("");
              setTimer(59);
            }}
          />

          {otpSent && (
            <MdOutlineKeyboardBackspace
              size={24}
              className="absolute top-4 left-4 cursor-pointer text-white"
              onClick={() => {
                setOtpSent(false);
                setError("");
                setOtpValues(["", "", "", ""]);
                setResendMessage("");
                setTimerExpired(false);
                setSuccessMessage("");
                setTimer();
              }}
            />
          )}

          {otpSent ? (
            <>
              <div className="text-[16px] font-thin text-white">
                {t(`Please enter the verification code`)} <br />{" "}
                {t(`we sent to`)}{" "}
                <span className="text-[#BE8AFF] underline">{phone}</span>
              </div>
              <div className="flex justify-center mt-4">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    style={otpInputStyle}
                    type="text"
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleOtpKeyDown(index, e)}
                  />
                ))}
              </div>
              <div className="text-white font-thin text-[16px] mt-2">
                {timer ? formatTime(timer) : ""}
              </div>
              {error && (
                <div className="text-red-500 font-normal text-[16px]">
                  {error}
                </div>
              )}
              {resendMessage && (
                <div className="text-[16px] font-thin text-white">
                  {resendMessage}
                </div>
              )}
              {timerExpired && (
                <div className="text-[16px] font-thin text-white">
                  {t(`Didn't get the code?`)}{" "}
                  <span
                    className="text-[#BE8AFF] underline cursor-pointer"
                    onClick={handleResendOtp}
                  >
                    {t(`Resend Code`)}
                  </span>
                </div>
              )}
              <button
                disabled={
                  timerExpired ? true : areAllOtpValuesFilled ? false : true
                }
                onClick={() => handleVerifyOtp()}
                className={`${
                  timerExpired
                    ? styles.disabledButton
                    : areAllOtpValuesFilled
                    ? styles.okButton
                    : styles.disabledButton
                } py-2 px-10 font-normal`}
              >
                {t(`Confirm`)}
              </button>{" "}
              {successMessage && (
                <div className="text-green-500 font-normal text-[16px] mt-2">
                  {successMessage}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="text-[16px] font-thin text-white">
                {t(`To verify your phone number please`)}
                <br /> {t(`click on`)} {`"`}
                <span className="text-[#BE8AFF] underline">
                  {t(`Get Code`)}
                </span>
                {`"`}
              </div>

              {isEdit ? (
                <div className="relative">
                  <InputField
                    placeHolder={"Your phone number"}
                    extraStyle={inputBox}
                    cls={styles.loginInputClass}
                    type="number"
                    name="phone"
                    // onKeyPress={checkUserNameValidation}
                    prefix={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div style={{ width: 20, height: 20 }}>
                          <img
                            src={getFlagIcon()}
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: 1,
                              objectFit: "contain",
                            }}
                            preview={false}
                            alt="nameIcon"
                          />
                        </div>
                        <p className={styles.countryCodeText}>{country_code}</p>
                        <p
                          style={{
                            fontStyle: "normal",
                            fontVariant: "normal",
                            fontWeight: "medium",
                            fontSize: "20px",
                            color: "#574E85",
                            opacity: 1,
                            margin: "0px 5px 1px 0px",
                          }}
                        >
                          |
                        </p>
                      </div>
                    }
                    required={true}
                    value={phone}
                    onChange={handleInputChange}
                  />

                  <div className="absolute right-[10px] top-[15px] z-[100]">
                    <div>
                      <FaCheck
                        className="cursor-pointer"
                        onClick={() => {
                          if (phone !== "") {
                            setIsEdit(false);
                          }
                        }}
                        size={20}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-4 h-[50px]">
                  <div className="text-[18px]">
                    {country_code} {phone}
                  </div>
                  <div>
                    <img
                      src={editPng}
                      className="cursor-pointer w-[20px] h-[20px]"
                      onClick={() => setIsEdit(true)}
                    />
                  </div>
                </div>
              )}
              {error && (
                <div className="text-red-500 font-normal text-[16px]">
                  {error}
                </div>
              )}
              <button
                disabled={phone == "" || isEdit ? true : false}
                onClick={() => handleGenerateOtp()}
                className={`${
                  phone == "" || isEdit
                    ? `${styles.disabledButton}`
                    : `${styles.okButton}`
                } py-2 px-10 font-normal`}
              >
                {t(`Get Code`)}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpPopupModal;
