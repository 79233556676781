import { api } from "./axios";

export const getConfig = (token, contentType = "application/json") => {
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": contentType ? contentType : "application/json",
      // "Content-Type": "multipart/form-data",
    },
  };
  return config;
};
export const APILoginUser = async (
  phone,
  password,
  deviceType,
  browserName
) => {
  try {
    const res = await api.post("player/login", {
      user_name: phone,
      password,
      device_type: deviceType,
      browser_type: browserName,
    });
    return res;
    // if (res.data.message == "USER_LOGGED_IN_SUCCESSFULLY") {
    //     if (res.data && res.data.token) {
    //         return res.data.token;
    //     }
    // }
  } catch (e) {
    if (e.response) {
      return e.response;
      // if (e.response.sttus === 500) {
      //     return "serverError";
      // } else if (e.response?.data?.message === "ACCOUNT_INACTIVE") {
      //     return "ACCOUNT_INACTIVE";
      // } else if (e.response.message === "USER_DOES_NOT_EXIST") {
      //     return "invalidPhone";
      // } else if (e.response.message === "PASSWORD_INCORRECT") {
      //     return null;
      // } else {
      //     // console.log(e);
      // }
    }
  }
  return null;
};

// Register User
export const APIRegisterUser = async (
  {
    user_name,
    password,
    phone,
    name,
    // language = "4",
    currency,
    agent_id,
    countryCode,
    // agentId
  },
  captcha_token,
  webGlResult,
  deviceType,
  browserName,
  params,
  browser_url
) => {
  try {
    const phoneWithCountryCode = countryCode.concat(phone);

    let referKey;
    if (params && Object.keys(params).length > 0) {
      let key = Object.keys(params)[0];
      referKey = { [key]: Number(params[key]) ?? `` };
    }
    const payload = {
      user_name,
      name,
      phone: phoneWithCountryCode,
      password,
      currency,
      captcha_token_v2: captcha_token,
      fp_data: webGlResult,
      device_type: deviceType,
      browser_type: browserName,
      browser_url:browser_url,
      ...referKey,
    };

    if (agent_id) {
      payload.agent_id = agent_id;
    }

    const res = await api.post("player/create", payload);
    // const res = await api.post("player/create", {
    //   user_name,
    //   name,
    //   phone: phoneWithCountryCode,
    //   password,
    //   // language,
    //   currency,
    //   agent_id,
    //   // agent_id: agentId,
    // });
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    return e.response;
  }
};

// Check phone is exist or not
export const APICheckUsernameExists = async (userName) => {
  try {
    const res = await api.post("player/check/name", {
      user_name: userName,
    });
    return res.data;
  } catch (e) {
    console.log(e);
  }
  return null;
};
// Check phone is exist or not
export const APICheckPhoneExist = async (phone) => {
  try {
    const res = await api.post("player/check/phone", { phone });
    return res.data;
  } catch (e) {
    console.log(e);
  }
  return null;
};

// get user data
export const APIUser = async () => {
  try {
    const res = await api.get("player/profile", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    return e;
  }
  return null;
};

// get turnover data
export const APITurnover = async () => {
  try {
    const res = await api.get("player/getTurnOverData", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    return e;
  }
  return null;
};
