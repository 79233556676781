import React from "react";
import styles from "./customButton.module.css";

const CustomButton = ({
  cls,
  onClick,
  children,
  background,
  width,
  height,
  color,
  type,
  className,
  disabled = false,
  htmlType = "",
}) => {
  const buttonStyle = {
    background:
      background ||
      "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #3B3F7B",
    cursor: "pointer",
    color: color || "#FFFFFF",
    width: width || "auto",
    height: height || "auto",
  };

  return (
    <button
      className={disabled ? `${styles.disabled} ${className}` : `${styles.btnContainer} ${className}`}
      style={buttonStyle}
      onClick={onClick}
      type={type}
      disabled={disabled}
      // htmlType={htmlType}
    >
      {children}
    </button>
  );
};

export default CustomButton;
