import { Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import "./App.css";
import i18n from "./i18n";
import Home from "./components/pages/Home/Home";
import Login from "./components/pages/Login/Login";
import { I18nextProvider } from "react-i18next";
import store from "./redux/store";
import { Provider } from "react-redux";
import { privateRoutes } from "./route/PrivateRoutes";
import PrivateRoute from "./route/PrivateRoute";
import AboutUs from "./components/pages/AboutUs/AboutUs";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./components/pages/TermsAndConditions/TermsAndCondition";
import ResponsibleGaming from "./components/pages/ResponsibleGaming/ResponsibleGaming";
import Support from "./components/shared-components/support/Support";
import AffiliateProgram from "./components/shared-components/AffiliateProgram/AffiliateProgram";
import Bonus from "./components/pages/Bonus/Bonus";
import SinglePromotionDetails from "./components/pages/Bonus/SinglePromotionDetails";
import "./CustomStyle.scss";
import Layouts from "./layouts/Layout";
import { useEffect, useState } from "react";
import MainPageLoading from "./components/shared-components/MainPageLoading/MainPageLoading";
// import SelectCountryLanguage from "./components/shared-components/SelectContryLanguage/SelectCountryLanguage";
import getClient from "./utils/getClient";
import { setClientConfig } from "./redux/Slice/ClientConfigSlice";
import PageNotFound from "./components/pages/PageNotFound/PageNotFound";
import DynamicCategoryRoute from "./route/DynamicCategoryRoute";

function App() {
  const [showloading, setShowLoading] = useState(true);
  // const [showlanguageSelector, setLanguageSelector] = useState(false);
  const clientConfig = getClient();
  store.dispatch(setClientConfig(clientConfig));

  useEffect(() => {
    if (showloading) {
      const timer = setTimeout(() => {
        setShowLoading(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showloading]);

  // useEffect(() => {
  //   const seenCountryPopup = localStorage.getItem("Lng");
  //   if (!seenCountryPopup) {
  //     setLanguageSelector(true);
  //   }
  //   setShowLoading(true);
  // }, []);

  // close for lngpop up
  // const handelCloseLanguage = (label) => {
  //   localStorage.setItem("Lng", label);
  //   setShowLoading(true);
  //   setLanguageSelector(false);
  // };

  return (
    <>
      {/* {showlanguageSelector ? (
        <div className="lngContainer">
          <SelectCountryLanguage
            handelCloseLanguage={handelCloseLanguage}
            show={showlanguageSelector}
          />
        </div>
      ) : (
        <> */}
      {showloading && <MainPageLoading />}
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Layouts>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:category" element={<DynamicCategoryRoute />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Login />} />
              <Route path="/support" element={<Support />} />
              <Route path="/affiliate" element={<AffiliateProgram />} />
              <Route path="/promotions" element={<Bonus />} />
              <Route path="*" element={<PageNotFound />} />
              {/* <Route path="/games/:name" element={<Games />} /> */}

              {/* <Route
                    path="/promotions/:id"
                    element={<SinglePromotionDetails />}
                  /> */}
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndCondition />}
              />
              <Route
                path="/responsible-gaming"
                element={<ResponsibleGaming />}
              />

              {privateRoutes.map((route) => (
                <Route
                  key={route.id}
                  path={route.path}
                  element={<PrivateRoute component={route.component} />}
                />
              ))}
            </Routes>
          </Layouts>
        </Provider>
      </I18nextProvider>
      {/* </>
      )} */}
    </>
  );
}

export default App;
