import React from "react";

const CustomMarquee = ({ cls, text, textStyle }) => {
  return (
    <div className={cls !== "" ? cls : ""}>
      <marquee
        style={{
          textAlign: "left",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "14px",
          lineHeight: "17px",
          fontFamily: "Roboto",
          letterSpacing: "0px",
          color: "#FFFFFF",
          opacity: 1,
        }}
      >
        {text ? text : "sample text"}
      </marquee>
    </div>
  );
};

export default CustomMarquee;
