export const currencyHelper = (currency) => {
  if (currency === "INR") {
    return "₹ ";
  } else if (currency === "PHP") {
    return "₱ ";
  } else if (currency === "VNDK") {
    return "₫ ";
  } else if (currency === "USD") {
    return "$ ";
  } else if (currency === "BDT") {
    return "৳ ";
  } else {
    return "₱ ";
  }
};
