import React, { useState, useEffect } from "react";
import styles from "./InviteFriends.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import backimage from "../../../assets/images/back-icon/BackIcon.png";
import "react-datepicker/dist/react-datepicker.css";
import { CommaSeperator, toFixedFunc } from "../../Helper/HelperFunction";
import NoDataComp from "../../shared-components/NoData/NoDataComp";
import useLogout from "../../../layouts/Header/useLogout";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import preIcon from "../../../assets/images/swip/Left Swip.png";
import nextIcon from "../../../assets/images/swip/Right Swip.png";
import { Button, DatePicker, Pagination, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import Overview from "./Overview/Overview";
import Records from "./Records/Records";
import { useSelector } from "react-redux";
import {
  APIInviteFriendsOverview,
  APIInviteFriendsRecords,
} from "../../../Api/InviteFriendsApi";
import { currencyHelper } from "../../Helper/currencyHelper";

const { RangePicker } = DatePicker;
const customStyle = {
  width: "250px",
  height: "36px",
  background: "#000326 0% 0% no-repeat padding-box",
  borderRadius: "7px",
  textAlign: "left",
  font: "normal normal normal 14px/36px Poppins",
  letterSpacing: "0px",
  color: "#FFFFFF",
  opacity: "1",
  backgroundColor: "#374165",
  border: "1px solid #3B3F7B",
};

const endDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = "23";
  const minutes = "59";
  const seconds = "59";

  return ` ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const startDateFormat = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = "00";
  const minutes = "00";
  const seconds = "00";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const InviteFriends = () => {
  const { t } = useTranslation();
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const dateFormat = "DD-MM-YYYY";
  const logout = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [loading, setLoading] = useState(false);
  const [toggleButton, setToggleButon] = useState({
    overview: state?.active === "overview" ? false : true,
    records: state?.active === "overview" ? true : false,
  });
  const [incomeInfo, setIncomeInfo] = useState();
  const [inviteRecords, setInviteRecords] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
    status: null,
  });

  const [pageData, setPageData] = useState(null);
  const [page, setPage] = useState(1);

  const dateHandleChange = (range) => {
    setDateRange(range);
    if (range && range.length === 2) {
      const startDate = range[0].format();
      const endDate = range[1].format();
      setFilterData({
        ...filterData,
        startDate: startDateFormat(startDate),
        endDate: endDateFormat(endDate),
      });
    }
  };

  const handleToggleButn = (e) => {
    let buttonText = e.target.textContent;
    if (buttonText === "Overview") {
      setToggleButon({
        overview: true,
        records: false,
      });
    } else {
      setToggleButon({
        overview: false,
        records: true,
      });
    }
  };

  const getInviteOverviewFunc = async () => {
    setLoading(true);
    const res = await APIInviteFriendsOverview();

    if (res?.status) {
      if (res?.data) {
        setIncomeInfo(res?.data);
      } else {
        setIncomeInfo();
      }
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      setIncomeInfo();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (toggleButton?.overview) {
      getInviteOverviewFunc();
    }
  }, [toggleButton.overview]);

  // Invite Records Api Call Function
  const getInviteRecordsFunc = async ({ page, filterData }) => {
    setLoading(true);
    const res = await APIInviteFriendsRecords({
      page,
      start_date: filterData?.startDate,
      end_date: filterData?.endDate,
    });
    // console.log("res", res);
    if (res) {
      if (res?.data) {
        setInviteRecords(res?.data);
        setPageData(res);
      } else {
        setInviteRecords([]);
      }
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      // setIncomeInfo();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (toggleButton?.records) {
      getInviteRecordsFunc({ page: page, filterData: filterData ?? null });
    }
  }, [toggleButton?.records, page]);

  const incomeTabs = [
    {
      id: "todaysIncome",
      label: "Today's Income",
      data: CommaSeperator(toFixedFunc(incomeInfo?.today_income)),
      icon: currencyHelper(userInfoReduxData?.currency),
    },
    {
      id: "yesterdaysIncome",
      label: "Yesterday's Income",
      data: CommaSeperator(toFixedFunc(incomeInfo?.yesterday_income)),
      icon: currencyHelper(userInfoReduxData?.currency),
    },
    {
      id: "register",
      label: "Registers",
      data: incomeInfo?.register,
      icon: "",
    },
    {
      id: "monthIncome",
      label: "This Month Income",
      data: CommaSeperator(toFixedFunc(incomeInfo?.current_month_income)),
      icon: currencyHelper(userInfoReduxData?.currency),
    },
    {
      id: "lastMonthIncome",
      label: "Last Month Income",
      data: CommaSeperator(toFixedFunc(incomeInfo?.last_month_income)),
      icon: currencyHelper(userInfoReduxData?.currency),
    },
    {
      id: "totalIncome",
      label: "Total's Income",
      data: CommaSeperator(toFixedFunc(incomeInfo?.total_income)),
      icon: currencyHelper(userInfoReduxData?.currency),
    },
  ];

  return (
    <div className="min-h-[60vh] p-7 relative">
      {/* Toogle Button and Header Title */}
      <div className="flex mt-4 space-x-5 justify-normal items-center">
        <Link to="/account">
          <img
            src={backimage}
            alt="back"
            style={{ width: "13px", height: "21px" }}
          />
        </Link>
        <h2 className={styles.headTitleDesign}>
          {toggleButton.overview ? "Overview" : "Records"}
        </h2>
      </div>

      {/* Toggle */}
      <div className="flex items-center flex-wrap mt-5 w-full md:justify-between">
        <div
          className="relative flex items-center p-1 rounded-full w-96 bg-body"
          style={{ border: "1px solid #3B3F7B" }}
        >
          <div
            className={`absolute top-0 bottom-0 left-0 right-0 m-1 bg-toggle rounded-full transition-transform transform ${
              toggleButton.overview ? "" : "translate-x-[95%]"
            }`}
            style={{ width: "50%" }}
          ></div>
          <button
            className={` ${
              styles.toggleText
            } flex-1 h-9  z-10  focus:outline-none ${
              toggleButton.overview ? "text-white" : "text-[#7F72C6]"
            }`}
            onClick={handleToggleButn}
          >
            Overview
          </button>
          <button
            className={` ${
              styles.toggleText
            } flex-1 h-9  z-10 focus:outline-none ${
              toggleButton.records ? "text-white" : "text-[#7F72C6]"
            }`}
            onClick={handleToggleButn}
          >
            Records
          </button>
        </div>

        {toggleButton.records && !loading && (
          <div className="flex flex-row flex-wrap  gap-4    ">
            <div className={"flex flex-row items-center gap-4 p-2 rounded-md "}>
              <h2
                style={{
                  textAlign: "left",
                  font: "normal normal normal 14px/36px Poppins",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                  opacity: 1,
                }}
              >
                {"Select Date"}
              </h2>
              <RangePicker
                style={{ ...customStyle }}
                format={dateFormat}
                value={dateRange}
                onChange={dateHandleChange}
                className="bg-default text-white"
              />
            </div>

            <div className="flex gap-4 mt-2 ">
              <button
                className={`${styles.customButtonSubmit}`}
                style={{
                  background:
                    "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
                  borderRadius: "5px",
                  border: "1px solid #3B3F7B",
                  opacity: "1",
                }}
                type="primary"
                onClick={() => {
                  setPage(1);
                  getInviteRecordsFunc({
                    page: 1,
                    filterData: filterData ?? null,
                  });
                }}
              >
                {t(`Search`)}
              </button>
              <button
                className={`${styles.customButtonReset}`}
                style={{
                  color: "white",
                  background: "#000326 0% 0% no-repeat padding-box",
                  border: "1px solid #3B3F7B",
                  borderRadius: "5px",
                  opacity: "1",
                }}
                onClick={() => {
                  setFilterData({
                    startDate: "",
                    endDate: "",
                    status: null,
                  });
                  setDateRange([]);
                  getInviteRecordsFunc({
                    page: 1,
                    filterData: null,
                  });
                }}
              >
                {t(`Reset`)}
              </button>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <SmallLoader />
      ) : (
        <>
          {/* Data of OVerview and Records */}
          <div className="mt-[54px]">
            {toggleButton.overview && (
              <Overview incomeTabs={incomeTabs} incomeData={incomeInfo} />
            )}
          </div>

          {toggleButton.records && (
            <Records
              ReferRecords={inviteRecords}
              pageData={pageData}
              setPage={setPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InviteFriends;
