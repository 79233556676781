import React, { useEffect } from "react";
import Banner from "../../../assets/Banner-desktop.png";
import giftBanner from "../../../assets/gift banner.png";
import Chart from "../../../assets/Chart.png";
import Table from "../../../assets/table.png";
import Calculation from "../../../assets/calculation.png";
import CustomButton from "../custom-button/customButton";

const AffiliateProgram = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-inherit flex flex-col items-center py-10">
      <div className="w-full max-w-5xl mx-auto">
        <div className="mb-8">
          <img
            src={Banner}
            alt="banner"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        <div className="mb-8">
          <img
            src={giftBanner}
            alt="gift banner"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        <div className="mb-8 text-center">
          <a
            href="https://t.me/BW9OFFICIALLINE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomButton
              text="Become Partner"
              type="bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
            />
          </a>
        </div>

        <div className="mb-8">
          <img
            src={Chart}
            alt="chart"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        <div className="mb-8 px-4">
          <p className="text-lg text-white">
            From DIRECT PLAYER, Agent will get weekly commission lost by
            following this chart:
          </p>
        </div>

        <div className="mb-8">
          <img
            src={Table}
            alt="table"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        <div className="mb-8 px-4">
          <p className="text-lg text-white">
            PLAYERS can earn bonus just by being SUBAGENT and invite more
            Players.
          </p>
        </div>

        <div className="mb-8 px-4 space-y-4">
          <p className="text-lg text-white">
            <span className="font-semibold text-indigo-600">1.</span> Invite a
            friend to sign-up and play with BESTWAY9 using your unique{" "}
            <span className="font-semibold text-indigo-600">
              "Referral link"
            </span>{" "}
            or{" "}
            <span className="font-semibold text-indigo-600">
              "Referral Code"
            </span>{" "}
            under{" "}
            <span className="font-semibold text-indigo-600">
              "My Account - Profile - Referral."
            </span>
          </p>
          <p className="text-lg text-white">
            <span className="font-semibold text-indigo-600">2.</span> When your
            friend accumulates a{" "}
            <span className="font-semibold text-indigo-600">300 PHP</span>{" "}
            deposit and{" "}
            <span className="font-semibold text-indigo-600">3000 PHP</span>{" "}
            turnover within{" "}
            <span className="font-semibold text-indigo-600">7 days</span>, you
            will receive{" "}
            <span className="font-semibold text-indigo-600">200 PHP</span>, and
            your friend will receive{" "}
            <span className="font-semibold text-indigo-600">100 PHP</span>{" "}
            automatically in the player account.
          </p>
          <p className="text-lg text-white">
            <span className="font-semibold text-indigo-600">3.</span> Get an
            additional{" "}
            <span className="font-semibold text-indigo-600">500 PHP</span> if
            you refer more than{" "}
            <span className="font-semibold text-indigo-600">10 friends</span>.
            Contact our Customer Support via live chat with the details below
            and get the additional{" "}
            <span className="font-semibold text-indigo-600">FREE 500 PHP</span>.
          </p>
        </div>

        <div className="mb-8">
          <img
            src={Calculation}
            alt="calculation"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>
      </div>
    </div>
  );
};

export default AffiliateProgram;
