import React, { useEffect, useState } from "react";
import styles from "./AddPaymentMethod.module.css";
import lockIcon from "../../../assets/Lock.png";
import selectedIcon from "../../../assets/images/payment icons/Selected Method.png";
import {
  APIMakePaymentMethod,
  paymentCategoriesApi,
} from "../../../Api/TransactionApi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ErrorsHandlingComp from "../../Helper/Validator/ErrorsHandlingComp";
import NoDataComp from "../../shared-components/NoData/NoDataComp";
import useLogout from "../../../layouts/Header/useLogout";
import Loader from "../../shared-components/Loader/Loader";
import CustomButton from "../../shared-components/custom-button/customButton";
import addEwallet from "../../../assets/images/accounts/Ewallet.png";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import { useTranslation } from "react-i18next";

const inputBox = {
  maxWidth: "",
  width: "100%",
  paddingLeft: "10px",
  height: "50px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "19px",
  fontFamily: "Roboto",
  letterSpacing: 0,
  color: "#FFFFFF",
  opacity: 1,
};

const AddPaymentMethod = ({
  selectedWallet,
  clearFields,
  setClearFields,
  buttonText,
  getBankListFunc,
  paymentMethodsInfo,
}) => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    selectWallet: t("Select bank code"),
    accountNumber: "",
  });
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [paymentCategories, setPaymentCategories] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const currentLanguage = localStorage.getItem("selectedLanguage");

  const handleClick = () => {
    setShowMessage(true);
  };

  const navigate = useNavigate();
  const logout = useLogout();

  const handleSelectData = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectWallet: e.target.value,
    }));
  };

  const handleInputData = (event) => {
    const { value } = event.target;
    const onlyNumbers = /^[0-9]*$/; // Allow empty string to clear input

    // Check if the value consists only of numbers (or is empty to allow deletion)
    if (onlyNumbers.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        accountNumber: value, // Update only if the input is numbers
      }));

      // Check the length and set or clear errors accordingly
      if (value.length < 3) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          account_number: t("Minimum 3 digits required"),
        }));
      } else {
        // Clear the error for account_number if it exists
        setErrors((prevErrors) => ({
          ...prevErrors,
          account_number: "", // Clear the specific error message
        }));
      }
    } else {
      // Set error for non-numeric values
      setErrors((prevErrors) => ({
        ...prevErrors,
        account_number: t("Enter Only Numbers"),
      }));
    }
  };
  useEffect(() => {
    if (!formData.accountNumber.length) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        account_number: "", // Clear the specific error message
      }));
    }
  }, [formData.accountNumber]);

  const getPaymentCategories = async () => {
    setCategoriesLoading(true);
    // setMethodLoading(true)
    const res = await paymentCategoriesApi();
    if (res?.status === 200) {
      if (res.data.length === 0 || !res.data) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          noCategory: (() => (
            <p className={styles.enterAmountText}>
              {t(
                "The System is Under Maintenance. Please try after some time or contact our"
              )}
              <a href="/support"> {t("customer support")}</a>.
            </p>
          ))(),
        }));
      } else {
        setPaymentCategories(res.data);
        setSelectedCategory(res.data[0]);
      }
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else if (res?.response?.status === 401) {
      logout();
    }
    setCategoriesLoading(false);
  };
  useEffect(() => {
    getPaymentCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      // getPaymentMethodsFunc(selectedCategory)
      const data = selectedCategory?.bank_codes.map((item) => ({
        value: item?.id,
        label: item?.public_name?.en_public_name,
      }));
      data.unshift({
        value: t("Select bank code"),
        label: t("Select bank code"),
      });
      setPaymentMethods(data);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedWallet) {
      setFormData({
        selectWallet: selectedWallet.bank_code_id || "",
        accountNumber: selectedWallet.account_number || "",
      });
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (clearFields) {
      setFormData({
        selectWallet: t("Select bank code"),
        accountNumber: "",
      });
      setClearFields(false);
    }
  }, [clearFields, setClearFields]);

  const validateForm = () => {
    return (
      formData &&
      formData.selectWallet &&
      formData.selectWallet !== t("Select bank code") &&
      formData.accountNumber.length >= 3
    );
  };

  const submitNewWallet = async () => {
    setLoading(true);
    if (validateForm()) {
      const res = await APIMakePaymentMethod(
        selectedCategory?.id,
        formData?.selectWallet,
        formData?.accountNumber
      );
      if (res.status === 200) {
        // window.location.reload();
        getPaymentCategories();
        getBankListFunc();
        setFormData({
          selectWallet: t("Select bank code"),
          accountNumber: "",
        });
        navigate("/withdraw");
      } else if (res?.response?.status === 400) {
        if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        }
      } else if (res?.response?.status === 401) {
        logout();
      } else {
        if (res?.response?.status === 422) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            account_number:
              res?.response?.data?.message === "USER_ACCOUNT_ALREADY_EXISTS"
                ? `User account already exists!`
                : `Something went wrong!`,
          }));
        }
      }
    }
    setLoading(false);
  };
  //  console.log(paymentCategories)
  return (
    <div className={styles.addPaymentMethodContainer}>
      <div className="mt-9 ml-7">
        <p className={`${styles.headTitleDesign}`}>{t("Add Payment Method")}</p>
      </div>
      {categoriesLoading ? (
        <SmallLoader />
      ) : !paymentCategories?.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <NoDataComp />
          {
            <div style={{ display: "flex", margin: "15px 0" }}>
              <ErrorsHandlingComp errors={errors} name="noCategory" />
            </div>
          }
        </div>
      ) : (
        <>
          <div className={` ${styles.container} w-full flex-col md:flex-row`}>
            <div className="w-full overflow-y-disable">
              <div className="mt-4 ml-7">
                <p className={`${styles.selectCatText} w-full`}>
                  {t("Select Category")}
                </p>
              </div>
              <div className={`ml-7 ${styles.paymentModeContainer}`}>
                {categoriesLoading ? (
                  <SmallLoader />
                ) : (
                  paymentCategories?.map((category) => {
                    return (
                      <div
                        className={`${styles.categoryBox} !w-[150px]`}
                        key={Number(category?.id)}
                        style={{
                          border:
                            Number(selectedCategory?.id) ===
                            Number(category?.id)
                              ? "2px solid #FFFFFF"
                              : "",
                        }}
                        onClick={() => {
                          setSelectedCategory(category);
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            selectWallet: t(`Select bank code`),
                          }));
                          // setPaymentMethods(null);
                        }}
                      >
                        <div style={{ display: "flex", width: 34, height: 35 }}>
                          <img
                            // src={category?.full_image?.en_icon}
                            src={addEwallet}
                            className="imgStyle"
                            style={{ background: "transparent" }}
                            alt="category icon"
                          />
                        </div>
                        <p className={styles.categoryBoxText}>
                          {t(category?.public_name?.en_public_name)}
                        </p>
                        {Number(selectedCategory?.id) ===
                        Number(category?.id) ? (
                          <img
                            src={selectedIcon}
                            className={styles.selectedIcon}
                            alt="selectedICon"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <div
              className={`${styles.cardWrapper} w-[95%] h-[100%] ml-7 mt-10`}
            >
              {selectedCategory && (
                <>
                  <div className={`mr-3 ${styles.inputContainer}`}>
                    {/* <div
                      className="items-center"
                      style={{ width: "100%", display: "flex" }}
                    >
                      <h2
                        className={
                          currentLanguage == "ph"
                            ? `${styles.inputContainerNameFilipino}`
                            : `${styles.inputContainerName}`
                        }
                      >
                        {t(`E-Wallet`)}:
                      </h2>
                      <select
                        className={styles.selectHolderField}
                        onChange={handleSelectData}
                        style={{ width: "100%" }}
                        placeholder={t("Select bank code")}
                        value={formData?.selectWallet}
                      >
                        {paymentMethods &&
                          paymentMethods.map((method, index) => (
                            <option key={index} value={method.value}>
                              {method.label}
                            </option>
                          ))}
                      </select>
                    </div> */}

                    <div className="flex flex-col sm:flex-row items-center w-full">
                      <h2
                        className={`${
                          currentLanguage === "ph"
                            ? styles.inputContainerNameFilipino
                            : styles.inputContainerName
                        }`}
                      >
                        {t(`E-Wallet`)}:
                      </h2>
                      {/* <select
                        className={`${styles.selectHolderField} mt-2 sm:mt-0 sm:ml-4 w-full`}
                        onChange={handleSelectData}
                        placeholder={t("Select bank code")}
                        value={formData?.selectWallet}
                      >
                        {paymentMethods &&
                          paymentMethods.map((method, index) => (
                            <option key={index} value={method.value}>
                              {method.label}
                            </option>
                          ))}
                      </select> */}

                      <select
                        className={`${styles.selectHolderField} mt-2 sm:mt-0 sm:ml-4 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 px-2 py-1 text-sm`}
                        onChange={handleSelectData}
                        placeholder={t("Select bank code")}
                        value={formData?.selectWallet}
                      >
                        {paymentMethods &&
                          paymentMethods.map((method, index) => (
                            <option key={index} value={method.value}>
                              {method.label}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* <div
                      className="items-center"
                      style={{ width: "100%", display: "flex" }}
                    >
                      <h2
                        className={
                          currentLanguage == "ph"
                            ? `${styles.inputContainerNameFilipino}`
                            : `${styles.inputContainerName}`
                        }
                      >
                        {t(`Holder Name`)}:
                      </h2>
                      <div className={styles.accountHolderField}>
                        <p className={styles.labelTextStyling}>
                          {userInfoReduxData?.name || ""}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            width: "20px",
                            height: "20px",
                            opacity: 1,
                          }}
                        >
                          <img
                            src={lockIcon}
                            className="imgStyle"
                            alt="lockIcon"
                            onClick={handleClick}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="flex flex-col sm:flex-row items-center w-full">
                      <h2
                        className={`${
                          currentLanguage === "ph"
                            ? styles.inputContainerNameFilipino
                            : styles.inputContainerName
                        }`}
                      >
                        {t(`Holder Name`)}:
                      </h2>
                      <div
                        className={`${styles.accountHolderField}  mt-2 sm:mt-0 sm:ml-4 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 px-2 py-1 text-sm`}
                      >
                        <p className={styles.labelTextStyling}>
                          {userInfoReduxData?.name || ""}
                        </p>
                        <div className="flex items-center justify-center w-5 h-5 ml-2 opacity-100">
                          <img
                            src={lockIcon}
                            className="cursor-pointer"
                            alt="lockIcon"
                            onClick={handleClick}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div
                      style={{ display: "flex", width: "100%", height: "50px" }}
                      className="add-payment-phone items-center"
                    >
                      <h2
                        className={
                          currentLanguage == "ph"
                            ? `${styles.inputContainerNameFilipino}`
                            : `${styles.inputContainerName}`
                        }
                      >
                        {t(`Account Number`)}:
                      </h2>
                      <input
                        style={{ ...inputBox }}
                        placeholder={t("Enter account number")}
                        onChange={handleInputData}
                        required
                        value={formData?.accountNumber}
                      />
                    </div> */}

                    <div className="flex flex-col sm:flex-row items-center w-full">
                      <h2
                        className={`${
                          currentLanguage === "ph"
                            ? styles.inputContainerNameFilipino
                            : styles.inputContainerName
                        }`}
                      >
                        {t(`Account Number`)}:
                      </h2>
                      <input
                        className="mt-2 sm:mt-0 sm:ml-4 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 px-2 py-1 text-sm"
                        placeholder={t("Enter account number")}
                        onChange={handleInputData}
                        required
                        value={formData?.accountNumber}
                        style={inputBox} // Assuming inputBox contains additional styles for the input
                      />
                    </div>

                    <ErrorsHandlingComp errors={errors} name="account_number" />

                    <div>
                      {paymentMethodsInfo?.length >= 3 && (
                        <p style={{ color: "#ff5746" }}>
                          {t(`You have reached the maximum limit of 3 wallets.`)}
                        </p>
                      )}
                    </div>

                    {showMessage && (
                      <p style={{ color: "red" }}>
                        {t(
                          "To Change Your Account Name, Please contact Customer"
                        )}
                        <Link
                          to="/support"
                          className="text-blue-500 underline ml-2"
                        >
                          {t(`Support`)}
                        </Link>
                      </p>
                    )}
                  </div>

                  <div className="absolute right-5 bottom-5 ">
                  {/* <div className="right-5 bottom-5 "> */}
                    {loading ? (
                      <SmallLoader />
                    ) : (
                      <>
                        {console.log("00", paymentMethods)}
                        <button
                          onClick={(e) => submitNewWallet(e)}
                          className={`${styles.depositBtn} ${
                            paymentMethodsInfo?.length >= 3
                              ? styles.disabled
                              : validateForm()
                              ? styles.activeButton
                              : styles.disabled
                          }`}
                        >
                          {t(buttonText)}
                        </button>

                        <CustomButton
                          background="#14132D 0% 0% no-repeat padding-box"
                          width="200px"
                          height="36px"
                          className="no-hover ml-2"
                          type={styles.addCancelPayBtn}
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          {t("Cancel")}
                        </CustomButton>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddPaymentMethod;
