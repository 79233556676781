import React,{ useState } from 'react';
import styles from './QuestPopup.module.css';
import bigWin from '../../../assets/quest hub/Big Win@2x.png';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const QuestHub = ({setActiveTab}) => {

    const [toggleButton, setToggleButton] = useState({
        dailyQuest: true,
        weeklyQuest: false,
      });
    

    const pregressCalc = (curr, total) => {
        const res = curr / total * 100;
        return res;
    }

    return (
        <div className={styles.detailSection}>
            <h2 className={styles.daysStyling}>Wednesday</h2>
            <div className={styles.rewardsSection}>
                <div className={styles.rewardTitle}>Accumulated Rewards:</div>
                <div className={styles.rewardNumeric}>₱ 5</div>
                <div className={styles.previousBtn} onClick={()=>setActiveTab({questHub:false,previousQuest:true})}>Previous Quest</div>
            </div>

            <div className="relative flex my-[5%] w-full p-1">
                <span
                    className="absolute mt-8 inset-0 pointer-events-none"
                    aria-hidden="true"
                >
                    <span
                        className={`absolute inset-0 transform transition duration-150 ease-in-out ${toggleButton.dailyQuest
                            ? `translate-x-0 ${styles.toggleBtn}`
                            : `translate-x-full ${styles.toggleBtn}`
                            } `}
                    ></span>
                    <span className={`absolute ${styles.toggleLine}`}></span>
                </span>
                <button
                    className={`${styles.toggleLoginBtn} relative flex-1 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2`}
                    onClick={() => {
                        setToggleButton({
                            dailyQuest: true,
                            weeklyQuest: false,
                        });
                        // navigate("/dailyquest");
                    }}
                >
                    Daily Quests
                </button>
                <button
                    className={`${styles.toggleLoginBtn} relative flex-1 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2`}
                    onClick={() => {
                        setToggleButton({
                            dailyQuest: false,
                            weeklyQuest: true,
                        });
                        // navigate("/weeklyquest");
                    }}
                >
                    Weekly Quest
                </button>
            </div>

            <div className="flex w-full">
                {/* Daily quest  */}
                {toggleButton?.dailyQuest && (
                    <div className="flex flex-col">
                        <div className="flex gap-6 mb-5">
                            <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#7473B7] opacity-100">
                                Expires in
                            </div>
                            <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                15 : 47 : 12
                            </div>
                        </div>
                        <div className="w-full flex gap-2 flex-col max-h-[280px] overflow-y-auto">
                            <div className="w-full flex bg-[#24224A] rounded-[10px] p-3 gap-3">
                                <div className="max-w-[70px] w-full max-h-[70px] h-full">
                                    <img src={bigWin} alt="bigwin" />
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                        Big Win
                                    </div>
                                    <p className="text-left not-italic font-normal text-[11px] tracking-normal leading-4 font-Poppins text-[#7473B7] opacity-100">
                                        Win a payout of 50x or above in any game with a bet of
                                        greater than $ 0.40.
                                    </p>
                                    <div className="text-left italic font-semibold text-[16px] leading-6 font-Poppins tracking-normal text-[#FFD029] opacity-100">
                                        Earn ₱ 0.2
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex bg-[#24224A] rounded-[10px] p-3 gap-3 items-center">
                                <div className="max-w-[70px] w-full max-h-[70px] h-full relative">
                                    <div className='max-w-[50px] w-full max-h-[50px] h-full bg-[#181733] rounded-full absolute top-[14%] left-[14%]'></div>
                                    <CircularProgressbar
                                        value={pregressCalc(1, 3)}
                                        text={`1/3`}
                                        strokeWidth={6}
                                        styles={buildStyles({
                                            textSize: '14px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#FFFFFF`,
                                            textColor: '#FFFFFF',
                                            trailColor: 'rgba(0, 0, 0, 0.30)',
                                            fontWeight: 'normal',
                                        })}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                        Wheel Master
                                    </div>
                                    <p className="text-left not-italic font-normal text-[11px] tracking-normal leading-4 font-Poppins text-[#7473B7] opacity-100">
                                        Winning streak of 3 rounds in Wheel with bets greater
                                        than $ 0.40.
                                    </p>
                                    <div className="text-left italic font-semibold text-[16px] leading-6 font-Poppins tracking-normal text-[#FFD029] opacity-100">
                                        Earn ₱ 0.1
                                    </div>
                                </div>
                                <div className={styles.gobtn}>Go</div>
                            </div>
                        </div>
                    </div>
                )}

                {toggleButton?.weeklyQuest && (
                    <div className="flex flex-col">
                        <div className="flex gap-6 mb-5">
                            <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#7473B7] opacity-100">
                                Expires in
                            </div>
                            <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                15 : 47 : 12
                            </div>
                        </div>
                        <div className="w-full flex gap-2 flex-col max-h-[280px] overflow-y-auto">
                            <div className="w-full flex bg-[#24224A] rounded-[10px] p-3 gap-3">
                                <div className="max-w-[70px] w-full max-h-[70px] h-full">
                                    <img src={bigWin} alt="bigwin" />
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                        Big Win
                                    </div>
                                    <p className="text-left not-italic font-normal text-[11px] tracking-normal leading-4 font-Poppins text-[#7473B7] opacity-100">
                                        Win a payout of 50x or above in any game with a bet of
                                        greater than $ 0.40.
                                    </p>
                                    <div className="text-left italic font-semibold text-[16px] leading-6 font-Poppins tracking-normal text-[#FFD029] opacity-100">
                                        Earn ₱ 0.2
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex bg-[#24224A] rounded-[10px] p-3 gap-3">
                                <div className="max-w-[70px] w-full max-h-[70px] h-full">
                                    <img src={bigWin} alt="bigwin" />
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                        Big Win
                                    </div>
                                    <p className="text-left not-italic font-normal text-[11px] tracking-normal leading-4 font-Poppins text-[#7473B7] opacity-100">
                                        Win a payout of 50x or above in any game with a bet of
                                        greater than $ 0.40.
                                    </p>
                                    <div className="text-left italic font-semibold text-[16px] leading-6 font-Poppins tracking-normal text-[#FFD029] opacity-100">
                                        Earn ₱ 0.2
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QuestHub
