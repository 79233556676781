import React from "react";
import DOMPurify from "dompurify";
import root from "react-shadow";

const DOMPurifyPage = ({ htmlContent }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    ADD_ATTR: ["style"],
  });

  return (
    <root.div className="quote">
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </root.div>
  );
};

export default DOMPurifyPage;
