import React, { useEffect, useState } from "react";
import styles from "./Account.module.css";
import { useDispatch, useSelector } from "react-redux";
import { CommaSeperator, toFixedFunc } from "../../Helper/HelperFunction";
import { useNavigate } from "react-router-dom";
import LogoutConfirmationModal from "../../../layouts/Header/LogoutConfirmationModal";
import CopyIcon from "../../../assets/images/accounts/Copy.png";
import CrownIcon from "../../../assets/images/accounts/Crown.png";
import RefreshIcon from "../../../assets/images/accounts/Refresh.png";
import DepositIcon from "../../../assets/images/accounts/Deposit.png";
import WithdrawalIcon from "../../../assets/images/accounts/Withdrawal.png";
import BettingRecordIcon from "../../../assets/images/accounts/Betting Record.png";
import NotificationsIcon from "../../../assets/images/accounts/Notifications.png";
import LogoutIcon from "../../../assets/images/accounts/Logout.png";
import EWalletIcon from "../../../assets/images/accounts/Ewallet.png";
import DepositRecordIcon from "../../../assets/images/accounts/Deposit Record.png";
import WithdrawalRecord from "../../../assets/images/accounts/Withdrawal Record.png";
import InviteFriendsIcon from "../../../assets/images/accounts/InviteFriends.png";
import { currencyHelper } from "../../Helper/currencyHelper";
import { getCheckLevelBasedOnPoints } from "../../../Api/AccountApi";
import useLogout from "../../../layouts/Header/useLogout";
import SideAccount from "../../shared-components/SideAccount/SideAccount";
import profileImage from "../../../assets/images/accounts/Profile IMG@2x.png";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import { APIUser } from "../../../Api/Apis";
import { setUserInfo } from "../../../redux/Slice/UserSlice";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const Account = () => {
  const { t } = useTranslation();
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const navigate = useNavigate();
  const logout = useLogout();
  const [logoutModal, setLogoutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [levelData, setLevelData] = useState();
  const current = levelData?.userCurrentPoints;
  const total = levelData?.max;
  const percent = (current / total) * 100;
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem("selectedLanguage");
  const [balanceRefresh, setBalanceRefresh] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(userInfoReduxData?.user_name).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const handleRefreshClick = async () => {
    setBalanceRefresh(true);
    const userInfoApiRes = await APIUser();
    if (userInfoApiRes?.response?.status === 401) {
      logout();
      setBalanceRefresh(false);
    } else if (userInfoApiRes?.response?.status === 400) {
      if (userInfoApiRes?.response?.data?.message === "INVALID_ACCESS") {
        logout();
        setBalanceRefresh(false);
      }
    } else {
      dispatch(setUserInfo(userInfoApiRes));
      setBalanceRefresh(false);
    }
  };

  const accountCategoryData = [
    {
      id: "deposit",
      label: "Deposit",
      link: "/deposit",
      icon: DepositIcon,
      new: false,
    },
    {
      id: "withdrawal",
      label: "Withdrawal",
      link: "/withdraw",
      icon: WithdrawalIcon,
      new: false,
    },
    {
      id: "bettingRecord",
      label: "Betting Record",
      link: "betting-records",
      icon: BettingRecordIcon,
      new: false,
    },
    {
      id: "notifications",
      label: "Notifications",
      link: "/notifications",
      icon: NotificationsIcon,
      new: false,
    },
    {
      id: "depositRecord",
      label: "Deposit Record",
      link: "transactions",
      icon: DepositRecordIcon,
      new: false,
    },
    {
      id: "withdrawalRecord",
      label: "Withdrawal Record",
      link: "transactions",
      icon: WithdrawalRecord,
      new: false,
    },
    {
      id: "ewallet",
      label: "E-Wallet Management",
      link: "ewallet",
      icon: EWalletIcon,
      new: false,
    },
    {
      id: "inviteFriends",
      label: "Invite Friends",
      link: "invite-friends",
      icon: InviteFriendsIcon,
      new: true,
    },
    {
      id: "logout",
      label: "Logout",
      link: "",
      icon: LogoutIcon,
      new: false,
    },
  ];

  const AccountLevelFunc = async () => {
    setLoading(true);
    const res = await getCheckLevelBasedOnPoints();
    if (res?.data) {
      setLevelData(res?.data);
    } else {
      setLevelData();
    }
    setLoading(false);
  };

  useEffect(() => {
    AccountLevelFunc();
  }, []);

  return (
    <div className="flex flex-row flex-wrap p-5 ">
      <div className={` h-[100%] ${styles.container}`}>
        <div>
          <div className={styles.accountProfileBlur}>
            {loading ? (
              <SmallLoader />
            ) : (
              <div className={styles.accountProfile}>
                <div
                  style={{ border: "3px solid #be8aff", borderRadius: "50%" }}
                >
                  <img
                    className="inline-block w-20 h-20 rounded-full ring-2 ring-white"
                    // src={userInfoReduxData?.profile_pic}
                    src={profileImage}
                    alt="avatar"
                  />
                </div>

                <div className={styles.profileDetails}>
                  <div className={styles.userTextContainer}>
                    <div
                      className={
                        currentLanguage == "vi"
                          ? styles.userNameStyleVi
                          : styles.userNameStyle
                      }
                    >
                      {t("Username")}:
                    </div>
                    <div className={styles.copyItemCotainer}>
                      <div className="text-[18px] text-white">
                        {userInfoReduxData?.user_name}
                      </div>
                      <div
                        className="max-w-[16px] max-h-[18px] w-full h-full cursor-pointer"
                        onClick={handleCopy}
                      >
                        <img
                          src={CopyIcon}
                          alt="copyIcon"
                          className="imgStyle"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.userTextContainer}>
                    <div className={styles.userNameStyle}>{t(`Balance`)}:</div>
                    {balanceRefresh ? (
                      <SmallLoader width="20px" height="20px" />
                    ) : (
                      <div className="flex items-center gap-2">
                        <div className={styles.profileBalanceAmount}>
                          {currencyHelper(userInfoReduxData?.currency)}{" "}
                          {CommaSeperator(
                            toFixedFunc(userInfoReduxData?.balance)
                          )}
                        </div>
                        <div className="max-w-[18px] max-h-[18px] w-full h-full cursor-pointer">
                          <img
                            onClick={handleRefreshClick}
                            src={RefreshIcon}
                            alt="RefreshIcon"
                            className="imgStyle"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Progress Bar */}
                <div style={{ width: "100%" }}>
                  <div className={styles.levelStyle}>
                    <div className="max-w-[30px] max-h-[20px] w-full h-full">
                      <img
                        src={CrownIcon}
                        alt="CrownIcon"
                        className="imgStyle"
                      />
                    </div>
                    <p className={styles.levelNameStyle}>
                      {t(levelData?.level_name)}
                    </p>
                  </div>

                  <div className="flex items-center gap-2">
                    <div style={{ width: "100%" }}>
                      {/* <Progress
                    percent={percent}
                    showInfo={false}
                    status="active"
                    size="small"
                    strokeColor={{
                      from: "#108ee9",
                      to: "#000000d6",
                    }}
                    trailColor="white"
                  /> */}
                      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                          className="bg-white h-2.5 rounded-full"
                          style={{ width: percent }}
                        ></div>
                      </div>
                    </div>
                    <div className="text-white">
                      <span>{current}</span>
                      <span>/</span>
                      <span>{total}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            style={{ display: "flex", alignItems: "center", gap: "1%" }}
            className="mt-5"
          >
            <div
              style={{
                padding: "0 3%",
                background: "#232754 0% 0% no-repeat padding-box",
                color: "white",
                borderRadius: "8px",
              }}
            >
              <p
                className={styles.personalStyle}
                style={{ textAlign: "center" }}
              >
                {t(`Personal Center`)}
              </p>
            </div>
            <div style={{ flex: "1" }}>
              <div className="w-full border-t border-1 border-[#232754]"></div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
              gap: "20px",
            }}
            className="mt-3"
          >
            {accountCategoryData.map((item) => (
              <div
                key={item.id}
                onClick={() => {
                  if (item.id === "logout") {
                    setLogoutModal(true);
                  } else if (item.id === "depositRecord") {
                    navigate("/account/transactions", {
                      state: { active: "deposit" },
                    });
                  } else if (item.id === "withdrawalRecord") {
                    navigate("/account/transactions", {
                      state: { active: "withdraw" },
                    });
                  } else {
                    navigate(item.link);
                  }
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "2px solid #3E337A",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      "transparent linear-gradient(180deg, #2C2165 0%, #3E337A 100%) 0% 0% no-repeat padding-box",
                  }}
                >
                  <img
                    src={item.icon}
                    alt={item.label}
                    style={{
                      maxWidth: "50%",
                      maxHeight: "50%",
                    }}
                  />
                </div>

                <p
                  className={styles.userPerosnalcentre}
                  style={{
                    textAlign: "center",
                    margin: "5% auto",
                  }}
                >
                  {t(`${item.label}`)}
                </p>

                {item.new && (
                  <p
                    className={styles.NewTextStyle}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    NEW
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>

        {logoutModal && (
          <LogoutConfirmationModal
            logoutModal={logoutModal}
            closeLogoutModal={() => setLogoutModal(false)}
          />
        )}
      </div>
      {/* <div className="mt-4">
        <SideAccount />
      </div> */}
    </div>
  );
};

export default Account;
