import { Button, Modal } from "antd";
import styles from "./BonusModel.module.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { promotionsAPI } from "../../../../Api/PromotionApi";
import clientConfig from "../../../../config.json";
import DOMPurifyPage from "../domPurify/DomPurifyPage";
import { CloseOutlined } from "@ant-design/icons";

const BonusModel = ({ show, hideModal, selectedBonus }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const [promotionData, setPromotionData] = useState(selectedBonus);
  const screenWidth = window.innerWidth;

  // Set width based on screen size
  const modalWidth =
    screenWidth <= 600 ? 300 : screenWidth <= 1280 ? 700 : 1000;

  useEffect(() => {
    setPromotionData(selectedBonus);
  }, [selectedBonus]);

  return (
    <Modal
      className="ml-[0px] md:ml-[0px] lg:ml-[200px] xl:ml-[250px]"
      open={show}
      centered
      // width={1000}
      width={modalWidth}
      onCancel={hideModal}
      footer={null}
      closeIcon={
        <CloseOutlined
          style={{
            marginRight: "40px",
            marginTop: "20px",
            fontSize: "16px",
            color: "gray",
          }}
        />
      }
    >
      <div className={`overflow-y-auto scrollbar ${styles.modalOverlay}`}>
        <div className={styles.modalContentWrapper}>
          <img
            src={promotionData?.image}
            className="imgStyle"
            alt="iconImg"
            style={{
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            }}
          />

          <div style={{ width: "100%", padding: "20px" }}>
            {promotionData?.body ? (
              <div className={styles.bodyContainer}>
                <DOMPurifyPage htmlContent={promotionData?.body} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BonusModel;
