import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import AppHeader from "./Header/AppHeader";
import AppFooter from "./Footer/AppFooter";
import Sidebar from "./Sidebar/Sidebar";
import styles from "./Layout.module.css";
import { useLocation } from "react-router-dom";
import useLogout from "./Header/useLogout";
import { useDispatch } from "react-redux";
import { setUser, setUserInfo } from "../redux/Slice/UserSlice";
import { APIUser } from "../Api/Apis";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import MainPageLoading from "../components/shared-components/MainPageLoading/MainPageLoading";

const Layouts = ({ children }) => {
  const logout = useLogout();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const userInfo = localStorage.getItem("auth_token");

  // get user info
  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      dispatch(setUser(localStorage.getItem("auth_token")));
      userInfoApi();
    }
  }, [userInfo, pathname]);

  // User balance API
  const userInfoApi = async () => {
    const userInfoApiRes = await APIUser();
    if (userInfoApiRes?.response?.status === 401) {
      logout();
    } else if (userInfoApiRes?.response?.status === 400) {
      if (userInfoApiRes?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      localStorage?.setItem("currency_id", userInfoApiRes?.currency_id);
      dispatch(setUserInfo(userInfoApiRes));
    }
  };

  return (
    <Layout style={{ backgroundColor: "#14132D" }}>
      {pathname?.toLowerCase().includes("login") ||
      pathname?.toLowerCase() === "/register" ? (
        <div
          className="wrapperClass"
          style={{
            boxSizing: "border-box",
            overflow: "hidden",
            flex: "1",
            width: "100%",
            // height: "100vh",
          }}
        >
          <main className={` ${styles.loginContent}  `}>{children}</main>
        </div>
      ) : (
        <>
          <div
            className={`${styles.sidebarContent} z-40 w-[150px] md:w-[200px] lg:w-[220px] xl:w-[250px] flex h-screen fixed `}
          >
            <Sidebar />
          </div>

          <Layout
            className="scrollbar flex flex-col flex-1 ml-[150px] md:ml-[200px] lg:ml-[20%] xl:ml-[250px]"
            style={{ backgroundColor: "#14132D" }}
          >
            <AppHeader />
            <Content style={{ overflow: "initial" }}>{children}</Content>
            <AppFooter />
          </Layout>
        </>
      )}
    </Layout>

    // <div className={`${styles.wrapper} flex `}>
    //   {pathname.includes("login") || pathname === "/register" ? (
    //     <>
    //       <div
    //         className="wrapperClass"
    //         style={{
    //           boxSizing: "border-box",
    //           overflow: "hidden",
    //           flex: "1",
    //           width: "100%",
    //         }}
    //       >
    //         {/* <AppHeader /> */}
    //         <main className={` ${styles.loginContent}  overflow-y-auto`}>
    //           {children}
    //         </main>
    //       </div>
    //     </>
    //   ) : (
    //     <>
    //       <div
    //         // style={{ width: "14%" }}
    //         className={`${styles.sidebarContent} z-40 w-[150px] md:w-[200px] lg:w-[20%] xl:w-[250px] flex h-screen fixed `}
    //       >
    //         <Sidebar />
    //       </div>

    //       <div
    //         className="scrollbar flex flex-col flex-1 ml-[150px] md:ml-[200px] lg:ml-[20%] xl:ml-[250px]"
    //         // style={{ marginLeft: "14%" }}
    //       >
    //         <AppHeader />
    //         <main className={` ${styles.content}  overflow-y-auto`}>
    //           {children}
    //         </main>
    //         <AppFooter />
    //       </div>
    //     </>
    //   )}
    // </div>
  );
};

export default Layouts;
