import { createSlice } from '@reduxjs/toolkit';

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    activeCatId: null,
    allCategories:[],
  },
  reducers: {
    setActiveCatId: (state, action) => {
      state.activeCatId = action.payload;
    },
    setAllCategories: (state, action) => {
      state.allCategories = action.payload;
    }
  },
});

export const { setActiveCatId, setAllCategories } = categoriesSlice.actions;

export const selectActiveCatId = (state) => state.categories.activeCatId;

export default categoriesSlice.reducer;
