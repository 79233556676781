import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import supportImage from "../../../assets/bestwaycs.webp";
import CallIcon from "../../../assets/images/social-media/LIVECHAT.png";
import ArrowIcon from "../../../assets/images/social-media/Go@2x.png";
import TelegramIcon from "../../../assets/images/social-media/Telegram.png";
// import chatbot from "../../../assets/images/social-media/CHATBOTA 3.04d8da5943c1fc6c81eb@2x.png";
import { Link } from "react-router-dom";
import SmallLoader from "../Loader/SmallLoader";
import { useTranslation } from "react-i18next";

const Support = () => {
  const [loading, setLoading] = useState(true);
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <div className="h-[65vh] flex justify-center items-center ">
          <SmallLoader />
        </div>
      ) : (
        <div className="bg-inherit flex flex-wrap items-center pt-10 px-4 lg:px-8 lg:min-h-[60vh] ">
          <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
            <h2 className="text-white text-3xl font-semibold">
              {t("Support")}
            </h2>
            <div className="mt-10">
              <span className="text-2xl font-medium text-white mb-2 block">
                {t("Welcome to our")}{" "}
                <span className="text-indigo-600">{t("BW9")}</span>{" "}
                {t("Service Center")}
              </span>
              <h2 className="text-lg text-white mb-8 font-normal">
                {t("Hello")},{" "}
                <span className="font-normal text-white">
                  {userInfoReduxData ? userInfoReduxData?.name : "Customer"}
                </span>
              </h2>
              <div className="flex flex-col gap-4 w-full max-w-xl">
                <Link
                  style={{ textDecoration: "none" }}
                  to="https://tawk.to/chat/66b5b380146b7af4a43823fa/1i4qsabtu"
                  target="_blank"
                >
                  <div
                    className="flex items-center p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #2C2164 0%, #3E337A 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    <div className="flex-shrink-0 mr-4">
                      <img
                        src={CallIcon}
                        alt="Live Chat"
                        className="w-12 h-12"
                      />
                    </div>
                    <div className="flex-grow">
                      <span className="block text-xl font-normal text-white">
                        {t("Live Chat")}
                      </span>
                      <span className="block text-gray-400">
                        {t(
                          "Leave us a contact number and our customer service representative will call you back shortly."
                        )}
                      </span>
                    </div>
                    <div className="flex-shrink-0 ml-4">
                      <img src={ArrowIcon} alt="Go" className="w-6 h-6" />
                    </div>
                  </div>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to="https://t.me/BW9Suport"
                  target="_blank"
                >
                  <div
                    className="flex items-center px-4 py-8  rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                    style={{
                      background:
                        "transparent linear-gradient(180deg, #2C2164 0%, #3E337A 100%) 0% 0% no-repeat padding-box",
                    }}
                  >
                    <div className="flex-shrink-0 mr-4">
                      <img
                        src={TelegramIcon}
                        alt="Telegram"
                        className="w-12 h-12"
                      />
                    </div>
                    <div className="flex-grow">
                      <span className="block text-xl font-normal text-white">
                        {t("Telegram")}
                      </span>
                      <span className="block text-gray-400">
                        {t("Available 24/7")}
                      </span>
                    </div>
                    <div className="flex-shrink-0 ml-4">
                      <img src={ArrowIcon} alt="Go" className="w-6 h-6" />
                    </div>
                  </div>
                </Link>
                {/* <div className="flex items-center p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
            style={{
              background:
                'transparent linear-gradient(180deg, #2C2164 0%, #3E337A 100%) 0% 0% no-repeat padding-box',
            }}

            >
              <div className="flex-shrink-0 mr-4">
                <img src={chatbot} alt="Chat Bot" className="w-12 h-12" />
              </div>
              <div className="flex-grow">
                <span className="block text-xl font-semibold text-white">
                  Chat Bot
                </span>
                <span className="block text-gray-400">
                  Answer Frequently Asked Questions
                </span>
              </div>
              <div className="flex-shrink-0 ml-4">
                <img src={ArrowIcon} alt="Go" className="w-6 h-6" />
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center mt-12">
            <img
              src={supportImage}
              alt={t("Support")}
              className="w-full h-auto max-w-md rounded-lg shadow-md"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Support;
