import React, { useState } from "react";
import SeachIcon from "../../../assets/images/search-icon/SearchIcon.svg";
import styles from "./searchBar.module.css";

const SearchBar = ({
  width,
  height,
  background,
  borderRadius,
  opacity,
  placeholder,
  value,
  onInputChange,
}) => {
  // const [value, setValue] = useState("");

  const searchBarStyle = {
    width: width || "270px",
    height: height || "36px",
    background: background || "#3D3279",
    borderRadius: borderRadius || "5px",
    opacity: opacity || 1,
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    // setValue(newValue);
    onInputChange(newValue); 
  };

  return (
    <div className={`mx-auto rounded-lg overflow-hidden`}>
      <div className="relative">
        <img
          className="absolute top-0 left-0 mt-3 ml-3"
          src={SeachIcon}
          alt="icon"
        />

        <input
          style={searchBarStyle}
          type="text"
          className={`${styles.searchBarContainer} px-12 rounded-lg focus:outline-none hover:cursor-pointer`}
          name=""
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default SearchBar;
