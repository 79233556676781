import React from "react";
import styles from "./PaymentMethod.module.css";
import Loader from "../../../shared-components/Loader/Loader";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import { useTranslation } from "react-i18next";

const PaymentMethod = (props) => {
  const { t } = useTranslation();
  const {
    userBankMethods,
    handleSelectCategory,
    selectedMethod,
    selectedIcon,
    methodLoading,
    loading,
    addWallet,
    addEWallet,
    type
  } = props;
  return (
    <>
      {/* <div className={styles.cardWrapper}> */}
        {/* <div className={styles.stepHolder}>
          <p className={styles.stepText}>Step 2: Choose Payment Method</p>
        </div> */}
        {/* <div className={styles.paymentMethodWrapper}> */}
          <div className={`${type === 'withdraw' ? styles.withdrawbankmethodMainContainer : styles.bankmethodMainContainer}`}>
            {/* <p className={styles.paymentTextStyle}>Payment Account</p> */}
            <div className={`${type === 'withdraw' ? styles.withdrawBankMethodsContainer : styles.bankMethodsContainer}`}>
              <div style={{ marginBottom: "10px" }}>
                <span className={styles.sectionLabel}>
                  {t(`Payment Accounts`)}
                </span>
              </div>
              <div className={`${type === 'withdraw' ? styles.withdrawPayAddmethodContainer : ""}`}>
              {userBankMethods && userBankMethods.length ? (
                userBankMethods?.map((method) => {
                  return (
                    <div
                      onClick={(e) => handleSelectCategory(e, method)}
                      className={styles.gCashModal}
                      style={{
                        maxWidth: type==='withdraw' ? '270px':'',
                        border:
                          selectedMethod.id === method.id
                            ? "2px solid #FFFFFF"
                            : "",
                        marginBottom: "10px",
                      }}
                      key={method?.id}
                    >
                      <div style={{ display: "flex" }}>
                        <p className={styles.holderNameStyle}>
                          {method?.bank_code?.public_name?.en_public_name}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p
                          className={styles.holderNameStyle}
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {method?.account_name}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <tooltip
                          title={
                            method?.account_number.length > 22
                              ? method?.account_number
                              : ""
                          }
                        >
                          <p
                            className={styles.holderNameStyle}
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {method?.account_number}
                          </p>
                        </tooltip>
                      </div>
                      {selectedMethod?.id === method?.id ? (
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            position: "absolute",
                            right: "0px",
                            bottom: "0px",
                          }}
                        >
                          <img
                            src={selectedIcon}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            alt="selectedIcon"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
              {methodLoading ? (
                <SmallLoader />
              ) : (
                <div
                  className={`${styles.addWalletContainer} ${
                    userBankMethods && userBankMethods?.length < 3 && !loading
                      ? styles.buttonActive
                      : styles.disabled
                  }`}
                  style={{maxWidth:type==='withdraw' ? '270px' :''}}
                  onClick={addWallet}
                >
                  <div
                    style={{
                      display: "flex",
                      maxWidth: 20,
                      maxHeight: 20,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={addEWallet}
                      className="imgStyle"
                      alt="addEwalletIcon"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className={styles.addeWalletTextStyling}>
                      {t(`Add Payment Method`)}
                    </p>
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default PaymentMethod;
