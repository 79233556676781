import React, { useEffect, useState } from "react";
import BW9Logo from "../../assets/images/company-logo/Bw9 latest logo.png";
import styles from "./Sidebar.module.css";
import BonusIcon from "../../assets/BonusIcon.png";
import InactiveDepositeIcon from "../../assets/images/Side Menu/InactiveDepositIcon.png";
import InactiveWithdrawIcon from "../../assets/images/Side Menu/InactiveWithdrawIcon.png";
import InactiveMyAccountIcon from "../../assets/images/Side Menu/InactiveMyAccountIcon.png";
import InactiveSupportIcon from "../../assets/images/Side Menu/InactiveSupportIcon.png";
import ActiveDepositeIcon from "../../assets/images/Side Menu/ActiveDepositIcon.png";
import ActiveWithdrawIcon from "../../assets/images/Side Menu/ActiveWithdrawIcon.png";
import ActiveMyAccountIcon from "../../assets/images/Side Menu/ActiveMyAccountIcon.png";
import ActiveSupport from "../../assets/images/Side Menu/Active Support.png";
import FacebookIcon from "../../assets/images/social-media/Facebook@2x.png";
import InstagramIcon from "../../assets/images/social-media/Instagram@2x.png";
import TelegramIcon from "../../assets/images/social-media/Telegram@2x.png";
import WhatsappIcon from "../../assets/images/social-media/WhatsApp@2x.png";
import { getGameCategories } from "../../Api/GamesApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QuestPopup from "../../components/shared-components/QuestPopup/QuestPopup";
import useControlBodyOverflow from "../../components/Helper/Custom Hooks/useControlBodyOverflow";
import TiktokIcon from "../../assets/images/social-media/Tiktok.webp";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveCatId,
  setAllCategories,
} from "../../redux/Slice/CategoriesSlice";
import { setTrendingGames } from "../../redux/Slice/TrendingSlice";
import LanguageSwitcher from "./components/LanguageSwitcher";
import clientConfig from "../../config.json";
import { useTranslation } from "react-i18next";

const paymentData = [
  {
    text: "Deposit",
    inactiveIcon: InactiveDepositeIcon,
    activeIcon: ActiveDepositeIcon,
    link: "deposit",
  },
  {
    text: "Withdraw",
    inactiveIcon: InactiveWithdrawIcon,
    activeIcon: ActiveWithdrawIcon,
    link: "withdraw",
  },
  {
    text: "My Account",
    inactiveIcon: InactiveMyAccountIcon,
    activeIcon: ActiveMyAccountIcon,
    link: "account",
  },
];

const affiliateData = [
  {
    text: "Support",
    inactiveIcon: InactiveSupportIcon,
    activeIcon: ActiveSupport,
    link: "support",
  },
];

const socialMediaLinks = [
  {
    text: "Facebook",
    icon: FacebookIcon,
    link: "https://www.facebook.com/Bw9PH",
  },
  {
    text: "Instagram",
    icon: InstagramIcon,
    link: "https://www.instagram.com/bw9ph/",
  },
  {
    text: "Telegram",
    icon: TelegramIcon,
    link: "https://t.me/+4Xz7OJ_QiEllODY9",
  },
  {
    text: "Whatsapp",
    icon: WhatsappIcon,
    link: "https://wa.me/qr/PGSYWFFQ3HNZA1",
  },
  {
    text: "Tiktok",
    icon: TiktokIcon,
    link: "https://www.tiktok.com/@bw9ph",
  },
];

function extractData(data) {
  const fullUrl = data.split("/").filter(Boolean);
  const category = fullUrl[0] || "";
  const id = fullUrl[1] || "";
  return { category, id };
}

const Sidebar = () => {
  // const clientConfig = useSelector((state) => state.client.clientConfig);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [gameCategories, setGameCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { category, id } = extractData(pathname);
  const [activeQuestPopup, setActiveQuestPopup] = useState(false);
  const location = useLocation();
  const [firstPath, setFirstPath] = useState();
  const allCategories = useSelector(
    (state) => state?.categories?.allCategories
  );

  const activeCatId = allCategories?.find(
    (category) =>
      JSON.parse(category.name).en.toLowerCase().replace(/\s+/g, "-") ===
      location.pathname.split("/")[1].toLowerCase()
  );

  const navigate = useNavigate();
  useControlBodyOverflow(activeQuestPopup);

  const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";
  const GameCategoriesFunc = async () => {
    setLoading(true);

    let currency_id = clientConfig.currencyBackendCode;
    const res = await getGameCategories(currency_id);
    if (res && res !== "NETWORK_ERROR") {
      setGameCategories(res);
      dispatch(setAllCategories(res));
      dispatch(setActiveCatId(res[0]));
      const trendingIcons = res.filter((item) =>
        item.properties_array.some((prop) => prop.trending === true)
      );
      dispatch(setTrendingGames(trendingIcons));

      const firstCategoryName = res
        ? JSON.parse(res[0]?.name)?.en?.toLowerCase()
        : "/";
      setFirstPath(firstCategoryName);

      if (location.pathname === "/") {
        navigate(`/${firstCategoryName}`, { replace: true });
      }
    } else {
      setGameCategories([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    GameCategoriesFunc();
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between h-16 px-3 mt-3.5">
        <Link to={`/${firstPath}`}>
          <img
            className="cursor-pointer w-14 h-7 md:w-16 md:h-9"
            src={BW9Logo}
            alt="Logo"
          />
        </Link>
        <LanguageSwitcher />
      </div>
      <div className={`scrollbar flex flex-col flex-1 overflow-y-auto `}>
        <nav className={`flex-1 ${styles.nav}`}>
          <div className={`${styles.bonusWrapper} mb-2`}>
            <Link to="/promotions">
              <div
                className={`flex items-center ${styles.bonusContainer} p-2 mb-1 cursor-pointer`}
              >
                <button className="flex items-center">
                  <img
                    className="w-5 h-5 mr-2 lg:w-7 lg:h-7"
                    src={BonusIcon}
                    alt="bonus icon"
                  />
                  <div className={`${styles.textStyle}`}>
                    {" "}
                    {t("PROMOTIONS")}
                  </div>
                </button>
              </div>
            </Link>
          </div>

          {/* <div className="">
            {gameCategories?.map((category, index) => {
              const categoryName = JSON.parse(category?.name)?.en.toLowerCase();
              const formattedCategoryName = categoryName
                ? categoryName.charAt(0).toUpperCase() +
                  categoryName.slice(1).toLowerCase()
                : "";
              return (
                <div
                  onClick={() => {
                    if (category?.id === activeCatId?.id) return;
                    navigate(`/${categoryName}`);
                  }}
                  key={category?.id}
                  className={`${
                    activeCatId?.id === category?.id
                      ? `${styles.contentContainerActive}`
                      : `${styles.contentContainer}`
                  }  p-2 mb-1 cursor-pointer`}
                >
                  <div className="w-full max-w-xs min-w-[10rem] lg:max-w-md lg:min-w-[10rem] xl:max-w-lg xl:min-w-[12rem]">
                    <button className="flex items-center">
                      <img
                        className="w-5 h-5 mr-2 lg:w-7 lg:h-7"
                        src={
                          activeCatId?.id === category.id
                            ? category.icon_active
                            : category.icon_image
                        }
                        alt={`img${category?.id}`}
                      />
                      <div className={styles.contentTextStyle}>
                        {t(`${formattedCategoryName.toUpperCase()}`)}
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </div> */}

          <div className="">
            {gameCategories?.map((category, index) => {
              const categoryNameEn = JSON.parse(
                category?.name
              )?.en.toLowerCase();
              // console.log("categoryNameEn", categoryNameEn);
              const formattedCategoryNameEN = categoryNameEn
                ? categoryNameEn.charAt(0).toUpperCase() +
                  categoryNameEn.slice(1).toLowerCase()
                : "";

              const parsedName = JSON.parse(category?.name);
              const categoryName = parsedName?.[selectedLanguage]
                ? parsedName[selectedLanguage].toLowerCase()
                : categoryNameEn?.toLowerCase();

              const formattedCategoryName = categoryName
                ? categoryName.charAt(0).toUpperCase() +
                  categoryName.slice(1).toLowerCase()
                : formattedCategoryNameEN;

              return (
                <div
                  onClick={() => {
                    if (category?.id === activeCatId?.id) return;
                    const navigateCategoryName = categoryNameEn.includes(" ")
                      ? categoryNameEn.replace(/\s+/g, "-")
                      : categoryNameEn;
                    navigate(`/${navigateCategoryName}`);
                  }}
                  key={category?.id}
                  className={`${
                    activeCatId?.id === category?.id
                      ? `${styles.contentContainerActive}`
                      : `${styles.contentContainer}`
                  } p-2 mb-1 cursor-pointer`}
                  style={{
                    width: screenWidth <= 768 ? "auto" : "auto",
                    // Adjust the above condition according to your design's breakpoint for switching to tabs
                  }}
                >
                  <div className="w-full max-w-xs min-w-auto lg:max-w-md lg:min-w-[10rem] xl:max-w-lg xl:min-w-[12rem]">
                    <button className="flex items-center">
                      <img
                        className="w-5 h-5 mr-2 lg:w-7 lg:h-7"
                        src={
                          activeCatId?.id === category.id
                            ? category.icon_active
                            : category.icon_image
                        }
                        alt={`img${category?.id}`}
                      />
                      <div className={styles.contentTextStyle}>
                        {`${formattedCategoryName.toUpperCase()}`}
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={`${styles.line} my-5`}></div>

          <div className="mt-4">
            {paymentData.map((item, index) => (
              <Link to={`/${item.link}`} key={index}>
                <div
                  className={`${
                    item.link === category
                      ? `${styles.contentContainerActive}`
                      : `${styles.contentContainer}`
                  }  p-2 mb-1 cursor-pointer`}
                >
                  <button className="flex items-center">
                    <img
                      className="w-5 h-5 mr-2 lg:w-7 lg:h-7"
                      src={
                        item.link === category.toLowerCase()
                          ? item.activeIcon
                          : item.inactiveIcon
                      }
                      alt={`${item.text} icon`}
                    />
                    <div className={styles.contentTextStyle}>
                      {t(`${item.text}`)}
                    </div>
                  </button>
                </div>
              </Link>
            ))}
          </div>

          <div className={`${styles.line} my-5`}></div>
          <div className="mt-4">
            {affiliateData.map((item, index) => (
              <Link to={`/${item.link}`} key={index}>
                <div
                  className={`${
                    item.link === category
                      ? `${styles.contentContainerActive}`
                      : `${styles.contentContainer}`
                  }  p-2 mb-1 cursor-pointer`}
                >
                  <button className="flex items-center">
                    <img
                      className="w-5 h-5 mr-2 lg:w-7 lg:h-7"
                      src={
                        item.link === category.toLowerCase()
                          ? item.activeIcon
                          : item.inactiveIcon
                      }
                      alt={`${item.text} icon`}
                    />
                    <div className={styles.contentTextStyle}>
                      {t(`${item.text}`)}
                    </div>
                  </button>
                </div>
              </Link>
            ))}
          </div>
          <div className={`${styles.line} my-5`}></div>

          <div className={`text-[#8C8BDE] ${styles.contentTextStyle}`}>
            {t("Follow us")}
          </div>
          <div className="flex mt-4 space-x-2 ">
            {socialMediaLinks.map((item, index) => (
              <div key={index} className="flex items-center">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <img
                    className="md:w-7 md:h-7 lg:w-7 lg:h-7 xl:w-9 xl:h-9"
                    src={item.icon}
                    alt={`${item.text} icon`}
                  />
                </a>
              </div>
            ))}
          </div>
        </nav>
      </div>
      {activeQuestPopup && <QuestPopup setTrigger={setActiveQuestPopup} />}
    </div>
  );
};

export default Sidebar;
