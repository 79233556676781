import { api } from "./axios";
// Account providers List API

export const getBettingRecordProviders = async () => {
  try {
    const res = await api.get(`providers/list`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getBettingRecord = async (
  id,
  provider_id,
  start_data,
  end_date,
  page
) => {
  try {
    // Construct the base URL
    let url = `player/bet_rounds/list?per_page=10&player_id=${id}&page=${page}`;

    // Append provider_id if it exists
    if (provider_id) {
      url += `&provider=${provider_id}`;
    }

    // Append start_data if it exists
    if (start_data) {
      url += `&from_date=${start_data}`;
    }

    // Append end_date if it exists
    if (end_date) {
      url += `&to_date=${end_date}`;
    }

    const res = await api.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });

    if (res?.data) {
      return res.data;
    }

    return res;
  } catch (error) {
    console.log(error);
    return null;
  }
};

