import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./AppFooter.module.css";
import BW9Logo from "../../assets/images/company-logo/BW9New.png";
import FacebookIcon from "../../assets/images/social-media/facebooknew.png";
import InstagramIcon from "../../assets/images/social-media/instagramnew.png";
import TelegramIcon from "../../assets/images/social-media/telegramnew.png";
import XIcon from "../../assets/images/social-media/TwitterX.png";
import TiktokIcon from "../../assets/images/social-media/Tiktok.webp";
import WhatsappIcon from "../../assets/images/social-media/whatsappnew.png";
import YoutubeIcon from "../../assets/images/social-media/YouTube.png";
import responsibleGameimg from "../../assets/ResponsibleGamingLatest.png";
import Image1 from "../../assets/images/providers/1.png";
import Image2 from "../../assets/images/providers/2.png";
import Image3 from "../../assets/images/providers/3.png";
import Image4 from "../../assets/images/providers/4.png";
import Image5 from "../../assets/images/providers/5.png";
import Image6 from "../../assets/images/providers/6.png";
import Image7 from "../../assets/images/providers/7.png";
import Image8 from "../../assets/images/providers/8.png";
import Image9 from "../../assets/images/providers/9.png";
import Image10 from "../../assets/images/providers/10.png";
import Image11 from "../../assets/images/providers/11.png";
import Image12 from "../../assets/images/providers/12.png";
import Image13 from "../../assets/images/providers/13.png";
import Image14 from "../../assets/images/providers/14.png";
import Image15 from "../../assets/images/providers/15.png";
import Image16 from "../../assets/images/providers/16.png";
import Image17 from "../../assets/images/providers/17.png";
import Image18 from "../../assets/images/providers/18.png";
import Image19 from "../../assets/images/providers/19.png";
import Image20 from "../../assets/images/providers/20.png";
import Image21 from "../../assets/images/providers/21.png";
import gcash from "../../assets/images/payment icons/Gcash@2x.png";
import maya from "../../assets/images/payment icons/Maya@2x.png";
import { useTranslation } from "react-i18next";

const ProviderLinks = [
  {
    id: 1,
    icon: Image1,
  },
  {
    id: 2,
    icon: Image2,
  },
  {
    id: 3,
    icon: Image3,
  },
  {
    id: 4,
    icon: Image4,
  },
  {
    id: 5,
    icon: Image5,
  },
  {
    id: 6,
    icon: Image6,
  },
  {
    id: 7,
    icon: Image7,
  },
  {
    id: 8,
    icon: Image8,
  },
  {
    id: 9,
    icon: Image9,
  },

  {
    id: 10,
    icon: Image10,
  },
  {
    id: 11,
    icon: Image11,
  },
  {
    id: 12,
    icon: Image12,
  },
  {
    id: 13,
    icon: Image13,
  },
  {
    id: 14,
    icon: Image14,
  },
  {
    id: 15,
    icon: Image15,
  },
  {
    id: 16,
    icon: Image16,
  },
  {
    id: 17,
    icon: Image17,
  },
  {
    id: 18,
    icon: Image18,
  },
  {
    id: 19,
    icon: Image19,
  },
  {
    id: 20,
    icon: Image20,
  },
  {
    id: 21,
    icon: Image21,
  },
];

const paymentMethod = [
  {
    id: 1,
    text: "Gcash",
    icon: gcash,
  },
  {
    id: 2,
    text: "Maya",
    icon: maya,
  },
];
const AppFooter = () => {
  const { t } = useTranslation();

  const productsLink = [
    {
      id: 1,
      name: "Sports",
      link: "/sport",
    },
    {
      id: 2,
      name: "Live Casino",
      link: "/casino",
    },
    {
      id: 3,
      name: "Slots",
      link: "/slots",
    },
    {
      id: 4,
      name: "Fishing",
      link: "/fishing",
    },

    {
      id: 5,
      name: "Sabong",
      link: "/sabong",
    },
  ];

  const aboutUsLink = [
    {
      id: 1,
      name: "About Us",
      link: "/about-us",
    },
    {
      id: 2,
      name: "Terms and Conditions",
      link: "/terms-and-conditions",
    },
    // {
    //   id: 3,
    //   name: "Agent Program",
    //   link: "/affiliate",
    // },
    {
      id: 4,
      name: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      id: 6,
      name: "Responsible Gaming",
      link: "/responsible-gaming",
    },
  ];

  const socialMediaLinks = [
    {
      text: "Facebook",
      icon: FacebookIcon,
      link: "https://www.facebook.com/Bw9PH",
    },
    {
      text: "Instagram",
      icon: InstagramIcon,
      link: "https://www.instagram.com/bw9ph/",
    },
    {
      text: "Telegram",
      icon: TelegramIcon,
      link: "https://t.me/+4Xz7OJ_QiEllODY9",
    },
    // { text: "X", icon: XIcon, link: "https://www.example.com" },
    {
      text: "Whatsapp",
      icon: WhatsappIcon,
      link: "https://wa.me/qr/PGSYWFFQ3HNZA1",
    },
    // {
    //   text: "Youtube",
    //   icon: YoutubeIcon,
    //   link: "https://www.youtube.com/example",
    // },  TiktokIcon
  ];

  return (
    <footer
      className="text-white"
      style={{
        background: "#24224A 0% 0% no-repeat padding-box",
      }}
    >
      <div className="footerContainer py-2 ml-3" style={{ width: "96%" }}>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 mt-10">
          <div style={{ alignContent: "center" }}>
            <div className={` ${styles.logo}`}>
              <img src={BW9Logo} alt="BW9 Logo" />
            </div>
            <div className={`${styles.responsibleGaming}`}>
              <img
                src={responsibleGameimg}
                className="w-full h-auto mt-2"
                alt="Responsible Gaming"
              />
            </div>
          </div>

          <div style={{ marginLeft: "auto", marginRight: "15%" }}>
            <p className={styles.products}>{t(`gameCategories`)}</p>
            <ul className={`mt-2 ${styles.listStyle}`}>
              {productsLink.map((item) => (
                <li key={item.id} className="mt-2">
                  <Link to={item.link} className="hover:underline">
                    {t(`${item.name}`)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div style={{ marginLeft: "auto", marginRight: "15%" }}>
            <p className={styles.links}>{t(`helpCenter`)}</p>
            <ul className={`mt-2 ${styles.listStyle}`}>
              {aboutUsLink.map((item) => (
                <li key={item.id} className="mt-2">
                  <Link to={item.link} className="hover:underline">
                    {t(`${item.name}`)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div style={{ marginLeft: "auto", marginRight: "15%" }}>
            <p className={styles.paymentMethods}>{t(`PaymentMethods`)}</p>
            <ul className={`mt-2 flex ${styles.listStyle}`}>
              {paymentMethod.map((item) => (
                <li key={item.id}>
                  <img
                    className={
                      item.text == "Gcash"
                        ? styles.iconStyleGcash
                        : styles.iconStyleMaya
                    }
                    src={item.icon}
                    alt={`${item.text} icon`}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div style={{ marginLeft: "auto", marginRight: "15%" }}>
            <p className={styles.followUs}>{t(`Follow us`)}</p>
            <ul className={`flex mt-3 ${styles.listStyle}`}>
              {socialMediaLinks.map((item) => (
                <li key={item.text}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.text == "Facebook" ? (
                      <img
                        className={styles.fbIconStyle}
                        src={item.icon}
                        alt={`${item.text} icon`}
                      />
                    ) : (
                      <img
                        className={styles.iconStyle}
                        src={item.icon}
                        alt={`${item.text} icon`}
                      />
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.bottomBorder}></div>
        <div className="mt-8">
          <ul
            className={`flex flex-wrap justify-center space-x-4 space-y-2 ${styles.listStyle}`}
          >
            {ProviderLinks.map((item) => (
              <li key={item.id} className={styles.providersIconStyle}>
                <img
                  className={"h-9"}
                  src={item.icon}
                  alt={`${item.text} icon`}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.bottomShadowBorder}>
        <p className={styles.rights}>&copy; {t(`rights`)}</p>
      </div>
    </footer>
  );
};

export default AppFooter;
