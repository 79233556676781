import { api } from "./axios";

export const promotionsAPI = async (currency, id, searchName) => {
  try {
    // Build the base query string
    let query = `/player/promotions?currency=${currency}`;

    // Add the category_id if id is not "All"
    if (id !== "All") {
      query += `&category_id=${id}`;
    }

    // Add the search_value if searchName is provided
    if (searchName) {
      query += `&search_value=${encodeURIComponent(searchName)}`;
    }

    const res = await api.get(query, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });

    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const promotionsCategoryAPI = async () => {
  try {
    const res = await api.get(`/player/promotion_categories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};
