import configs from "../config";

const getClient = () => {
  const hostname = window.location.hostname;
  const pathname = window.location.pathname;

  // Extract the first two segments of the pathname
  const pathSegments = pathname.split("/").filter(Boolean);
  const pathKey = pathSegments.slice(0, 2).join("/");

  let config;

  switch (pathKey) {
    case "ph/d":
      config = configs.client1;
      break;
    case "bg/d":
      config = configs.client2;
      break;
    // case "":
    //   config = configs.client3;
    //   break;
    default:
      config = configs.default;
      break;
  }

  // Add additional keys based on the hostname
  if (hostname === "bw9.io") {
    config = {
      ...config,
      captchaKey: "6LfRLvUpAAAAAAjmR3557cYFVTDuHs0-nsIWMO9o",
    };
  } else if (
    hostname === "wmdesktop-staging.iegaming.io" ||
    hostname === "wmdesktop-master.iegaming.io"
  ) {
    config = {
      ...config,
      captchaKey: "6Ld1ofApAAAAAFG78E4InZcxvOT-WxridSFnhhaZ",
    };
  } else if (hostname === "localhost") {
    config = {
      ...config,
      captchaKey: "6Ld1ofApAAAAAFG78E4InZcxvOT-WxridSFnhhaZ",
    };
  }

  return config;
};

export default getClient;
