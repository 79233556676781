import React, { useState, useEffect } from "react";
import styles from "./WithdrawInput.module.css";
import { currencyHelper } from "../../../Helper/currencyHelper";
import { CommaSeperator, toFixedFunc } from "../../../Helper/HelperFunction";
import InputField from "../../../shared-components/inputField/InputField";
import balanceIcon from "../../../../assets/images/payment icons/Balance.png";
import { useTranslation } from "react-i18next";
import { APITurnover } from "../../../../Api/Apis";
import LoginPopupModal from "./../../Login/LoginPopupModal";
import { useNavigate } from "react-router-dom";

const WithdrawInput = (props) => {
  const { t } = useTranslation();
  const [turnoverData, setTurnoverData] = useState(null);
  const [popupError, setPopupError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    inputBox,
    amount,
    userInfoReduxData,
    withdrawamountReduxData,
    handleInputChange,
    handleKeyPress,
    withdrawMinAmount,
    withdrawMaxAmount,
    noAmountError,
    setTurnoverDataCallback,
  } = props;
   
  useEffect(() => {
    const fetchTurnoverData = async () => {
      try {
        const res = await APITurnover();
        if (res?.data) {
          setTurnoverData(res?.data);
          setTurnoverDataCallback(res?.data);
        }
      } catch (error) {
        console.error("Error fetching turnover data:", error);
      }
    };

    fetchTurnoverData();
  }, []);

  const closePopup = () => {
    // navigate("/");
    setPopupError("");
    setLoading(false); // Reset loading state when closing the popup
    navigate("/withdraw");
  };

  useEffect(() => {
    if (amount != null && amount !== "") {
      if (
        userInfoReduxData?.balance &&
        CommaSeperator(toFixedFunc(userInfoReduxData?.balance)) < 100
      ) {
        setPopupError(t("Dont have enough balance to make a withdrawal Request"));
      }
    }
  }, [amount]);
  

  return (
    <>
      <div className={styles.cardWrapper}>
        <div className="flex items-center gap-4">
          <div className={styles.balanceTextStyling}>{t(`Balance`)}:</div>
          <div className="flex items-center gap-2">
            <div className={styles.balanceIconStyle}>
              <img src={balanceIcon} className="imgStyle" alt="balanceIcon" />
            </div>
            <div className={styles.balanceContainer}>
              <p className={styles.balanceStyling}>
                {currencyHelper(userInfoReduxData?.currency)}
                {userInfoReduxData?.balance &&
                  CommaSeperator(toFixedFunc(userInfoReduxData?.balance))}
              </p>
            </div>
          </div>
        </div>

        {/* Withdrawal turnover requirements */}
        {/* */}
        
        {/* below condition is commited to display the turnover data details to user */}
        {/* {turnoverData && turnoverData.remainingTurnOver !== 0 && ( */}   
          <div className="wrapper">
            <p className="text-white">{t(`Withdrawal turnover requirements`)}</p>

            {/* Conditional message based on remainingTurnOver */}
            {turnoverData && turnoverData.remainingTurnOver > 0 && (
              <div className="mt-2 text-red-500">
                {t(`Please complete the required turnover for withdrawal`)}
              </div>
            )}
            <div className="flex items-center mt-4">
              <table className="w-full max-w-md overflow-hidden text-white border-collapse rounded-lg table-fixed">
                <thead>
                  <tr className={styles.tableHead}>
                    <th className="w-1/2 pb-2 ">{t(`Total Turnover Required`)}</th>
                    <th className="w-1/2 pb-2 ">{t(`Remaining Turnover Required`)}</th>
                  </tr>
                </thead>
                <tbody className={`${styles.tableBody} bg-darkblue`}>
                  <tr>
                    <td className="w-1/2 py-4 text-center border-r-2 border-[#262141]">
                      {turnoverData
                        ? CommaSeperator(turnoverData.totalDepositSum)
                        : "-"}
                    </td>
                    <td className="w-1/2 py-4 text-center text-red-500">
                      {turnoverData
                        ? CommaSeperator(turnoverData.remainingTurnOver)
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        {/* )} */}
        <div className="flex flex-col mt-3">
          <div className="flex flex-wrap items-center">
            <div className={styles.inputTitle}>
              {t(`Withdraw Points`)}: ({withdrawMinAmount} - {withdrawMaxAmount}
              )
            </div>
            <div className="w-full mt-2">
              <InputField
                placeHolder="0.00"
                extraStyle={inputBox}
                type="text"
                name="withdraw"
                prefix={
                  <p
                    style={{
                      fontSize: "24px",
                      opacity: 1,
                      color: amount.length ? "#FFFFFF" : "#574F85",
                      marginRight: "10px",
                    }}
                  >
                    {currencyHelper(userInfoReduxData?.currency)}
                  </p>
                }
                value={amount || withdrawamountReduxData}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            </div>
            {!amount && noAmountError && (
              <div className={styles.amountError}>{t(`Please Enter Amount`)}</div>
            )}
          </div>

          <div className={styles.minMaxMainContainer}>
            <p className={styles.minMaxTextStyle}>
              {t(`Min`)}:
              <span style={{ color: "#FFFFFF" }}>
                {CommaSeperator(withdrawMinAmount)}
              </span>
            </p>
            <p className={styles.minMaxTextStyle}>
              {t(`Max`)}:{" "}
              <span style={{ color: "#FFFFFF" }}>
                {CommaSeperator(withdrawMaxAmount)}
              </span>
            </p>
          </div>
        </div>

        {popupError && (
          <LoginPopupModal
            message={popupError}
            show={popupError ? true : false}
            errorIcon={popupError ? true : false}
            hideModal={closePopup}
          />
        )}
      </div>
    </>
  );
};

export default WithdrawInput;
