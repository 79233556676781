import { Divider, Popover } from "antd";
import styles from "./Overview.module.css";
import ShareYourFriendIcon from "../../../../assets/images/accounts/Share to your friends.png";
import CopyIcon from "../../../../assets/images/accounts/Copy.png";
import { TfiMenuAlt } from "react-icons/tfi";
import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import ShareToFriendsModal from "../../../shared-components/share-to-friendsModal/ShareToFriendsModal";
import clientConfig from "../../../../config.json";

const Overview = ({ incomeTabs, incomeData }) => {
  const [visible, setVisible] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const referralURL = `${window.location.origin}/register?${incomeData?.url}`;

  const handleCopy = (value) => {
    copy(value);
    setVisible(true);
    // Hide the Popover after 2 seconds
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  return (
    <div className="lg:flex">
      <div className="lg:w-[45%]">
        <div className={`${styles.filterContainer}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 14,
            }}
          >
            {incomeTabs &&
              incomeTabs.slice(0, 3).map((tab) => (
                <div className={styles.tabsStyle} key={tab?.id}>
                  <div className={styles.tabLabelStyle}>{tab.label}</div>
                  <div>
                    <span className={styles.incomeIcon}>{tab.icon}</span>{" "}
                    <span className={styles.tabData}>{tab.data}</span>
                  </div>
                </div>
              ))}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 14,
            }}
          >
            {incomeTabs &&
              incomeTabs.slice(3).map((tab) => (
                <div className={styles.tabsStyle} key={tab?.id}>
                  <div className={styles.tabLabelStyle}>{tab.label}</div>
                  <div>
                    <span className={styles.incomeIcon}>{tab.icon}</span>{" "}
                    <span className={styles.tabData}>{tab.data}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className={styles.referalLinkContainer}>
          <div className={styles.referalTitle}>Share to your friends</div>
          <div className={styles.referalLinkInput}>{referralURL}</div>
          <div className={styles.buttonsContainer}>
            <div
              className={styles.btnStyle}
              onClick={() => setShareModal(true)}
            >
              <img className="w-7 h-7" src={ShareYourFriendIcon} alt="icon" />
              <span className={styles.btnText}>Share to your friends</span>
            </div>

            <div
              className={`${styles.btnStyle}`}
              onClick={() => handleCopy(referralURL)}
            >
              <Popover
                overlayStyle={{ width: "60px" }}
                content={"copied"}
                trigger="click"
                open={visible}
              >
                <img className={`w-5 h-5 mr-1`} src={CopyIcon} alt="icon" />
              </Popover>
              <span className={styles.btnText}>Copy link</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center my-10 lg:my-0 lg:mx-10 xl:mx-20 items-center">
        <div className="bg-gray-500 w-full h-[2px] lg:w-[2px] lg:h-full" />
      </div>

      {/* Rules Section */}
      <div
        className="lg:w-[45%]"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className={styles.rulesTitle}>
          <TfiMenuAlt /> Rules
        </div>
        <div className="flex flex-col gap-3 mt-4" style={{ width: "100%" }}>
          {incomeData &&
            incomeData?.rules.map((rule, index) => (
              <p key={index} className={styles.rulesStyle}>
                * {rule}
              </p>
            ))}
        </div>
      </div>

      {shareModal && (
        <ShareToFriendsModal
          logoutModal={shareModal}
          closeLogoutModal={() => setShareModal(false)}
          shareUrl={referralURL}
        />
      )}
    </div>
  );
};

export default Overview;
