import React from "react";
import styles from "./PaymentMethod.module.css";
import selectedIcon from "../../../../assets/images/payment icons/Selected Method.png";
import Loader from "../../../shared-components/Loader/Loader";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";

const PaymentMethod = (props) => {
  const { methodLoading, paymentMethods, selectedMethod, handleSelectMethod } =
    props;
  return (
    <div className={`${styles.cardWrapper} `}>
      {methodLoading ? (
        <SmallLoader />
      ) : paymentMethods && paymentMethods.length ? (
          paymentMethods.map((method) => {
          if (!method || typeof method.id === 'undefined') {
            return null; // Skip rendering if method or method.id is undefined
          }

          return (
            <div
              className={styles.goCashInactive}
              style={{
                width: "270px",
                height: "70px",
                marginBottom: "10px",
                padding: "17px",
                minWidth:"30px",
                border: selectedMethod && selectedMethod.id === method.id ? "2px solid #FFFFFF" : "",
              }}
              key={method.id}
              onClick={(e) => handleSelectMethod(e, method)}
            >
              <div style={{ width: "36px", height: "30px" }}>
                <img
                  src={method.bank_code?.full_image}
                  className={styles.gcashtyling}
                  alt="iconImg"
                />
              </div>
              <span style={{ color: "white", fontFamily: "Poppins"}}>
                {method?.bank_code?.public_name?.en_public_name}
              </span>
              {selectedMethod && selectedMethod.id === method.id && (
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    position: "absolute",
                    right: "0px",
                    bottom: "0px",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={selectedIcon}
                    className="imgStyle"
                    alt="selectedIcon"
                  />
                </div>
              )}
            </div>
          );
        })
      ) : (
        // <NoDataComp />
        null
      )}
    </div>
  );
};

export default PaymentMethod;
