import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import UserSlice from "./Slice/UserSlice";
import CategoriesSlice from "./Slice/CategoriesSlice";
import TrendingSlice from "./Slice/TrendingSlice";
import ClientConfigSlice from "./Slice/ClientConfigSlice";

const reducers = combineReducers({
  user: UserSlice,
  categories: CategoriesSlice,
  trending: TrendingSlice,
  client: ClientConfigSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // Redux Thunk is included by default, so no need to add it here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export default store;
