import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../BettingRecord.module.css";
import NoDataComp from "../../../shared-components/NoData/NoDataComp";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import { currencyHelper } from "../../../Helper/currencyHelper";
import { CommaSeperator, toFixedFunc } from "../../../Helper/HelperFunction";
import copyIcon from "../../../../assets/images/accounts/Copy.png";
import leftSwipe from "../../../../assets/images/swip/Left Swip.png";
import rightSwipe from "../../../../assets/images/swip/Right Swip.png";
import { useTranslation } from "react-i18next";

const BettingRecordTable = ({ methodLoading, bettingRecordData }) => {
  const { t } = useTranslation();
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const entriesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  function formatDateTime(timestamp) {
    const [datePart, timePart] = timestamp.split(" ");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    let [hours, minutes, seconds] = timePart.split(":");
    let period = "AM";
    hours = parseInt(hours, 10);
    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }
    const formattedTime = `${hours}:${minutes}:${seconds} ${period}`;
    return `${formattedTime} ${formattedDate}`;
  }
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => {
        console.error("Unable to copy to clipboard:", error);
      });
  }

  // Calculate total number of pages
  const totalPages = Math.ceil(bettingRecordData.length / entriesPerPage);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = Math.min(
    startIndex + entriesPerPage,
    bettingRecordData.length
  );

  const visiblePages = [];
  for (let i = currentPage - 2; i <= currentPage + 2; i++) {
    if (i > 0 && i <= totalPages) {
      visiblePages.push(i);
    }
  }

  return (
    <div>
      <div
        className={`relative mt-10 overflow-x-auto shadow-md sm:rounded-lg ${styles.tableRecords}`}
      >
        <table className="w-full text-sm text-left text-gray-500 rtl:text-right ">
          <thead
            className="text-xs bg-table"
            style={{ fontWeight: "normal", font: "Poppins", color: "#FFFFFF" }}
          >
            <tr>
              <th scope="col" className="px-6 py-4 font-normal">
                {t(`Reference ID`)}
              </th>
              <th scope="col" className="px-6 py-3 font-normal">
                {t(`Game Name`)}
              </th>
              <th scope="col" className="px-6 py-3 font-normal">
                {t(`Bet Time`)}
              </th>
              <th scope="col" className="px-6 py-3 font-normal">
                {t(`Bet Amount`)}
              </th>
              <th scope="col" className="px-6 py-3 font-normal">
                {t(`Valid Bet`)}
              </th>
              <th scope="col" className="px-6 py-3 font-normal">
                {t(`Profit/Loss`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {methodLoading ? (
              <tr>
                <td colSpan="6" className="px-6 py-4">
                  <SmallLoader />
                </td>
              </tr>
            ) : bettingRecordData && bettingRecordData.length > 0 ? (
              bettingRecordData
                .slice(startIndex, endIndex)
                .map((record, index) => (
                  <tr
                    key={index}
                    className="border-b bg-body dark:bg-body dark:border-gray-700"
                  >
                    <td
                      className="flex flex-row gap-2 px-6 py-4 font-medium text-white whitespace-nowrap"
                      onClick={() => copyToClipboard(record.id)}
                    >
                      {record.id}
                      <img
                        src={copyIcon}
                        alt="Copy"
                        className="copy-icon cursor-pointer"
                      />
                    </td>
                    <td className="px-6 py-4 font-light text-white whitespace-nowrap">
                      {JSON.parse(record.name)?.en}
                    </td>
                    <td className="px-6 py-4 font-light text-white whitespace-nowrap">
                      {formatDateTime(record.started_on)}
                    </td>
                    <td className="px-6 py-4 font-light text-white whitespace-nowrap">
                      {currencyHelper(userInfoReduxData?.currency)}{" "}
                      {CommaSeperator(toFixedFunc(record.total_turnovers))}
                    </td>
                    <td className="px-6 py-4 font-light text-white whitespace-nowrap">
                      {currencyHelper(userInfoReduxData?.currency)}{" "}
                      {CommaSeperator(toFixedFunc(record.total_valid_bets))}
                    </td>
                    <td
                      className={`px-6 py-4  ${
                        record.win_loss < 0 ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {CommaSeperator(toFixedFunc(record.win_loss))}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="6" className="px-6 py-4">
                  <NoDataComp />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
    </div>
  );
};

export default BettingRecordTable;
