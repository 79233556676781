import React, { useEffect, useState } from "react";
import styles from "./Bonus.module.css";
import {
  promotionsAPI,
  promotionsCategoryAPI,
} from "../../../Api/PromotionApi";
import CustomButton from "../../Helper/CustomButton";
import Loader from "../../shared-components/Loader/SmallLoader";
import NoDataComp from "../../shared-components/NoData/NoDataComp";
import { Link, useNavigate } from "react-router-dom";
import backIcon from "../../../assets/images/back-icon/BackIcon.png";
import clientConfig from "../../../config.json";
import PromtionActiveIcon from "../../../assets/images/Side Menu/Promotion_Active.png";
import { Card, Col, List, Row } from "antd";
import Meta from "antd/es/card/Meta";
import BonusModel from "./BonusModel/BonusModel";
import SearchBar from "../../shared-components/searchBar/SearchBar";

const Bonus = () => {
  const currencyCode = clientConfig?.currencyBackendCode;
  const navigate = useNavigate();
  const [promotionCategory, setPromotionCategory] = useState([]);
  const [selectedId, setSelectedId] = useState("All");
  const [promotionData, setPromotionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBonus, setSelectedBonus] = useState();
  const [searchName, setSearchName] = useState();

  const promotionCategoryFunc = async () => {
    setLoading(true);
    const res = await promotionsCategoryAPI(currencyCode);
    if (res) {
      setPromotionCategory(res);
    } else {
      setPromotionCategory([]);
    }

    // promotionDataFunc();
    setLoading(false);
  };
  const getPromotionDataFunc = async (id, searchName) => {
    setLoading(true);
    const res = await promotionsAPI(currencyCode, id, searchName);
    if (res) {
      setPromotionData(res);
      // console.log("sssss", res);
    } else {
      setPromotionData([]);
    }

    // promotionDataFunc();
    setLoading(false);
  };

  // useEffect(() => {
  //   promotionDataFunc(selectedButton);
  // }, [selectedButton, promotionData]);

  useEffect(() => {
    getPromotionDataFunc(selectedId, searchName);
  }, [selectedId, searchName]);

  useEffect(() => {
    setSearchName("");
  }, [selectedId]);

  useEffect(() => {
    promotionCategoryFunc();
  }, []);

  const handleBonusPopup = (promotion) => {
    setSelectedBonus(promotion);
  };

  const onInputChange = (value) => {
    setSearchName(value);
  };

  return (
    <div style={{ width: "100%", overflow:"hidden" }}>
      <div className="flex justify-normal  mt-4 ml-3 space-x-2">
        <div>
          {/* <Link to="/">
            <img src={backIcon} alt="back" />
          </Link> */}
          <img src={PromtionActiveIcon} alt="back" />
        </div>
        <div>
          <p className="text-xl font-medium text-white">BW9 Hot Deals</p>
        </div>
      </div>
      <div className={styles.container}>
        <div
          className={styles.buttonsContainer}
          style={{
            display: "flex",
            // flexWrap: "wrap",
            // width: "100%",
            // justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: "10px",
            }}
          >
            {promotionCategory && promotionCategory.length ? (
              <>
                <div className={styles.buttonWrapper}>
                  <CustomButton
                    text="All Promotions"
                    className="no-hover"
                    type={`${styles.buttonsStyle} ${
                      selectedId === "All"
                        ? styles.activeBtnBg
                        : styles.inactiveBtnBg
                    }`}
                    onClick={() => setSelectedId("All")}
                  />
                </div>
                {promotionCategory.map((category) => {
                  const categoryName = category?.name?.en;
                  return (
                    <div className={styles.buttonWrapper} key={category.id}>
                      <CustomButton
                        text={categoryName}
                        className="no-hover"
                        type={`${styles.buttonsStyle} ${
                          selectedId === category.id
                            ? styles.activeBtnBg
                            : styles.inactiveBtnBg
                        }`}
                        onClick={() => setSelectedId(category.id)}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <SearchBar
              placeholder="Search for promotions..."
              value={searchName}
              onInputChange={onInputChange}
            />
          </div>
        </div>

        {loading ? (
          <div style={{ height: "45vh", paddingTop: "20vh" }}>
            <Loader />
          </div>
        ) : promotionData && promotionData.length ? (
          <List
            style={{ padding: "10px" }}
            grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 4 }}
            dataSource={promotionData}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                // className={styles.CardStyle}
                onClick={() => {
                  handleBonusPopup(item);
                }}
              >
                <div className={` ${styles.CardStyle}`}>
                  <div className={styles.imageContainer}>
                    <div className={styles.imageOverlay}>
                      <div className={styles.playButtonContainer}>
                        <CustomButton
                          text="More Info"
                          className="no-hover"
                          type={styles.moreInfoBtn}
                          background="transparent linear-gradient(180deg, #5158DE 0%, #613ADE 48%, #711ADE 100%) 0% 0% no-repeat padding-box"
                        />
                      </div>
                    </div>
                    <img
                      alt={item.title}
                      src={item.desktop_image}
                      loading="lazy"
                      style={{
                        // padding: "2px",
                        // height: "159px",
                        // height: "auto",
                        // objectFit: "fill",
                      }}
                      className="imgStyle"
                    />
                    <Meta
                      title={
                        <div
                          className={styles.titleStyle}
                          style={{ textAlign: "center" }}
                        >
                          {item?.title}
                        </div>
                      }
                      description={
                        <div
                          className={styles.descStyle}
                          style={{ textAlign: "center" }}
                        >
                          {item?.sub_title}
                        </div>
                      }
                    />
                  </div>
                </div>
              </List.Item>
            )}
          />
        ) : (
          // <Row
          //   // gutter={20}
          //   style={{
          //     width: "100%",
          //     display: "flex",
          //     // justifyContent: "space-evenly",
          //     // gap:"10px"
          //   }}
          // >
          //   {promotionData &&
          //     promotionData.map((promotion) => (
          //       <Col
          //         span={6}
          //         xxl={6}
          //         lg={8}
          //         md={12}
          //         xs={24}
          //         key={promotion.id}
          //         className={styles.CardStyle}
          //         onClick={() => {
          //           handleBonusPopup(promotion);
          //         }}
          //       >
          //         <div className={styles.imageContainer}>
          //           <div className={styles.imageOverlay}>
          //             <div className={styles.playButtonContainer}>
          //               <CustomButton
          //                 text="More Info"
          //                 className="no-hover"
          //                 type={styles.moreInfoBtn}
          //                 background="transparent linear-gradient(180deg, #5158DE 0%, #613ADE 48%, #711ADE 100%) 0% 0% no-repeat padding-box"
          //                 // onClick={() => setSelectedId(promotion.id)}
          //               />
          //             </div>
          //           </div>
          //           <img
          //             alt={promotion.title}
          //             src={promotion.desktop_image}
          //             loading="lazy"
          //             style={{
          //               padding: "2px",
          //               // width: "388px",
          //               height: "159px",
          //               objectFit: "fill",
          //             }}
          //             className="imgStyle"
          //           />
          //           <Meta
          //             title={
          //               <div
          //                 className={styles.titleStyle}
          //                 style={{ textAlign: "center" }}
          //               >
          //                 {promotion?.title}
          //               </div>
          //             }
          //             description={
          //               <div
          //                 className={styles.descStyle}
          //                 style={{ textAlign: "center" }}
          //               >
          //                 {promotion?.sub_title}
          //               </div>
          //             }
          //           />
          //         </div>
          //       </Col>
          //     ))}
          // </Row>
          <NoDataComp />
        )}
      </div>

      <BonusModel
        selectedBonus={selectedBonus}
        show={selectedBonus || selectedBonus ? true : false}
        hideModal={() => {
          setSelectedBonus("");
        }}
      />
    </div>
  );
};

export default Bonus;
