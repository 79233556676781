import React from "react";

const Loader = () => {
  return (
    <div
      className="fixed z-[101] inset-0 bg-[#575583]"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "2px 0",
        // background: "#24224A",
        backgroundColor: 'rgba(0, 0, 0, .5)',
      }}
    >
      <div className="flex gap-4 p-4 flex-wrap justify-center">
        <img
          className="w-20 h-20 animate-spin"
          src="https://www.svgrepo.com/show/199956/loading-loader.svg"
          alt="Loading icon"
        />
        {/* <img
          className="w-20 h-20 animate-spin"
          src="https://www.svgrepo.com/show/169757/loading-process.svg"
          alt="Loading icon"
        />
        <img
          className="w-20 h-20 animate-spin"
          src="https://www.svgrepo.com/show/70469/loading.svg"
          alt="Loading icon"
        /> */}
      </div>
    </div>
  );
};

export default Loader;
