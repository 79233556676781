import { api } from "./axios";
// Account providers List API

export const APIInviteFriendsOverview = async () => {
  try {
    const res = await api.get(`player/affiliate/inviteFriendsOverview`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const APIInviteFriendsRecords = async ({
  page,
  start_date,
  end_date,
}) => {
  try {
    const res = await api.get(
      `player/affiliate/downlines?page=${page}${
        start_date && end_date
          ? `&from_date=${start_date}&to_date=${end_date}`
          : ``
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    );
    if (res?.data) {
      return res.data;
    }
    return res;
  } catch (error) {
    console.log(error);
  }
  return null;
};
