import React, { useState } from "react";
import styles from "./GcashPage.module.css";
import SmallLoader from "../../shared-components/Loader/SmallLoader";

const GCashPage = ({ data = "", transactionId, result = {} }) => {
  const [loading, setLoading] = useState(true);
  const hanldleLoad = () => {
    setLoading(false);
  };

  return (
    <div className={styles.iframeContainer}>
      {loading && <SmallLoader />}
      <iframe
        src={data}
        title="Embedded Page"
        frameBorder="0"
        allowFullScreen
        className={styles.responsiveIframe}
        onLoad={hanldleLoad}
      ></iframe>
    </div>
  );
};

export default GCashPage;
