import React from "react";
import styles from "./LoginPopupModal.module.css";
import SuccessIcon from "../../../assets/Success.png";
import WarnigIcon from "../../../assets/error.png";

const LoginPopupModal = ({ show, hideModal, message, errorIcon = true }) => {
  return (
    <div className={`${styles.modalOverlay} fixed z-50 inset-0 `}>
      <div className={styles.errorContainer}>
        <div className={styles.modalContentWrapper}>
          <div style={{ height: 80, width: 80 }}>
            <img
              src={errorIcon ? WarnigIcon : SuccessIcon}
              className="imgStyle"
              alt="iconImg"
            />
          </div>
          <div style={{ display: "flex" }}>
            <p className={styles.messageStyling}>{message}</p>
          </div>
          <button onClick={hideModal} className={styles.okButton}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPopupModal;
