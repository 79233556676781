import React, { useEffect, useState, useRef } from "react";
import styles from "./Platforms.module.css";
import AllProviders from "../../../../assets/Providers/ALL.png";
import LeftIcon from "../../../../assets/Left.svg";
import RightIcon from "../../../../assets/Right.svg";

const Platforms = ({
  platforms,
  setSelectedPlatforms,
  selectedPlatforms,
  setMulitpleSelectedPlatforms,
  setGames,
  setUpdateAllPlatForm,
  setPage,
  setSearchData,
  // setTempAllAvailableGames,
  setAllAvailableGames,
  setTempCategoryAndGames,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const platformLength = platforms && platforms.length;

  const handlePlatform = (platformId) => {
    if (platformId === selectedPlatforms[0]) {
    } else {
      setSearchData("");
      setPage(1);
      setGames([]);
      setMulitpleSelectedPlatforms([]);
      setSelectedPlatforms([platformId]);
      setUpdateAllPlatForm([]);
      setAllAvailableGames([]);
      setTempCategoryAndGames(null);
    }
  };

  const handlePlatformAll = () => {
    setSearchData("");
    setPage(1);
    setGames([]);
    setUpdateAllPlatForm(platforms);
    setSelectedPlatforms([]);
    setAllAvailableGames([]);
    // setTempAllAvailableGames(null);
    // Call the API when "All" is clicked
    // fetchAllGames();
  };

  //Scrollable Content
  const scrollableContainerRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialScrollX, setInitialScrollX] = useState(0);

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setInitialMouseX(event.clientX);
    setInitialScrollX(scrollableContainerRef.current.scrollLeft);
    scrollableContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = event.clientX - initialMouseX;
    scrollableContainerRef.current.scrollLeft = initialScrollX - deltaX;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    scrollableContainerRef.current.style.cursor = "grab";
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLeftClick = () => {
    scrollableContainerRef.current.scrollTo({
      left: scrollableContainerRef.current.scrollLeft - 250,
      behavior: "smooth",
    });
  };

  const handleRightClick = () => {
    scrollableContainerRef.current.scrollTo({
      left: scrollableContainerRef.current.scrollLeft + 250,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.platformContainer}>
      {platforms.length > 5 ? (
        <img
          style={{ cursor: "pointer" }}
          src={LeftIcon}
          className={`${styles.newArrIcon} ${styles.leftArrow}`}
          alt="LeftIcon"
          onClick={handleLeftClick}
        />
      ) : (
        ""
      )}
      <div
        className={`${styles.platformWrapper}`}
        ref={scrollableContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div
          className={`${styles.singlePlatform} ${
            selectedPlatforms.length === 0 && styles.activePlatform
          } `}
          onClick={() => handlePlatformAll()}
        >
          <div className={styles.imgWrapper}>
            <img
              className={styles.imgStyle}
              alt={`platformImage`}
              src={AllProviders}
            />
          </div>
          <span
            className={`${styles.title} ${
              selectedPlatforms.length === 0 && styles.activeTitle
            } `}
          >
            All Games
          </span>
        </div>
        {platforms?.map((platform) => {
          return (
            <div
              className={`${styles.singlePlatform} ${
                selectedPlatforms.includes(platform.id) && styles.activePlatform
              } `}
              key={platform?.id}
              onClick={() => handlePlatform(platform.id)}
            >
              <div className={styles.imgWrapper}>
                <img
                  className={styles.imgStyle}
                  alt={`platformImage`}
                  src={platform.icon_image}
                />
              </div>
              <span
                className={`${styles.title} ${
                  selectedPlatforms.includes(platform.id) && styles.activeTitle
                } `}
              >
                {platform.name}
              </span>
            </div>
          );
        })}
      </div>
      {platforms.length > 5 ? (
        <img
          style={{ cursor: "pointer" }}
          src={RightIcon}
          className={`${styles.newArrIcon} ${styles.rightArrow}`}
          alt="RightIcon"
          onClick={handleRightClick}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Platforms;
