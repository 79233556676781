import { createSlice } from "@reduxjs/toolkit";
export const WithdrawSlice = createSlice({
    name: "user",
    initialState: {
        withdrawamount: null,
    },
    reducers: {
        setWithdrawAmount: (state, action) => {
            state.withdrawamount = action.payload;
        },
    },
});

export const { setWithdrawAmount } = WithdrawSlice.actions;

export default WithdrawSlice.reducer;
