import { Button, Modal } from "antd";
import styles from "./LoginPopupModal.module.css";
import WarnigIcon from "../../assets/error.png";
import SuccessIcon from "../../assets/Success.png";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const LoginPopupModal = ({ show, hideModal, message, errorIcon = true }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={show}
      centered
      // onCancel={hideModal}
      footer={null}
      closable={false}
    >
      <div className={styles.modalOverlay}>
        <div className={styles.errorContainer}>
          <div className={styles.modalContentWrapper}>
            <div style={{ height: 80, width: 80 }}>
              <img
                src={errorIcon ? WarnigIcon : SuccessIcon}
                className="imgStyle"
                alt="iconImg"
              />
            </div>
            <div style={{ display: "flex" }}>
              <p className={styles.messageStyling}>{t(message)}</p>
            </div>
            <Button onClick={hideModal} className={styles.okButton}>
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginPopupModal;
