import React, { useEffect, useState } from "react";
import styles from "./Notificatoins.module.css";
import NotificationIcon from "../../../../assets/No Notification@2x.png";
import { useSelector } from "react-redux";
import {
  getAllNotifications,
  putMarkAsReadNotifications,
} from "../../../../Api/NotificationsApi";
import Loader from "../../../shared-components/Loader/Loader";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import { Link } from "react-router-dom";
import backimage from "../../../../assets/images/back-icon/BackIcon.png";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();

  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const [loading, setLoading] = useState(false);
  const [notificationsInfo, setNotificationsInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [showViewMore, setShowViewMore] = useState(true);

  const putMarkAsReadNotificationsFunc = async (id) => {
    setLoading(true);
    const res = await putMarkAsReadNotifications(id);
    setLoading(false);
  };

  const GetNotificationsFunc = async (id) => {
    setLoading(true);
    const res = await getAllNotifications(id, page);
    // console.log("notification : ",res.data)
    if (res?.data) {
      if (page === 1) {
        setNotificationsInfo(res.data.player_notifications);
      } else {
        setNotificationsInfo((prevNotifications) => [
          ...prevNotifications,
          ...res.data.player_notifications,
        ]);
      }
      if (res?.data?.player_notifications?.length < 10) {
        setShowViewMore(false);
      }
    } else {
      setNotificationsInfo([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetNotificationsFunc(userInfoReduxData?.id);
    putMarkAsReadNotificationsFunc(userInfoReduxData?.id);
  }, [page]);

  const handleViewMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className={styles.container}>
      <div className="flex mt-4 space-x-5 p-7 justify-normal items-center">
        <Link to="/account">
          <img
            src={backimage}
            alt="back"
            style={{ width: "13px", height: "21px" }}
          />
        </Link>
        <h2 className={styles.headTitleDesign}>{t(`Notification`)}</h2>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-80">
          <SmallLoader />
        </div>
      ) : (
        <div className={styles.notificationCardContainer}>
          {notificationsInfo?.length > 0 ? (
            <>
              {notificationsInfo.map((item, index) => (
                <div key={index} className={styles.notificationCard}>
                  <div className={styles.titleWithTime}>
                    <p className={styles.notificationTitleStyle}>
                      {item?.notification?.title}
                    </p>
                    <p className={styles.notificationTimeStyle}>
                      {timeAgoFromNow(item.notification?.created_at)}
                    </p>
                  </div>

                  <p className={styles.notificationDescStyle}>
                    {item?.notification?.description}
                  </p>
                  <hr
                    className="my-0 border-t border-gray-700"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                </div>
              ))}
              {showViewMore && (
                <div className="flex items-center justify-center my-4">
                  <button
                    className="px-4 py-2 text-white border border-gray-700 rounded bg-gradient-to-r from-blue-600 to-purple-600 focus:outline-none"
                    onClick={handleViewMore}
                  >
                    {t(`View More`)}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className={styles.imgContainer}>
              <div className={styles.noImgStyle}>
                <img
                  className={styles.imgStyle}
                  src={NotificationIcon}
                  alt="notificationIcon"
                />
                <p className={styles.noNotificationTitleStyle}>
                  {t("No Notification")}
                </p>
              </div>

              <p className={styles.noNotificationDescStyle}>
                {t("There are currently no new system messages.")}
              </p>
              <p className={styles.noNotificationDescStyle}>
                {t("Please come back later!")}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;

function timeAgoFromNow(timestamp) {
  const time = new Date(timestamp);
  const timeDifference = new Date() - time;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Format the time difference
  if (days > 0) {
    return days === 1 ? "1 day ago" : days + " days ago";
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : hours + " hours ago";
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : minutes + " minutes ago";
  } else {
    return "Just now";
  }
}
