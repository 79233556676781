import React, { useEffect, useState } from "react";
import styles from "./Deposit.module.css";
import PaymentMethod from "./components/PaymentMethod";
import useLogout from "../../../layouts/Header/useLogout";
import {
  APIMakeDepositRequest,
  depositAllowed,
  paymentMethodsAndCategories,
} from "../../../Api/TransactionApi";
import {
  CommaSeperator,
  handleKeyPress,
  toFixedFunc,
} from "../../Helper/HelperFunction";
import GCashPage from "../Deposit/GcashPage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DepositInput from "./components/DepositInput";
import Loader from "../../shared-components/Loader/Loader";
import ErrorPopup from "./components/error/ErrorPopup";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import LoginPopupModal from "../../Helper/LoginPopupModal"; // Import the popup component
import UsdtQrCode from "./components/UsdtQrCode";

const Deposit = () => {
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logout = useLogout();

  const [amount, setAmount] = useState("");
  const [depositMinAmount, setDepositMinAmount] = useState(500);
  const [depositMaxAmount, setDepositMaxAmount] = useState(50000);
  const [selectedBank, setSelectedBank] = useState(null);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);
  const [bankPayment, setBankPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popupError, setPopupError] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [gCashData, setGCashData] = useState(null);
  const [suggestedAmount, setSuggestedAmount] = useState([]);

  const [banks, setBanks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fetchDataLoading, setFetchDataLoading] = useState(false);
  const [isUsdt, setIsUsdt] = useState(false);
  const [goToUsdtpg, setGoToUsdtPg] = useState(false);

  useEffect(() => {
    const newSuggestedAmount = [];
    for (let i = 1; i <= 5; i++) {
      const value = depositMinAmount * i;
      newSuggestedAmount.push({
        id: i,
        label: value.toString(),
        value: value.toString(),
      });
    }
    setSuggestedAmount(newSuggestedAmount);
  }, [depositMinAmount]);

  const handleSelectMethod = (e, method) => {
    setGoToUsdtPg(false);
    setSelectedBank(method);
    // method.account_name === "USDT" ? setIsUsdt(true) : setIsUsdt(false);
  };

  const handleSelectAmount = (e, btn) => setAmount(btn.value);

  const handleInputChange = (name, value) => setAmount(value);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setFetchDataLoading(true);
    setLoading(true);
    const res = await paymentMethodsAndCategories();
    if (res?.status === 200) {
      // setSelectedCategory(res.data[0] || null);
      setSelectedCategory(res.data || []);
    }
    setFetchDataLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedCategory) {
      //   const uniqueBanks = selectedCategory.payment_methods.reduce(
      //     (uniqueMethods, method) => {
      //       if (
      //         !uniqueMethods.some(
      //           (uniqueMethod) =>
      //             uniqueMethod.bank_code_id === method.bank_code_id
      //         )
      //       ) {
      //         uniqueMethods.push(method);
      //       }
      //       return uniqueMethods;
      //     },
      //     []
      //   );
      //   setBanks(uniqueBanks);
      //   setSelectedBank(uniqueBanks[0] || null);
      //   console.log("unique",uniqueBanks)

      // }
      const uniqueBanks = selectedCategory
        .map((category) => category.payment_methods)
        .flat()
        .reduce((uniqueMethods, method) => {
          if (
            !uniqueMethods.some(
              (uniqueMethod) =>
                uniqueMethod.bank_code_id === method.bank_code_id
            )
          ) {
            uniqueMethods.push(method);
          }
          return uniqueMethods;
        }, []);

      setBanks(uniqueBanks);
      setSelectedBank(uniqueBanks[0] || null);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedBank && selectedCategory.length > 0) {
      const filteredMethods = selectedCategory
        .map((category) =>
          category.payment_methods.filter(
            (method) => method.bank_code_id === selectedBank.bank_code_id
          )
        )
        .flat();

      setFilteredPaymentMethods(filteredMethods);
    }
  }, [selectedBank, selectedCategory]);

  // useEffect(() => {
  //   if (selectedBank) {
  //     const filteredMethods = selectedCategory.payment_methods.filter(
  //       (method) => method.bank_code_id === selectedBank.bank_code_id
  //     );

  //     setFilteredPaymentMethods(filteredMethods);
  //   }
  // }, [selectedBank, selectedCategory]);

  useEffect(() => {
    if (selectedBank) {
      setDepositMinAmount(selectedBank.min_deposit_amount || 500);
      setDepositMaxAmount(selectedBank.max_deposit_amount || 50000);
    }
  }, [selectedBank]);

  useEffect(() => {
    setBankPayment(filteredPaymentMethods[0] || null);
  }, [filteredPaymentMethods]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await depositAllowed();
      if (res?.status === 200) {
        if (res.data.message === "PLAYER_NOT_ALLOWED_TO_DEPOSIT") {
          setPopupError(
            "Your deposit feature has been locked, please contact customer support for assistance."
          );
        }
      }
    };
    fetchData();
  }, []);

  const depositFunc = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader
    const deposit = await APIMakeDepositRequest(bankPayment?.id, amount);
    if (deposit?.response?.data?.message === "PENDING_TRANSACTION") {
      setPopupError("pendingreqerror");
    } else if (deposit?.response?.data?.message === "SUCCESS") {
      setPopupMessage(t("Deposit successful!"));
    } else if (deposit?.response?.data?.message === "TRANSACTION_FAILED") {
      setPopupError(
        t(
          "Deposit Request Failed. Please try again with a different payment option or please try after some time."
        )
      );
    } else if (deposit?.response?.data?.message === "PENDING") {
      navigate("/account/transactions");
    } else if (deposit?.response?.data?.message === "INVALID_AMOUNT") {
      setPopupError(
        `Enter a valid amount between ${CommaSeperator(
          toFixedFunc(depositMinAmount)
        )} - ${CommaSeperator(
          toFixedFunc(depositMaxAmount)
        )} to make deposit request.`
      );
    } else if (deposit?.data?.message === "WAITING_FOR_PAYMENT") {
      setGCashData(deposit?.data?.link);
    } else if (deposit?.response?.status === 403) {
      if (
        deposit?.response?.data?.message === "PLAYER_NOT_ALLOWED_TO_DEPOSIT"
      ) {
        setPopupError(
          "Your deposit feature has been locked, please contact customer support for assistance."
        );
      }
    } else if (deposit?.response?.status === 400) {
      if (deposit?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else if (deposit?.response?.data?.message === "TRANSACTION_FAILED") {
      setPopupError(
        "Deposit Request Failed. Please try again with a different payment option or please try after some time."
      );
    } else if (deposit?.response?.status === 401) {
      logout();
    } else if (deposit?.response?.status === 422) {
      if (deposit?.response?.data?.message?.payment_method_id) {
        setPopupError(deposit?.response?.data?.message?.payment_method_id[0]);
      } else if (deposit?.response?.data?.message?.amount) {
        setPopupError(deposit?.response?.data?.message?.amount[0]);
      } else {
        setPopupError(
          t(
            "Your deposit feature has been locked, please contact customer support for assistance."
          )
        );
      }
    } else if (deposit?.response?.status === 500) {
      setPopupError(t("Internal Server Error. Please try again later."));
    } else if (deposit === "WAIT_PLEASE") {
      setPopupError("pendingreqerror");
    } else {
      if (deposit?.data?.status === 200) {
        navigate("/account/transactions");
      } else {
        setPopupError(
          "We're sorry, but the selected payment method is under maintenance. Kindly choose another method."
        );
      }
    }
    setLoading(false);
  };

  const Usdtfun = (e) => {
    e.preventDefault();
    setGoToUsdtPg(true);
  };

  const checkValidation = () =>
    !amount ||
    Number(amount) < Number(depositMinAmount) ||
    Number(amount) > Number(depositMaxAmount) ||
    !selectedBank ||
    loading ||
    amount.includes(".");

  return (
    <>
      {gCashData ? (
        <GCashPage data={gCashData} />
      ) : (
        <div className={`m-5 min-h-[70vh] pb-5`}>
          <p className={`text-3xl ${styles.headTitleDesign}`}>{t(`Deposit`)}</p>
          <div className={` ${styles.container} w-[100%] pb-5`}>
            <div className="overflow-y-disable">
              {fetchDataLoading ? (
                <div>
                  <SmallLoader />
                </div>
              ) : (
                <PaymentMethod
                  methodLoading={false}
                  paymentMethods={banks}
                  selectedMethod={selectedBank}
                  handleSelectMethod={handleSelectMethod}
                />
              )}
            </div>

            <div
              className={`${styles.cardWrapper} w-[100%] h-[70vh] relative min-h-[70vh]`}
            >
              {selectedBank?.payment_code_name === "USDT" ? (
                <>
                  {goToUsdtpg ? (
                    <UsdtQrCode />
                  ) : (
                    <div style={{ flex: "1", width: "100%", padding: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "98%",
                          marginTop: "38px",
                          marginLeft: "1%",
                          marginRight: "1%",
                          height: "65px",
                          background: "#000326 0% 0% no-repeat padding-box",
                          borderLeftWidth: "5px",
                          borderLeftColor: "#5859F2",
                          borderLeftStyle: "solid",
                          opacity: 1,
                          textAlign: "left",
                          fontFamily: "Roboto",
                          fontWeight: "normal",
                          lineHeight: "19px",
                          fontSize: "18px",
                          letterSpacing: 0,
                          color: "#FFFFFF",
                        }}
                      >
                        <p style={{ marginLeft: "20px" }}>
                          Please Click on "Deposit" to continue
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <DepositInput
                  IsUSDT={isUsdt}
                  fetchDataLoading={fetchDataLoading}
                  depositMinAmount={depositMinAmount}
                  depositMaxAmount={depositMaxAmount}
                  amount={amount}
                  userInfoReduxData={userInfo}
                  handleInputChange={handleInputChange}
                  sugestedAmount={suggestedAmount}
                  bankPaymentmethod={filteredPaymentMethods}
                  selectedPaymentMethod={bankPayment}
                  setSelectedPaymentMethod={setBankPayment}
                  handleSelectAmount={handleSelectAmount}
                />
              )}
              <div className="absolute left-5 bottom-5">
                {loading ? (
                  <SmallLoader />
                ) : (
                  <>
                    {selectedBank?.payment_code_name === "USDT" ? (
                      <>
                        {goToUsdtpg ? (
                          <></>
                        ) : (
                          <button
                            onClick={(e) => Usdtfun(e)}
                            className={`${styles.depositBtn} ${styles.activeButton}`}
                          >
                            {t(`Deposit`)}
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        onClick={(e) => depositFunc(e)}
                        className={`${styles.depositBtn} ${
                          checkValidation()
                            ? styles.disabled
                            : styles.activeButton
                        }`}
                      >
                        {t(`Deposit`)}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {(popupError || popupMessage) && (
        <LoginPopupModal
          message={t(popupError) || popupMessage} // Display either error or success message
          show={popupError || popupMessage ? true : false}
          hideModal={() => {
            setPopupError("");
            setPopupMessage(""); // Reset both messages
            setLoading(false); // Stop loader
            if (
              popupError ===
              "Your deposit feature has been locked, please contact customer support for assistance."
            ) {
              navigate("/support");
            } else if (popupError === "pendingreqerror") {
              navigate("/account/transactions", {
                state: { active: "deposit" },
              });
            } else {
              navigate("/account/transactions", {
                state: { active: "deposit" },
              });
            }
          }}
        />
      )}
    </>
  );
};

export default Deposit;
