import React from "react";
import Logo from "../../../assets/images/company-logo/BW92X.png";
import responsibleGameimg from "../../../assets/ResponsibleGamingNew.png";
import styles from "./AgeVerificationsPopup.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AgeVerificationPopup = ({ show, hideModal }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`${styles.modalOverlay} fixed z-50 inset-0 `}
        // onClick={hideModal}
      >
        <div className={styles.errorContainer}>
          <div className={styles.loadingSection}>
            <div className="flex items-center justify-center">
              <img src={Logo} alt="logo" className={styles.errorImg} />
            </div>
            <div>
              <div className={styles.headerTextStyling}>{t(`ageVerify`)}</div>
              <div className={styles.paraTextStyling}>{t(`ageMessage`)}</div>
              <div
                style={{ margin: "10px 0" }}
                className={styles.paraTextStyling}
              >
                {t(`iAgree`)}

                <Link to="/terms-and-conditions">
                  <span className={styles.termsTextStyling}>{t(`TnC`)}</span>
                </Link>
              </div>
            </div>
            <div className={styles.rejectButtons}>
              <button className={styles.okButton} onClick={hideModal}>
                {t(`age`)}
              </button>
              <Link to="https://www.google.com/">
                <button className={styles.editBtn}>{t(`Exit`)}</button>
              </Link>
            </div>

            <hr className="my-6 border-[#707070]" />
            <div className="text-center">
              <img
                src={responsibleGameimg}
                alt="responsible gaming"
                className="imgStyle"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgeVerificationPopup;
