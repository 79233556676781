import React from "react";
import styles from "./UsdtQrCode.module.css";
import scanner from "../../../../assets/QR.png";
import supportIcon from "../../../../assets/Customer Support.png";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";

function UsdtQrCode() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.scannerSection}>
        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <Typography.Text className={styles.titleText}>USDT</Typography.Text>
          <img src={scanner} className={styles.logoStyle} alt="errorImg" />
        </div>

        <div className={styles.rulesList}>
          <ol style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <li>
              <Typography.Text className={styles.subChildText}>
                1- Scan the QR code.
              </Typography.Text>
            </li>
            <li>
              <Typography.Text className={styles.subChildText}>
                2- Send the selected amount.
              </Typography.Text>
            </li>
            <li>
              <Typography.Text className={styles.subChildText}>
                3- Contact the customer support with a screenshot of the
                transfer.
              </Typography.Text>
            </li>
          </ol>
        </div>

        <div className={styles.logoSection}>
          <Link to="/support" style={{ textDecoration: "none" }}>
            <div
              style={{ display: "flex", alignItems : 'center', justifyContent : 'center', padding: "12px", gap: "10px" }}
              className={styles.customerButton}
            >
              <img src={supportIcon} alt="icon" />
              <Typography.Text className={styles.customerButton1}>
                Customer Support
              </Typography.Text>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default UsdtQrCode;
