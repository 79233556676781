import React from "react";
import { Link } from "react-router-dom";
import CustomButtonAnt from "./CustomButtonAnt";
import styles from "./customButton.module.css";

const CustomButtonAccount = ({
  profileImage,
  lowerCaseFirstCharUserName,
  currency,
  balance,
}) => {
  const handleClick = () => {
    // Handle click logic if needed
  };

  const buttonStyle = {
    fontfamily: "Roboto",
    background: "#3D3279",
    border: "1px solid #3B3F7B",
    cursor: "pointer",
    color: "#FFFFFF",
    width: "100%",
    height: "36px",
  };

  return (
    <div
      style={buttonStyle}
      className={`pr-2 gap-1 flex mx-auto rounded-lg overflow-hidden`}
    >
      <Link to="/account" className="w-full">
        <CustomButtonAnt
          className="w-full"
          width="100%"
          boder="1px solid #3B3F7B"
          height="100%"
          color="#FFFFFF"
          paddingRight="0px"
          icon={profileImage}
          onClick={handleClick}
        >
          <div className="flex flex-col justify-center items-start pr-1">
            <p className={styles.lowercaseText}>{lowerCaseFirstCharUserName}</p>
            <div className="flex justify-center items-center">
              <p
                className={`${styles.balanceText} mr-1`}
                style={{ fontSize: "12px" }}
              >
                {currency}
              </p>
              <p className={styles.balanceText} style={{ fontSize: "12px" }}>
                {balance}
              </p>
            </div>
          </div>
        </CustomButtonAnt>
      </Link>
    </div>
  );
};

export default CustomButtonAccount;
