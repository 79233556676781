import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(i18nBackend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem("selectedLanguage") ?? "en", // Default fallback language code
    debug: false, // Enable debug mode for development
    detection: {
      order: ["cookie", "localStorage", "navigator"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    // Define resources for each language
    resources: {
      en: {
        translation: {
          defaultUserNameMessage:
            "Minimum 8, Maximum 12 Characters. No Special Characters or Space Allowed.",
          defaultPasswordMessage:
            "Minimum 6, Maximum 16 Characters and can include letters, numbers, and special characters.",
          defaultFullNameMessage:
            "Full name without any special characters. Eg: Alden Reyes",
          defaultPhoneMessage: "Enter 10 digit phone number",
          temporaryLockWithdraw:
            "Your account's withdrawal function is temporarily locked, please contact Customer Support.",
          USER_DOES_NOT_EXIST:
            "Username or Password Incorrect. Please try again or contact Customer Care for support",
          LOGIN_LIMIT_EXCEEDED:
            "Account Login Disabled. Please wait a few minutes and try again or contact customer support. Error 10004",
          ACCOUNT_INACTIVE:
            "Player not allowed to log in. Please contact customer support. Error 10003",
          PASSWORD_INCORRECT:
            "Username or Password Incorrect. Please try again or contact Customer Care for support",
          ERROR_500:
            "An error occurred while connecting to the system. Please contact Customer Care",
          DOES_NOT_MATCH:
            "Username or password does not match. Please check again.",
          USER_NAME_VALIDATION:
            "Username must be 8-12 characters long and contain only lowercase letters and numbers.",
          MINIMUM_NAME_VALIDATION:
            "The name must be at least 5 characters long and contain at least 2 words",
          MAXIMUM_NAME_VALIDATION:
            "Name must not exceed 255 characters and contain at least 2 words",
          NAME_PATTERN_VALIDATION:
            "The name should not contain numbers or special characters. Eg: Alden Reyes",
          PASSWORD_VALIDATION:
            "The password must be 6-16 characters long and can include letters, numbers, and special characters.",
          PHONE_NO_VALIDATION: "Phone number must be 10 digits",
          withdrawFeatureLock:
            "Your withdrawal feature has been locked, please contact customer support for assistance.",
          ADMIN_FORBIDDEN:
            "Unable to log into game. Please contact customer support",
          pendingreqerror:
            "You have a pending request. You cannot create a new request while there is a pending request. Please request again later.",
          "Something went wrong, please try again later.":
            "Something went wrong, please try again later.",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "Your deposit feature has been locked, please contact customer support for assistance.",
          "Internal Server Error. Please try again later.":
            "Internal Server Error. Please try again later.",
          ///////////////////// Sidebar  /////////////////////

          BONUS: "BONUS",
          PROMOTIONS: "PROMOTIONS",
          POPULAR: "POPULAR",
          CASINO: "CASINO",
          SLOTS: "SLOTS",
          FISHING: "FISHING",
          SPORT: "SPORT",
          COCKFIGHT: "COCKFIGHT",
          Deposit: "Deposit",
          Withdraw: "Withdraw",
          "My Account": "My Account",
          Support: "Support",
          "Follow us": "Follow us",

          ///////////////////// Header  /////////////////////
          headerText:
            "For the security of your account and funds, please do not disclose your account and password to others to avoid losses.",
          Logout: "Logout",
          Login: "Login",
          signUp: "Sign Up",

          ////////////////////// AddPaymentMethod //////////////////////
          "You have reached the maximum limit of 3 wallets.":
            "You have reached the maximum limit of 3 wallets.",

          ///////////////////// Footer  /////////////////////
          OfficialLicense: "Official License",
          Products: "Products",
          "Live Casino": "Live Casino",
          Slots: "Slots",
          Fishing: "Fishing",
          Sports: "Sports",
          Cockfighting: "Cockfighting",
          Links: "Links",
          "About Us": "About Us",
          "Privacy Policy": "Privacy Policy",
          "Responsible Gaming": "Responsible Gaming",
          "Terms and Conditions": "Terms and Conditions",
          Termsofuse: "Terms of use",
          PaymentMethods: "Payment Methods",
          rights: "2024 BW9 all rights reserved",
          gameCategories: "Game Categories",
          helpCenter: "Help Center",
          depositAmount: "Deposit Amount",

          ///////////////////// Age Verification popup  /////////////////////
          ageVerify: "Age Verification",
          ageMessage:
            " Please verify that you are 21 years old or older to enter this site",
          iAgree: "I agree to the BW9",
          TnC: "Terms and Conditions",
          age: "I'm over 21 years old",
          Exit: "Exit",

          ///////////////////// Page Headers  /////////////////////

          "POPULAR GAMES": "POPULAR GAMES",
          "BEST SLOTS": "BEST SLOTS",
          "New Games-x": "New Games-x",
          "Big Jackpot-x": "Big Jackpot-x",
          "BEST FISHING GAMES": "BEST FISHING GAMES",
          "Trending-y": "Trending-y",
          CASINO: "CASINO",
          SLOTS: "SLOTS",
          FISHING: "FISHING",
          SPORT: "SPORT",
          CockFight: "CockFight",
          Games: "Games",

          ///////////////////// Deposit Page  /////////////////////

          "Payment Method": "Payment Method",
          Deposit: "Deposit",
          "Payment Channel": "Payment Channel",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "Your deposit feature has been locked, please contact customer support for assistance.",

          ///////////////////// Withdraw Page  /////////////////////

          "Payment Accounts": "Payment Accounts",
          "Recent Withdrawal": "Recent Withdrawal",
          "Add Payment Method": "Add Payment Method",
          Balance: "Balance",
          "Withdraw Points": "Withdraw Points",
          Min: "Min",
          Max: "Max",
          "No wallet selected. Please add a wallet to continue.":
            "No wallet selected. Please add a wallet to continue.",

          ///////////////////// Accounts Page  /////////////////////

          Username: "Username",
          Username: "Username",
          "Personal Center": "Personal Center",
          Withdrawal: "Withdrawal",
          "Betting Record": "Betting Record",
          Notifications: "Notifications",
          "Deposit Record": "Deposit Record",
          "Withdrawal Record": "Withdrawal Record",
          "E-wallet Management": "E-wallet Management",
          Logout: "Logout",

          ///////////////////// Betting Record  /////////////////////

          "Betting Record": "Betting Record",
          From: "From",
          To: "To",
          Providers: "Providers",
          Search: "Search",
          Reset: "Reset",

          ///////////////////// Betting Record Table  /////////////////////

          "REFERENCE ID": "REFERENCE ID",
          "GAME NAME": "GAME NAME",
          "BET TIME": "BET TIME",
          "BET AMOUNT": "BET AMOUNT",
          "VALID BET": "VALID BET",
          "PROFIT/LOSS": "PROFIT/LOSS",

          //////////////////////  Betting Record ///////////////////
          "The from date field must match the format Y-m-d H:i:s.":
            "The from date field must match the format Y-m-d H:i:s.",
          "The to date field must match the format Y-m-d H:i:s.":
            "The to date field must match the format Y-m-d H:i:s.",
          "From date should not be greater than today":
            "From date should not be greater than today",
          "Enter the To date field": "Enter the To date field",
          "End date should not be lesser than from date":
            "End date should not be lesser than from date",
          "From date should not be greater than today":
            "From date should not be greater than today",
          "From date is greater than today": "From date is greater than today",
          "To date is greater than today": "To date is greater than today",
          "Enter the From date field": "Enter the From date field",
          "End date should not be lesser than from date":
            "End date should not be lesser than from date",

          ///////////////////// notification  /////////////////////

          Notification: "Notification",
          Create: "Create",
          "View More": "View More",

          //////////////////////////// RecentWithdrawal.js //////////////////////////////
          "No Withdrawal Requests": "No Withdrawal Requests",
          More: "More",

          //////////////////////////////////////// WithdrawlInputs ///////////////////////
          "Dont have enough balance to make a withdrawal Request":
            "Dont have enough balance to make a withdrawal Request",
          "Withdrawal turnover requirements":
            "Withdrawal turnover requirements",
          "Please complete the required turnover for withdrawal":
            "Please complete the required turnover for withdrawal",
          "Please Enter Amount": "Please Enter Amount",
          "Total Turnover Required": "Total Turnover Required",
          "Remaining Turnover Required": "Remaining Turnover Required",

          ////////////////////////////// Register.js ///////////////////////////

          "User could not be verified. Please contact Customer Support. Error: 10001":
            "User could not be verified. Please contact Customer Support. Error: 10001",
          "Account could not be created because verification failed. Please contact Customer Support. Error: 10002":
            "You have exceeded the maximum number of user licence you are permitted. Please contact our customer service.",

          ////////////////// E Wallet Management //////////////////

          ///////////////////// Deposit Record  /////////////////////

          "Deposit Record": "Deposit Record",
          "Select Date": "Select Date",
          Status: "Status",
          "DEPOSIT AMOUNT": "Deposit Amount",
          time: "Time",

          depositamt: "Deposit Amount",

          Approved: "Approved",
          Rejected: "Rejected",
          Pending: "Pending",
          "Waiting For Payment": "Waiting For Payment",
          Processing: "Processing",
          Failed: "Failed",

          "Withdraw Record": "Withdraw Record",

          "Add E-Wallet": "Add E-Wallet",

          logoutMsg: "Are you sure that you want to logout?",

          NoStay: "No, Stay",
          YesLeave: "Yes, Leave",

          ////////////////////////////////////// OtpPopUp   ///////////////////////////
          "Number is already registered!": "Number is already registered!",
          "Failed to send OTP, please try again.":
            "Failed to send OTP, please try again.",
          "Failed to send OTP, please try again.":
            "Failed to send OTP, please try again.",
          "Verified successfully": "Verified successfully",
          "Invalid OTP, please try again.": "Invalid OTP, please try again.",
          "Code sent again!": "Code sent again!",
          "OTP expired, please request a new code.":
            "OTP expired, please request a new code.",
          "Resend Code": "Resend Code",
          Confirm: "Confirm",
          "Get Code": "Get Code",
          "To verify your phone number please":
            "To verify your phone number please",
          "click on": "click on",
          "Didn't get the code?": "Didn't get the code?",
          "Please enter the verification code":
            "Please enter the verification code",
          "we sent to": "we sent to",
        },
      },
      vi: {
        translation: {
          defaultUserNameMessage:
            "Tối thiểu 8, tối đa 12 ký tự. Không được sử dụng ký tự đặc biệt hoặc khoảng trắng.",
          defaultPasswordMessage:
            "Tối thiểu 6, tối đa 16 ký tự và có thể bao gồm chữ cái, số và ký tự đặc biệt.",
          defaultFullNameMessage:
            "Tên đầy đủ không có ký tự đặc biệt. Ví dụ: Alden Reyes",
          defaultPhoneMessage: "Nhập số điện thoại gồm 10 chữ số",
          USER_DOES_NOT_EXIST: "Người dùng không tồn tại",
          LOGIN_LIMIT_EXCEEDED:
            "Đăng nhập tài khoản bị vô hiệu hóa. Vui lòng đợi vài phút và thử lại hoặc liên hệ với hỗ trợ khách hàng. Lỗi 10004.",
          ACCOUNT_INACTIVE:
            "Người chơi không được phép đăng nhập. Vui lòng liên hệ với hỗ trợ khách hàng. Lỗi 10003.",
          PASSWORD_INCORRECT: "Mật khẩu không chính xác",
          ERROR_500: "Lỗi máy chủ 500",
          DOES_NOT_MATCH: "Không khớp",
          USER_NAME_VALIDATION: "Xác thực tên người dùng",
          MINIMUM_NAME_VALIDATION: "Tên không đủ độ dài tối thiểu",
          MAXIMUM_NAME_VALIDATION: "Tên vượt quá độ dài tối đa",
          NAME_PATTERN_VALIDATION: "Tên không đúng định dạng",
          PASSWORD_VALIDATION:
            "Mật khẩu phải có độ dài từ 6 đến 16 ký tự và có thể bao gồm chữ cái, số và ký tự đặc biệt.",
          PHONE_NO_VALIDATION: "Xác thực số điện thoại",
          withdrawFeatureLock:
            "Tính năng rút tiền của bạn đã bị khóa, vui lòng liên hệ với bộ phận hỗ trợ khách hàng để được trợ giúp.",
          ADMIN_FORBIDDEN:
            "Không thể đăng nhập vào trò chơi. Vui lòng liên hệ hỗ trợ khách hàng.",
          pendingreqerror:
            "Bạn có một yêu cầu đang chờ xử lý. Bạn không thể tạo một yêu cầu mới trong khi có một yêu cầu đang chờ xử lý. Vui lòng thử lại sau.",
          gameCategories: "Thể loại trò chơi",
          helpCenter: "Trung tâm trợ giúp",
          depositAmount: "Số tiền nạp",
          Create: "asdf",

          "Registered E-Wallet": "Ví điện tử đã đăng ký",
          "Holder Name": "Tên chủ sở hữu",
          "Phone Number": "Số điện thoại",
          "Don't have an account?": "Chưa có tài khoản?",
          "Access your account": "Truy cập vào tài khoản của bạn",
          "Register your account": "Đăng ký tài khoản của bạn",
          "Your username": "Tên người dùng của bạn",
          "Your password": "Mật khẩu của bạn",
          "Forgot Password?": "Quên mật khẩu?",
          "By accessing you confirm the you are at least 18 years old and agree to the":
            "Việc truy cập xác nhận rằng bạn ít nhất 18 tuổi và đồng ý với",
          "Terms and service": "Điều khoản và dịch vụ",
          "Already have an account?": "Đã có tài khoản?",
          "Your full name": "Tên đầy đủ của bạn",
          "Your phone number": "Số điện thoại của bạn",
          "Phone Already Taken": "Số điện thoại đã được sử dụng",
          "Username Already Taken": "Tên người dùng đã được sử dụng",
          "Network Error": "Lỗi mạng",
          "Agent Not Found": "Không tìm thấy đại lý",
          "Something went wrong, please try again later.":
            "Đã xảy ra lỗi, vui lòng thử lại sau.",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "Tính năng nạp tiền của bạn đã bị khóa, vui lòng liên hệ hỗ trợ khách hàng để được trợ giúp.",
          "Internal Server Error. Please try again later.":
            "Lỗi máy chủ nội bộ. Vui lòng thử lại sau.",
          Bronze: "Đồng thiếc",

          "Welcome to our": "Chào mừng bạn đến với",
          BW9: "BW9",
          "Service Center": "Trung tâm dịch vụ",
          Hello: "Xin chào",
          "Live Chat": "Trò chuyện trực tuyến",
          "Leave us a contact number and our customer service representative will call you back shortly.":
            "Để lại số điện thoại liên lạc của bạn và đại diện dịch vụ khách hàng của chúng tôi sẽ gọi lại cho bạn trong thời gian ngắn.",
          Telegram: "Telegram",
          "Available 24/7": "Có sẵn 24/7",
          "Select Category": "Chọn danh mục",
          "Select bank code": "Chọn mã ngân hàng",
          "Enter account number": "Nhập số tài khoản",
          Username: "Tên người dùng",
          "No Notification": "Không có thông báo",
          "There are currently no new system messages.":
            "Hiện tại không có tin nhắn hệ thống mới nào.",
          "Please come back later!": "Vui lòng trở lại sau!",
          "Select Date": "Chọn ngày",
          Create: "Tạo mới",
          Update: "Cập nhật.",
          Cancel: "Hủy bỏ",
          "Minimum 3 digits required": "Yêu cầu ít nhất 3 chữ số",
          "Enter Only Numbers": "Chỉ nhập số",
          "The System is Under Maintenance. Please try after some time or contact our":
            "Hệ thống đang được bảo trì. Vui lòng thử lại sau hoặc liên hệ với",
          "customer support": "hỗ trợ khách hàng",
          "E-Wallet": "Ví điện tử",
          Select: "Chọn",
          ///////////////////// Sidebar  /////////////////////

          BONUS: "Thưởng",
          PROMOTIONS: "KHUYẾN MÃI",
          POPULAR: "Phổ biến",
          CASINO: "Sòng bạc",
          SLOTS: "Máy đánh bạc",
          FISHING: "Câu cá",
          SPORT: "Thể thao",
          COCKFIGHT: "Đấu gà",
          Deposit: "Gửi tiền",
          Withdraw: "Rút tiền",
          "My Account": "Tài khoản của tôi",
          Support: "Hỗ trợ",
          "Follow us": "Theo dõi chúng tôi",

          ///////////////////// Header  /////////////////////

          headerText:
            "Để đảm bảo an toàn cho tài khoản và quỹ của bạn, vui lòng không tiết lộ tài khoản và mật khẩu cho người khác để tránh mất mát.",
          Logout: "Đăng xuất",

          Logout: "Đăng xuất",
          Login: "Đăng nhập",
          signUp: "Đăng ký",

          ///////////////////// Footer  /////////////////////

          OfficialLicense: "Giấy phép chính thức",
          Products: "Sản phẩm",
          "Live Casino": "Sòng bạc trực tiếp",
          Slots: "Máy đánh bạc",
          Fishing: "Câu cá",
          Sports: "Thể thao",
          Cockfighting: "Đá gà",
          Links: "Liên kết",
          "About Us": "Về chúng tôi",
          "Privacy Policy": "Chính sách bảo mật",
          "Responsible Gaming": "Trò chơi có trách nhiệm",
          "Terms of use": "Điều khoản sử dụng",
          PaymentMethods: "Phương thức thanh toán",
          rights: "BW9 bảo lưu mọi quyền",
          "Official License": "Giấy phép chính thức",

          ///////////////////// Age Verification popup  /////////////////////

          ageVerify: "Xác minh tuổi",
          ageMessage:
            " Vui lòng xác minh bạn đã đủ 21 tuổi hoặc lớn hơn để vào trang web này",
          iAgree: "Tôi đồng ý với BW9",
          TnC: "Điều khoản và Điều kiện",
          age: "Tôi đã trên 21 tuổi",
          Exit: "Thoát",

          ///////////////////// Page Headers  /////////////////////

          "POPULAR GAMES": "Trò chơi phổ biến",
          "BEST SLOTS": "Máy đánh bạc tốt nhất",
          "New Games-x": "Trò chơi mới-x",
          "Big Jackpot-x": "Tiền thưởng lớn-x",
          "BEST FISHING GAMES": "Trò chơi câu cá tốt nhất",
          "Trending-y": "Xu hướng-y",
          CASINO: "sòng bạc",
          SLOTS: "máy đánh bạc",
          FISHING: "câu cá",
          SPORT: "thể thao",
          CockFight: "đấu gà",
          Games: "Trò chơi",

          ///////////////////// Deposit Page  /////////////////////

          "Payment Method": "Phương thức thanh toán",
          Deposit: "Nạp tiền",
          "Payment Channel": "Kênh thanh toán",
          Amount: "Số tiền",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "Tính năng gửi tiền của bạn đã bị khóa, vui lòng liên hệ bộ phận hỗ trợ khách hàng để được trợ giúp.",

          ///////////////////// Withdraw Page  /////////////////////

          "Payment Accounts": "Tài khoản thanh toán",
          "Add Payment Method": "Kênh thanh toán",
          Balance: "Số dư",
          "Withdraw Points": "Rút điểm",
          Min: "Tối thiểu",
          Max: "Tối đa",

          ///////////////////// Accounts Page  /////////////////////

          Username: "Tài khoản thanh toán",
          "Personal Center": "Trung tâm cá nhân",
          Withdrawal: "Rút tiền",
          "Betting Record": "Lịch sử cược",
          Notifications: "Thông báo",
          "Deposit Record": "Lịch sử gửi tiền",
          "Withdrawal Record": "Lịch sử rút tiền",
          "E-wallet Management": "Quản lý ví điện tử",
          Logout: "Đăng xuất",

          ///////////////////// Betting Record  /////////////////////

          "Betting Record": "Lịch sử cược",
          From: "Từ",
          To: "Đến",
          Providers: "Nhà cung cấp",
          Search: "Tìm kiếm",
          Reset: "Đặt lại",

          ///////////////////// Betting Record Table  /////////////////////

          "Reference ID": "MÃ THAM CHIẾU",
          "Game Name": "TÊN TRÒ CHƠI",
          "Bet Time": "THỜI GIAN ĐẶT CƯỢC",
          "Bet Amount": "SỐ TIỀN CƯỢC",
          "Valid Bet": "CƯỢC HỢP LỆ",
          "Profit/Loss": "LỢI NHUẬN/THUA LỖ",

          ///////////////////// notification  /////////////////////

          Create: "Tạo",
          "View More": "Xem thêm",

          Notification: "Thông báo",

          depositamt: "Số tiền nạp",

          Approved: "Đã được phê duyệt",
          rejected: "Bị từ chối",
          Pending: "Đang chờ xử lý",
          "Waiting For Payment": "Đang chờ thanh toán",
          Processing: "Đang xử lý",
          failed: "Thất bại",

          status: "Trạng thái",

          time: "Thời gian",

          "Withdraw Record": "Ghi chép Rút tiền",
          "Add E-Wallet": "Thêm Ví Điện Tử",

          logoutMsg: "Bạn chắc chắn muốn đăng xuất chứ?",
          NoStay: "Không, Ở lại",
          YesLeave: "Có, Rời đi",

          ////////////////////////////////////// OtpPopUp   ///////////////////////////
          "Number is already registered!": "Số đã được đăng ký!",
          "Failed to send OTP, please try again.":
            "Gửi OTP thất bại, vui lòng thử lại.",
          "Verified successfully": "Xác minh thành công",
          "Invalid OTP, please try again.":
            "OTP không hợp lệ, vui lòng thử lại.",
          "Code sent again!": "Mã đã được gửi lại!",
          "OTP expired, please request a new code.":
            "OTP đã hết hạn, vui lòng yêu cầu mã mới.",
          "Resend Code": "Gửi lại mã",
          Confirm: "Xác nhận",
          "Get Code": "Nhận mã",
          "To verify your phone number please":
            "Để xác minh số điện thoại của bạn, vui lòng",
          "click on": "nhấn vào",
          "Didn't get the code?": "Không nhận được mã?",
          "Please enter the verification code": "Vui lòng nhập mã xác minh",
          "we sent to": "chúng tôi đã gửi đến",

          ////////////////////// AddPaymentMethod //////////////////////
          "You have reached the maximum limit of 3 wallets.":
            "Bạn đã đạt đến giới hạn tối đa là 3 ví.",

          //////////////////////  Betting Record ///////////////////
          "The from date field must match the format Y-m-d H:i:s.":
            "Trường từ ngày phải khớp với định dạng Y-m-d H:i:s.",
          "The to date field must match the format Y-m-d H:i:s.":
            "Trường đến ngày phải khớp với định dạng Y-m-d H:i:s.",
          "From date should not be greater than today":
            "Từ ngày không nên lớn hơn ngày hôm nay",
          "Enter the To date field": "Nhập trường đến ngày",
          "End date should not be lesser than from date":
            "Ngày kết thúc không nên nhỏ hơn ngày bắt đầu",
          "From date is greater than today": "Từ ngày lớn hơn ngày hôm nay",
          "To date is greater than today": "Đến ngày lớn hơn ngày hôm nay",
          "Enter the From date field": "Nhập trường từ ngày",

          ////////////////////////////// Register.js ///////////////////////////

          "User could not be verified. Please contact Customer Support. Error: 10001":
            "Không thể xác minh người dùng. Vui lòng liên hệ bộ phận Hỗ trợ Khách hàng. Lỗi: 10001",
          "Account could not be created because verification failed. Please contact Customer Support. Error: 10002":
            "Bạn đã vượt quá số lượng giấy phép người dùng tối đa cho phép. Vui lòng liên hệ với dịch vụ khách hàng của chúng tôi.",

          //////////////////////////// RecentWithdrawal.js //////////////////////////////
          "No Withdrawal Requests": "Không có yêu cầu rút tiền",
          More: "Thêm",

          //////////////////////////////////////// WithdrawlInputs ///////////////////////
          "Don't have enough balance to make a withdrawal Request":
            "Không đủ số dư để yêu cầu rút tiền",
          "Withdrawal turnover requirements": "Yêu cầu doanh thu rút tiền",
          "Please complete the required turnover for withdrawal":
            "Vui lòng hoàn thành doanh thu yêu cầu để rút tiền",
          "Please Enter Amount": "Vui lòng nhập số tiền",
          "Total Turnover Required": "Tổng doanh thu yêu cầu",
          "Remaining Turnover Required": "Doanh thu còn lại yêu cầu",
        },
      },
      ph: {
        translation: {
          defaultUserNameMessage:
            "Minimum na 8, Maksimum na 12 na mga Titik. Walang mga Espesyal na Titik o Puwang na Pinapayagan.",
          defaultPasswordMessage:
            "Minimum na 6, Maximum na 16 mga Character at maaaring maglaman ng mga titik, numero, at mga espesyal na karakter.",
          defaultFullNameMessage:
            "Buong pangalan na walang anumang special characters. Halimbawa: Alden Reyes",
          defaultPhoneMessage: "Ilagay ang 10 digit na numero ng telepono",
          USER_DOES_NOT_EXIST: "Ang user ay hindi umiiral",
          LOGIN_LIMIT_EXCEEDED:
            "Hindi na ma-access ang account. Pakiusap maghintay ng ilang minuto at subukang muli o makipag-ugnayan sa customer support. Error 10004.",
          ACCOUNT_INACTIVE:
            "Hindi pinapayagan ang player na mag-login. Pakiusap makipag-ugnayan sa customer support. Error 10003.",
          PASSWORD_INCORRECT: "Maling password",
          ERROR_500: "Error 500",
          DOES_NOT_MATCH: "Hindi tugma",
          USER_NAME_VALIDATION: "Pag-validate ng pangalan ng user",
          MINIMUM_NAME_VALIDATION: "Hindi sapat ang haba ng pangalan",
          MAXIMUM_NAME_VALIDATION: "Labis na haba ng pangalan",
          NAME_PATTERN_VALIDATION: "Hindi wastong format ng pangalan",
          PASSWORD_VALIDATION:
            "Ang password ay dapat na may haba na 6-16 na mga karakter at maaaring maglaman ng mga titik, numero, at espesyal na mga karakter.",
          PHONE_NO_VALIDATION: "Pag-validate ng numero ng telepono",
          withdrawFeatureLock:
            "Ang feature ng pag-withdraw mo ay nakasara, mangyaring makipag-ugnayan sa customer support para sa tulong.",
          ADMIN_FORBIDDEN:
            "Hindi makapag-log in sa laro. Mangyaring makipag-ugnayan sa customer support.",
          pendingreqerror:
            "Mayroon kang nakabinbing kahilingan. Hindi ka makakagawa ng bagong kahilingan habang may nakabinbing kahilingan. Mangyaring subukan muli mamaya.",
          gameCategories: "Mga Kategorya ng Laro",
          helpCenter: "Sentro ng Tulong",
          depositAmount: "Halaga ng Deposito",

          "Registered E-Wallet": "Nakarehistrong E-Wallet",
          "Holder Name": "Pangalan ng May-ari",
          "Phone Number": "Numero ng Telepono",
          "Phone Already Taken": "Ang numero ng telepono ay may-ari na",
          "Username Already Taken": "Ang username ay may-ari na",
          "Network Error": "Error sa network",
          "Agent Not Found": "Agent hindi natagpuan",
          "Don't have an account?": "Wala ka pang account?",
          "Access your account": "I-access ang iyong account",
          "Register your account": "Irehistro ang iyong account",
          "Your username": "Ang iyong User",
          "Your password": "Ang iyong password",
          "Forgot Password?": "Nakalimutan ang Password?",
          "By accessing you confirm the you are at least 18 years old and agree to the":
            "Sa pag-access, kinukumpirma mo na ikaw ay hindi bababa sa 18 taong gulang at sumasang-ayon sa",
          "Terms and service": "Mga Tuntunin at Serbisyo",
          "Already have an account?": "May account ka na?",
          "Your full name": "Ang iyong buong pangalan",
          "Your phone number": "Ang iyong numero ng telepono",
          "Something went wrong, please try again later.":
            "May nangyaring mali, pakisubukan ulit mamaya.",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "Ang iyong feature ng deposito ay na-lock, mangyaring makipag-ugnayan sa customer support para sa tulong.",
          "Internal Server Error. Please try again later.":
            "Internal na Pagkakamali ng Server. Pakisubukan ulit mamaya.",
          Bronze: "Bronse",

          "Welcome to our": "Maligayang pagdating sa aming",
          BW9: "",
          "Service Center": "Sentro ng Serbisyo",
          Hello: "Kamusta",
          "Live Chat": "Chat sa Diretso",
          "Leave us a contact number and our customer service representative will call you back shortly.":
            "Iwanan mo sa amin ang iyong numero ng kontak at tatawagan ka ng aming kinatawan ng serbisyo sa kustomer sa lalong madaling panahon.",
          Telegram: "Telegrama",
          "Available 24/7": "Magagamit 24/7",
          "Select Category": "Pumili ng Kategorya",
          Create: "Lumikha",
          Update: "I-update",
          Cancel: "Kanselahin",
          bankCode: "Pumili ng Kodigo ng Bangko",
          "Enter account number": "Ipasok ang numero ng account",
          "Username:": "Pangalan ng User:",
          "No Notification": "Walang Abiso",
          "There are currently no new system messages.":
            "Sa ngayon wala pang mga bagong mensahe ng sistema.",
          "Please come back later!": "Mangyaring bumalik mamaya!",
          "Select Date": "Pumili ng Petsa",
          Select: "Pumili",
          ///////////////////// Sidebar  /////////////////////

          BONUS: "PREMYO",
          PROMOTIONS: "PROMOSYON",
          POPULAR: "SIKAT",
          CASINO: "CASINO",
          SLOTS: "SLOTS",
          FISHING: "PANGISDA",
          SPORT: "PALARO",
          COCKFIGHT: "SABONG",
          Deposit: "Magdeposit",
          Withdraw: "Magwithdraw",
          "My Account": "Aking Account",
          Support: "Tulong",
          "Follow us": "Sundan kami",

          ///////////////////// Header  /////////////////////
          headerText:
            "Para sa seguridad ng iyong account at pondo, mangyaring huwag ibunyag ang iyong account at password sa iba upang maiwasan ang mga pagkawala.",
          Logout: "Mag-logout",
          Login: "Mag-login",
          signUp: "Mag-sign Up",

          ///////////////////// Footer  /////////////////////
          OfficialLicense: "Opisyal na Lisensya",
          Products: "Mga Produkto",
          LiveCasino: "Live na Kasino",
          "Live Casino": "Live na Kasino",
          Slots: "Mga Slots",
          Fishing: "Pangisda",
          Sports: "Palaro",
          Cockfighting: "Sabong",
          Links: "Mga Link",
          "About Us": "Tungkol Sa Amin",
          "Privacy Policy": "Patakaran sa Pagkapribado",
          "Responsible Gaming": "Responsableng Paglalaro",
          "Terms of use": "Mga Tuntunin ng Paggamit",
          "About Us": "Tungkol Sa Amin",
          "Privacy Policy": "Patakaran sa Pagkapribado",
          "Responsible Gaming": "Responsableng Pagsusugal",
          Termsofuse: "Mga Tuntunin ng Paggamit",
          PaymentMethods: "Mga Paraan ng Pagbabayad",
          rights: "BW9 lahat ng karapatan ay nakalaan",
          "Official License": "Opisyal na Lisensya",

          ///////////////////// Age Verification popup  /////////////////////
          ageVerify: "Pagsusuri ng Edad",
          ageMessage:
            " Mangyaring patunayan na ikaw ay 21 taong gulang o higit pa upang pumasok sa site na ito",
          iAgree: "Sumasang-ayon ako sa BW9",
          TnC: "Mga Tuntunin at Kondisyon",
          age: "Ako ay higit sa 21 taong gulang",
          Exit: "Lumabas",

          ///////////////////// Page Headers  /////////////////////

          "POPULAR GAMES": "MGA SIKAT NA LARO",
          "BEST SLOTS": "PINAKAMAHUSAY NA MGA SLOTS",
          "New Games-x": "Bagong Mga Laro-x",
          "Big Jackpot-x": "Malaking Jackpot-x",
          "BEST FISHING GAMES": "PINAKAMAHUSAY NA MGA LARO SA PANGISDA",
          "Trending-y": "Trending-y",
          CASINO: "CASINO",
          SLOTS: "SLOTS",
          FISHING: "PANGISDA",
          SPORT: "PALARO",
          CockFight: "Sabong",
          Games: "Mga Laro",

          ///////////////////// Deposit Page  /////////////////////

          "Payment Method": "Paraan ng Pagbabayad",
          Deposit: "Magdeposit",
          "Payment Channel": "Kanal ng Pagbabayad",
          Amount: "halaga",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "Ang iyong tampok sa deposito ay na-lock, mangyaring makipag-ugnayan sa suporta ng customer para sa tulong.",

          ///////////////////// Withdraw Page  /////////////////////

          "Payment Accounts": "Mga Account ng Pagbabayad",
          "Add Payment Method": "Magdagdag ng Paraang Pagbabayad",
          Balance: "Balanse",
          "Withdraw Points": "Magwithdraw ng Points",
          Min: "Min",
          Max: "Max",

          ///////////////////// Accounts Page  /////////////////////

          Username: "Username",
          "Personal Center": "Personal na Sentro",
          Withdrawal: "Pag-withdraw",
          "Betting Record": "Rekord ng Pagsusugal",
          Notifications: "Mga Abiso",
          "Deposit Record": "Rekord ng Pagdeposito",
          "Withdrawal Record": "Rekord ng Pagwithdraw",
          "E-wallet Management": "Pamamahala ng E-Wallet",
          Logout: "Mag-logout",

          ///////////////////// Betting Record  /////////////////////

          "Betting Record": "Rekord ng Pagsusugal",
          From: "Mula",
          To: "Sa",
          Providers: "Mga Tagapagbigay",
          Search: "Maghanap",
          Reset: "I-reset",

          ///////////////////// Betting Record Table  /////////////////////

          "Reference ID": "REFERENCE ID",
          "Game Name": "PANGALAN NG LARO",
          "Bet Time": "ORAS NG PAGSUSUGAL",
          "Bet Amount": "HALAGA NG PAGSUSUGAL",
          "Valid Bet": "VALID NA PAGSUSUGAL",
          "Profit/Loss": "KITA/KAWALAN",

          ///////////////////// notification  /////////////////////

          Notification: "Abiso",
          Create: "Gumawa",
          "View More": "Tingnan Pa",

          ///////////////////// Deposit Record  /////////////////////

          "Deposit Record": "Rekord ng Pagdeposito",
          "Select Date": "Pumili ng Petsa",
          status: "Katayuan",
          Status: "Katayuan",
          "DEPOSIT AMOUNT": "Halaga ng Pagdeposito",
          time: "Oras",

          depositamt: "Halaga ng Pagdeposito",

          Approved: "Aprobado",
          approved: "Aprobado",
          Rejected: "Tinanggihan",
          rejected: "Tinanggihan",
          Pending: "Nakabinbin",
          pending: "Nakabinbin",
          "Waiting For Payment": "Naghihintay Para sa Pagbabayad",
          Processing: "Napoproseso",
          processing: "Napoproseso",
          Failed: "Nabigo",
          failed: "Nabigo",

          "Withdraw Record": "Rekord ng Pagwithdraw",

          "Add E-Wallet": "Magdagdag ng E-Wallet",

          logoutMsg: "Sigurado ka bang nais mong mag-logout?",

          NoStay: "Hindi, Manatili",
          YesLeave: "Oo, Lumisan",

          "Number is already registered!": "Ang numero ay nakarehistro na!",
          "Failed to send OTP, please try again.":
            "Nabigo ang pagpapadala ng OTP, mangyaring subukang muli.",
          "Verified successfully": "Matagumpay na na-verify",
          "Invalid OTP, please try again.":
            "Di-wastong OTP, mangyaring subukang muli.",
          "Code sent again!": "Muling ipinadala ang code!",
          "OTP expired, please request a new code.":
            "Nag-expire na ang OTP, mangyaring humiling ng bagong code.",
          "Resend Code": "Ipadala muli ang code",
          Confirm: "Kumpirmahin",
          "Get Code": "Kunin ang code",
          "To verify your phone number please":
            "Upang ma-verify ang iyong numero ng telepono, mangyaring",
          "click on": "i-click ang",
          "Didn't get the code?": "Hindi natanggap ang code?",
          "Please enter the verification code":
            "Mangyaring ipasok ang verification code",
          "we sent to": "na ipinadala namin sa",

          ////////////////////// AddPaymentMethod //////////////////////
          "You have reached the maximum limit of 3 wallets.":
            "Naabot mo na ang pinakamataas na limitasyon ng 3 pitaka.",

          ////////////////////////////// Betting Record //////////////////////////////

          "The from date field must match the format Y-m-d H:i:s.":
            "Ang field mula sa petsa ay dapat tumugma sa format na Y-m-d H:i:s.",
          "The to date field must match the format Y-m-d H:i:s.":
            "Ang field sa petsa ay dapat tumugma sa format na Y-m-d H:i:s.",
          "From date should not be greater than today":
            "Ang petsa mula ay hindi dapat mas malaki kaysa sa ngayon",
          "Enter the To date field": "Ilagay ang field sa petsa",
          "End date should not be lesser than from date":
            "Ang petsa ng pagtatapos ay hindi dapat mas mababa kaysa sa petsa ng simula",
          "From date is greater than today":
            "Ang petsa mula ay mas malaki kaysa sa ngayon",
          "To date is greater than today":
            "Ang petsa ay mas malaki kaysa sa ngayon",
          "Enter the From date field": "Ilagay ang field mula sa petsa",

          ////////////////////////////// Register //////////////////////////////////
          "User could not be verified. Please contact Customer Support. Error: 10001":
            "Hindi ma-verify ang gumagamit. Mangyaring makipag-ugnayan sa Suporta ng Customer. Error: 10001",
          "Account could not be created because verification failed. Please contact Customer Support. Error: 10002":
            "Lumagpas ka na sa maximum na bilang ng user license na pinapayagan. Mangyaring makipag-ugnayan sa aming customer service.",

          //////////////////////  RecentWithDrawal.js //////////////////////////////////////
          "No Withdrawal Requests": "Walang Mga Kahilingan sa Pag-withdraw",
          More: "Higit Pa",

          //////////////////////////////////////// WithdrawlInputs ///////////////////////
          "Don't have enough balance to make a withdrawal Request":
            "Walang sapat na balanse upang makagawa ng kahilingan sa pag-withdraw",
          "Withdrawal turnover requirements":
            "Mga kinakailangan sa turnover para sa pag-withdraw",
          "Please complete the required turnover for withdrawal":
            "Mangyaring kumpletuhin ang kinakailangang turnover para sa pag-withdraw",
          "Please Enter Amount": "Mangyaring ipasok ang halaga",
          "Total Turnover Required": "Kabuuang kinakailangang turnover",
          "Remaining Turnover Required": "Natitirang kinakailangang turnover",
        },
      },
      bn: {
        translation: {
          defaultUserNameMessage:
            "সর্বনিম্ন ৮, সর্বাধিক ১২ অক্ষর। কোনো বিশেষ অক্ষর বা স্পেস ব্যবহার করা যাবে না।",
          defaultPasswordMessage:
            "সর্বনিম্ন ৬, সর্বাধিক ১৬ অক্ষর এবং অক্ষর, সংখ্যা ও বিশেষ অক্ষর অন্তর্ভুক্ত থাকতে পারে।",
          defaultFullNameMessage:
            "বিশেষ অক্ষর ছাড়া পূর্ণ নাম। উদাহরণ: Alden Reyes",
          defaultPhoneMessage: "১০ ডিজিটের ফোন নম্বর প্রবেশ করুন",
          USER_DOES_NOT_EXIST:
            "ব্যবহারকারীর নাম বা পাসওয়ার্ড ভুল। অনুগ্রহ করে আবার চেষ্টা করুন বা সহায়তার জন্য গ্রাহক সেবায় যোগাযোগ করুন।",
          LOGIN_LIMIT_EXCEEDED:
            "অ্যাকাউন্ট লগইন নিষ্ক্রিয়। কিছু সময় পরে আবার চেষ্টা করুন বা গ্রাহক সেবায় যোগাযোগ করুন। ত্রুটি ১০০০৪",
          ACCOUNT_INACTIVE:
            "খেলোয়াড় লগইন করতে পারছে না। সহায়তার জন্য গ্রাহক সেবায় যোগাযোগ করুন। ত্রুটি ১০০০৩",
          PASSWORD_INCORRECT:
            "ব্যবহারকারীর নাম বা পাসওয়ার্ড ভুল। অনুগ্রহ করে আবার চেষ্টা করুন বা সহায়তার জন্য গ্রাহক সেবায় যোগাযোগ করুন।",
          ERROR_500:
            "সিস্টেমের সাথে সংযোগ করার সময় একটি ত্রুটি ঘটেছে। অনুগ্রহ করে গ্রাহক সেবায় যোগাযোগ করুন।",
          DOES_NOT_MATCH:
            "ব্যবহারকারীর নাম বা পাসওয়ার্ড মিলছে না। অনুগ্রহ করে আবার পরীক্ষা করুন।",
          USER_NAME_VALIDATION:
            "ব্যবহারকারীর নাম ৮-১২ অক্ষরের মধ্যে হতে হবে এবং শুধুমাত্র ছোট হাতের অক্ষর ও সংখ্যা থাকতে হবে।",
          MINIMUM_NAME_VALIDATION:
            "নাম সর্বনিম্ন ৫ অক্ষরের হতে হবে এবং অন্তত ২টি শব্দ থাকতে হবে।",
          MAXIMUM_NAME_VALIDATION:
            "নাম ২৫৫ অক্ষরের বেশি হতে পারবে না এবং অন্তত ২টি শব্দ থাকতে হবে।",
          NAME_PATTERN_VALIDATION:
            "নামে সংখ্যা বা বিশেষ অক্ষর থাকতে পারবে না। উদাহরণ: Alden Reyes",
          PASSWORD_VALIDATION:
            "পাসওয়ার্ড ৬-১৬ অক্ষরের হতে হবে এবং এতে অক্ষর, সংখ্যা ও বিশেষ অক্ষর অন্তর্ভুক্ত থাকতে পারে।",
          PHONE_NO_VALIDATION: "ফোন নম্বর ১০ ডিজিটের হতে হবে।",
          withdrawFeatureLock:
            "আপনার উত্তোলন ফিচার লক করা হয়েছে, অনুগ্রহ করে সহায়তার জন্য গ্রাহক সেবায় যোগাযোগ করুন।",
          ADMIN_FORBIDDEN:
            "গেমে লগইন করতে অক্ষম। অনুগ্রহ করে গ্রাহক সেবায় যোগাযোগ করুন।",
          pendingreqerror:
            "আপনার একটি মুলতুবি অনুরোধ রয়েছে। মুলতুবি অনুরোধ থাকাকালীন নতুন অনুরোধ তৈরি করা যাবে না। অনুগ্রহ করে পরে আবার অনুরোধ করুন।",
          "Something went wrong, please try again later.":
            "কিছু ভুল হয়েছে, অনুগ্রহ করে পরে আবার চেষ্টা করুন।",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "আপনার জমা ফিচার লক করা হয়েছে, অনুগ্রহ করে সহায়তার জন্য গ্রাহক সেবায় যোগাযোগ করুন।",
          "Internal Server Error. Please try again later.":
            "অভ্যন্তরীণ সার্ভার ত্রুটি। অনুগ্রহ করে পরে আবার চেষ্টা করুন।",
          ///////////////////// Sidebar  /////////////////////
          BONUS: "বোনাস",
          PROMOTIONS: "প্রচারণা",
          POPULAR: "জনপ্রিয়",
          CASINO: "ক্যাসিনো",
          SLOTS: "স্লটস",
          FISHING: "মাছ ধরা",
          SPORT: "খেলাধুলা",
          Sabong: "সাবং",
          COCKFIGHT: "মোরগ লড়াই",
          Deposit: "জমা",
          Withdraw: "উত্তোলন",
          "My Account": "আমার অ্যাকাউন্ট",
          Support: "সহায়তা",
          "Follow us": "আমাদের অনুসরণ করুন",
          ///////////////////// Header  /////////////////////
          headerText:
            "আপনার অ্যাকাউন্ট এবং তহবিলের নিরাপত্তার জন্য, দয়া করে আপনার অ্যাকাউন্ট এবং পাসওয়ার্ড অন্যকে প্রকাশ করবেন না যাতে ক্ষতি এড়ানো যায়।",
          Logout: "লগআউট",
          Login: "লগইন",
          signUp: "সাইন আপ",
          ////////////////////// AddPaymentMethod //////////////////////
          "You have reached the maximum limit of 3 wallets.":
            "আপনি সর্বাধিক ৩টি ওয়ালেট সীমায় পৌঁছেছেন।",
          ///////////////////// Footer  /////////////////////
          OfficialLicense: "সরকারি লাইসেন্স",
          Products: "প্রোডাক্ট",
          "Live Casino": "লাইভ ক্যাসিনো",
          Slots: "স্লটস",
          Fishing: "মাছ ধরা",
          Sports: "খেলাধুলা",
          Cockfighting: "মোরগ লড়াই",
          Links: "লিঙ্ক",
          "About Us": "আমাদের সম্পর্কে",
          "Privacy Policy": "গোপনীয়তা নীতি",
          "Responsible Gaming": "দায়িত্বশীল গেমিং",
          "Terms and Conditions": "শর্তাদি",
          Termsofuse: "ব্যবহারের শর্তাবলী",
          PaymentMethods: "পেমেন্ট পদ্ধতি",
          rights: "BW9 সমস্ত অধিকার সংরক্ষিত",
          gameCategories: "গেম বিভাগ",
          helpCenter: "সহায়তা কেন্দ্র",
          depositAmount: "জমার পরিমাণ",
          ///////////////////// Age Verification popup  /////////////////////
          ageVerify: "বয়স যাচাই",
          ageMessage:
            "এই সাইটে প্রবেশ করতে ২১ বছর বা তার বেশি বয়সের প্রমাণ দিন",
          iAgree: "আমি BW9 এর সাথে একমত",
          TnC: "শর্তাবলী",
          age: "আমি ২১ বছরের বেশি বয়সী",
          Exit: "প্রস্থান",
          ///////////////////// Page Headers  /////////////////////
          "POPULAR GAMES": "জনপ্রিয় গেমস",
          "BEST SLOTS": "সেরা স্লটস",
          "New Games-x": "নতুন গেমস-x",
          "Big Jackpot-x": "বড় জ্যাকপট-x",
          "BEST FISHING GAMES": "সেরা ফিশিং গেমস",
          "Trending-y": "ট্রেন্ডিং-y",
          CASINO: "ক্যাসিনো",
          SLOTS: "স্লটস",
          FISHING: "মাছ ধরা",
          SPORT: "খেলাধুলা",
          CockFight: "মোরগ লড়াই",
          Games: "গেমস",
          ///////////////////// Deposit Page  /////////////////////
          "Payment Method": "পেমেন্ট পদ্ধতি",
          Deposit: "জমা",
          "Payment Channel": "পেমেন্ট চ্যানেল",
          "Your deposit feature has been locked, please contact customer support for assistance.":
            "আপনার জমা ফিচার লক করা হয়েছে, অনুগ্রহ করে সহায়তার জন্য গ্রাহক সেবায় যোগাযোগ করুন।",
          ///////////////////// Withdraw Page  /////////////////////
          "Payment Accounts": "পেমেন্ট অ্যাকাউন্টস",
          "Recent Withdrawal": "সাম্প্রতিক উত্তোলন",
          "Add Payment Method": "পেমেন্ট পদ্ধতি যোগ করুন",
          Balance: "ব্যালেন্স",
          "Withdraw Points": "উত্তোলন পয়েন্ট",
          Min: "সর্বনিম্ন",
          Max: "সর্বাধিক",
          "No wallet selected. Please add a wallet to continue.":
            "কোনো ওয়ালেট নির্বাচন করা হয়নি। চালিয়ে যেতে একটি ওয়ালেট যোগ করুন।",
          ///////////////////// Accounts Page  /////////////////////
          Username: "ব্যবহারকারীর নাম",
          Username: "ব্যবহারকারীর নাম",
          "Personal Center": "ব্যক্তিগত কেন্দ্র",
          Withdrawal: "উত্তোলন",
          "Betting Record": "বেটিং রেকর্ড",
          Notifications: "বিজ্ঞপ্তি",
          "Deposit Record": "জমার রেকর্ড",
          "Withdrawal Record": "উত্তোলনের রেকর্ড",
          "E-wallet Management": "ই-ওয়ালেট ব্যবস্থাপনা",
          Logout: "লগআউট",
          ///////////////////// Betting Record  /////////////////////
          "Betting Record": "বেটিং রেকর্ড",
          From: "থেকে",
          To: "পর্যন্ত",
          Providers: "প্রদানকারী",
          Search: "অনুসন্ধান",
          Reset: "রিসেট",
          ///////////////////// Betting Record Table  /////////////////////
          "REFERENCE ID": "রেফারেন্স আইডি",
          "GAME NAME": "গেমের নাম",
          "BET TIME": "বেট সময়",
          "BET AMOUNT": "বেট পরিমাণ",
          "VALID BET": "বৈধ বেট",
          "PROFIT/LOSS": "লাভ/ক্ষতি",
          Amount: "পরিমাণ",
          //////////////////////  Betting Record ///////////////////
          "The from date field must match the format Y-m-d H:i:s.":
            "থেকে তারিখ ক্ষেত্রটি Y-m-d H:i:s ফরম্যাটের সাথে মিলতে হবে।",
          "The to date field must match the format Y-m-d H:i:s.":
            "পর্যন্ত তারিখ ক্ষেত্রটি Y-m-d H:i:s ফরম্যাটের সাথে মিলতে হবে।",
          "From date should not be greater than today":
            "থেকে তারিখ আজকের চেয়ে বেশি হওয়া উচিত নয়",
          "Enter the To date field": "পর্যন্ত তারিখ ক্ষেত্রটি প্রবেশ করুন",
          "End date should not be lesser than from date":
            "শেষ তারিখ থেকে তারিখের চেয়ে কম হওয়া উচিত নয়",
          "From date should not be greater than today":
            "থেকে তারিখ আজকের চেয়ে বেশি হওয়া উচিত নয়",
          "From date is greater than today": "থেকে তারিখ আজকের চেয়ে বেশি",
          "To date is greater than today": "পর্যন্ত তারিখ আজকের চেয়ে বেশি",
          "Enter the From date field": "থেকে তারিখ ক্ষেত্রটি প্রবেশ করুন",
          "End date should not be lesser than from date":
            "শেষ তারিখ থেকে তারিখের চেয়ে কম হওয়া উচিত নয়",
          ///////////////////// notification  /////////////////////
          Notification: "বিজ্ঞপ্তি",
          Create: "তৈরি করুন",
          "View More": "আরও দেখুন",
          //////////////////////////// RecentWithdrawal.js //////////////////////////////
          "No Withdrawal Requests": "কোনো উত্তোলনের অনুরোধ নেই",
          More: "আরও",
          //////////////////////////////////////// WithdrawlInputs ///////////////////////
          "Dont have enough balance to make a withdrawal Request":
            "উত্তোলনের অনুরোধ করার জন্য যথেষ্ট ব্যালেন্স নেই",
          "Withdrawal turnover requirements": "উত্তোলন টার্নওভার প্রয়োজনীয়তা",
          "Please complete the required turnover for withdrawal":
            "উত্তোলনের জন্য প্রয়োজনীয় টার্নওভার সম্পূর্ণ করুন",
          "Please Enter Amount": "পরিমাণ প্রবেশ করুন",
          "Total Turnover Required": "মোট টার্নওভার প্রয়োজন",
          "Remaining Turnover Required": "অবশিষ্ট টার্নওভার প্রয়োজন",
          ////////////////////////////// Register.js ///////////////////////////
          "User could not be verified. Please contact Customer Support. Error: 10001":
            "ব্যবহারকারী যাচাই করা যায়নি। অনুগ্রহ করে গ্রাহক সেবায় যোগাযোগ করুন। ত্রুটি: ১০০০১",
          "Account could not be created because verification failed. Please contact Customer Support. Error: 10002":
            "আপনি অনুমোদিত সর্বাধিক ব্যবহারকারী লাইসেন্স সংখ্যা অতিক্রম করেছেন। অনুগ্রহ করে আমাদের গ্রাহক পরিষেবার সাথে যোগাযোগ করুন।",
          ////////////////// E Wallet Management //////////////////
          ///////////////////// Deposit Record  /////////////////////
          "Deposit Record": "জমার রেকর্ড",
          "Select Date": "তারিখ নির্বাচন করুন",
          Status: "স্থিতি",
          "DEPOSIT AMOUNT": "জমার পরিমাণ",
          time: "সময়",
          depositamt: "জমার পরিমাণ",
          Approved: "অনুমোদিত",
          Rejected: "প্রত্যাখ্যাত",
          Pending: "মুলতুবি",
          "Waiting For Payment": "পেমেন্টের জন্য অপেক্ষা",
          Processing: "প্রক্রিয়াকরণ",
          Failed: "ব্যর্থ",
          "Withdraw Record": "উত্তোলনের রেকর্ড",
          "Add E-Wallet": "ই-ওয়ালেট যোগ করুন",
          logoutMsg: "আপনি কি লগআউট করতে চান?",
          NoStay: "না, থাকুন",
          YesLeave: "হ্যাঁ, ছাড়ুন",
          ////////////////////////////////////// OtpPopUp   ///////////////////////////
          "Number is already registered!": "নম্বর ইতিমধ্যে নিবন্ধিত!",
          "Failed to send OTP, please try again.":
            "OTP পাঠাতে ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন।",
          "Verified successfully": "সফলভাবে যাচাই করা হয়েছে",
          "Invalid OTP, please try again.":
            "অবৈধ OTP, অনুগ্রহ করে আবার চেষ্টা করুন।",
          "Code sent again!": "কোড পুনরায় পাঠানো হয়েছে!",
          "OTP expired, please request a new code.":
            "OTP মেয়াদ শেষ হয়েছে, একটি নতুন কোডের অনুরোধ করুন।",
          "Resend Code": "কোড পুনরায় পাঠান",
          Confirm: "নিশ্চিত করুন",
          "Get Code": "কোড পান",
          "To verify your phone number please":
            "আপনার ফোন নম্বর যাচাই করতে দয়া করে",
          "click on": "ক্লিক করুন",
          "Didn't get the code?": "কোড পাননি?",
          "Please enter the verification code": "যাচাইকরণ কোড প্রবেশ করুন",
          "we sent to": "আমরা পাঠিয়েছি",
          ////////////////////////////////  Support page /////////////////////////////
          "Leave us a contact number and our customer service representative will call you back shortly.":
            "আমাদের একটি যোগাযোগ নম্বর দিন এবং আমাদের গ্রাহক পরিষেবা প্রতিনিধি আপনাকে শীঘ্রই ফোন করবে।",
          "Welcome to our": "আমাদের স্বাগতম",
          "Service Center": "সার্ভিস সেন্টার",
        },
      },
    },
  });

export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import i18nBackend from "i18next-http-backend";

// i18n
//     .use(i18nBackend)
//     .use(initReactI18next)
//     .init({
//         fallbackLng: "1",
//         lng: "1",
//         resources: {
//             1: {
//                 translation: {
//                     specialcharerror: "Does not contain special characters, spaces",
//   india: "India",
//   pakistan: "Pakistan",
//   MoneyFooter: "Transaction",
//   TransactionHeader: "Transaction",
//   //login page
//   maxamterror: "Please choose above 150,000",
//   minamterror: "Please select below 100,000,000",
//   withdrawforbidden:
//     "Unable to Withdraw. Please contact Customer Support",
//   adminforbidden:
//     "Unable to log into game. Please contact customer support",
//   nothingtowithdraw: "Nothing to withdraw",
//   gamechicken: "Game Chicken",
//   casino: "Casino",
//   sport: "Sport",
//   esports: "E-Sports",
//   lottery: "Lottery",
//   confirm: "Confirm",
//   withdrawmin: "Withdraw Min",
//   withdrawmax: "Withdraw Max",
//   amount: "Amount of money",
//   accountexist: "Do you already have an account ?",
//   verification: "Verification",
//   username: "User Name",
//   singlename: "Name",
//   Log_in: "Log In",
//   Register: "Register",
//   phone: "PhoneNumber",
//   password: "Password",
//   example: 'Example : Daga123123 (the letter" ',
//   d: "D",
//   iswritteninCAPITALnoaccents: '" is written in CAPITAL,no accents)',
//   enterphonenumber: "Enter your phone number",
//   retreiveaccinfo:
//     "Retrieving account information, please wait a moment!",
//   donothavacc: "Do not have an account?",
//   //error messages
//   phonepwderror:
//     "user name or password does not match. Please check again.",
//   servererror:
//     "An error occurred while connecting to the system. Please contact Customer Care",
//   invalidphone:
//     "The account you just entered does not exist. Please create an account or contact Customer Care for support",
//   popuperror:
//     "You have entered the wrong password more than 5 times. Please contact Customer Care",

//   //profile page
//   networkerror: "Network Error",
//   info: "INFORMATION",
//   statement: "STATEMENT",
//   history: "BET HISTORY",
//   recharge: "DEPOSIT",
//   withdrawmoney: "WITHDRAW ",
//   transaction: "TRANSACTION",
//   version: "Version :",

//   //home page

//   cricketleague: "CRICKET LEAGUE",
//   rightnow: "Right Now",
//   homepage: "HOME PAGE",
//   support: "24/7 SUPPORT",
//   account: "Account",
//   total: "Total.",
//   nogamesfound: "No Games Found",
//   transferpoints: "Transfer Points",
//   accountbalance: "Account Balance",
//   playnow: "Play Now",
//   cancel: "Cancel",
//   transferallpoints: "Transfer All Points",
//   paymentchannels: "Payment channels",
//   amounttodeposit: " Amount to deposit",
//   exchangerate: "Exchange rate:",
//   mindepositerror: "Minimum deposit from 150,000 VND or more",
//   maxdepositerror: "Deposit amount exceeds the limit of VND 90 million",
//   loadmin: "Load Min",
//   loadmax: "Load Max",
//   point: "point",
//   next: "Next",
//   youwillget: "You will get",
//   rechargeinfo: "RECHARGE INFORMATION",
//   completed: "COMPLETED",
//   errormessage: "Something went wrong, please contact Customer Service",
//   prev: "Prev",
//   rechargebank: "Recharge Bank",
//   receiveraccountname: "Receiver account name",
//   depositamt: "Deposit Amount",
//   withdrawamt: "Withdraw Amount",
//   loading: "Loading..",

//   note: "Note",
//   depositinfo: "Deposit Information",

//   entercodecorrect: "Enter the code correctly",
//   gotocontentmessage: "go to 'Content/Message .'",

//   contenterror:
//     "Without TRANSFER CONTENT, SVW38 will not be able to recognize the transfer from you",
//   copy: "Copy",
//   dealercode: "Dealer code",
//   passwordError:
//     "The password can't have special characters and must contain lowercase, numbers and the length between 6 and 13",
//   pwderror: "Password must contain capital letters",
//   pwderror1: "Password must have lowercase letters",
//   pwderror2: "Password must have numbers (0~9)",
//   pwdlength: "Be 10 characters or more in length",
//   pwdrepeat: "Password Repeat",
//   selectlang: "Select Language",
//   en: "EN",
//   vn: "VN",
//   ch: "CH",
//   currency: "Currency",
//   selectcurrency: "Select Currency",
//   pwdnomatch: "Password does not match",
//   rejecterror:
//     "Your account has not been activated or you have not made a transfer",
//   mybankcard: "My bank card",
//   //withdraw errors
//   minamounterror: "Please choose over 150,000",
//   addbankerror: "Please add a bank  ",
//   bankactivationerror: "Your account has not been activated",
//   pendingreqerror:
//     "You have a pending request. You cannot create a new request while there is a pending request. Please request again later.",
//   insufficientbalance: " INSUFFICIENT_BALANCE ",
//   someerror: "Some Error,Try Again",
//   day: "Day",
//   status: "Status",
//   approved: "Approved",
//   waitforprogress: "Waiting for progressing ",
//   rejected: "Rejected ",
//   processing: "Processing",
//   transfercontents: "Transfer Contents",
//   invoiceimage: " Invoice Image",
//   optional: "Optional",
//   remove: "Remove",
//   addnewtransaction: "Add and Create new transactions",
//   ok: "OK",
//   name: "Account Name",
//   selectbank: "Please select bank",
//   bank: "Bank",
//   nogames: "No Games",
//   accnumber: "Account number",
//   addbankacc: "Add a bank account",
//   balance: "Balance",
//   error: "Error",
//   transfer: "Transfer",
//   Support: "Support",
//   reason: "Reason",
//   bankname: "Bank Name",
//   notransaction: "No Transaction",
//   bankaccountname: "Bank Account Name",
//   bankaccountnum: "Bank Account Number",
//   transferevrythingtomainacc: "Transfer everything to the main account",
//   enteraccnumber: "Please enter your account number",
//   accnameerr: "Account name written in HOA, no mark, no number",
//   accnumbererr:
//     "Account number minimum be 3 chracters and numbers only",
//   transfermessage:
//     "Please just press the button below when you have transferred",
//   depositprocesserror:
//     "There was an error during the deposit process. Please contact Customer Care",
//   fileformaterror:
//     "Image format does not match. Please contact customer service for assistance",
//   successevents: "APP",
//   uppercaseerror: "Shoud not be uppercase",
//   specialcharactererror: "Dont allow Special Characters",
//   selecterror: "Select at least 8",
//   maxerror: "maximum 12",
//   userexisterror: "User name already exist",
//   nameMinLengthError: "Name must be at least 3 characters long",
//   nameMaxLengthError: "Name must not exceed 255 characters",
//   nameValidationError:
//     "Full name without any special characters. Eg: Alden Reyes",
//   selectminerror: "please Select Minimum 10",
//   phoneerror: "Phone Already Taken",
//   invalidcode: "Invalid verification code",
//   phnregistererror:
//     "This phone number has been registered, please contact customer service for support",
//   accountactivatemsg:
//     "Your account has been created successfully. To activate your account please make a deposit",
//   selectmaxerror: "please Select Maximum 10",
//   depositAmount: "Deposit Amount",
//   reality: "Reality",
//   copy: "Copy",
//   depositPageTitle:
//     "To »DEPOSIT MONEY«, transfer to us using the information provided below",
//   bankDeposit: "Bank Deposit",
//   recieveingAccountName: "Receiving Account Name",
//   accNumber: "Account Number",
//   depositAmount: "Deposit Amount",
//   transfercontent: "Transfer Content",
//   depositText1: "Note: Enter the exact code",
//   depositText2: `in the "Content/Message" field`,
//   depositText3: `Without the TRANSFER CONTENT, SVW38 will not be able to recognize the transaction from the customer`,
//   activeAccount: `Activate Account`,

//   comeBack: "Come Back",
//   promotion: "Promotion",
//   enterAmountErr: "Please enter an amount",
//   minWidthdrawAmmErrPart1: "Minimum withdrawal amount from",
//   minWidthdrawAmmErrPart2: "upwards",
//   maxWidthdrawAmmErrPart1: "Withdrawal amount exceeds the limit of",
//   maxWidthdrawAmmErrPart2: "million",
//   notEnoughBalanceErr:
//     "You do not have enough balance to make this transaction",
//   deleteAndCreateNewTrans: "Delete and Create New Transaction",
//   customerSupport: "Customer Support",
//   withdrawFeatureLock:
//     "Your withdrawal feature has been locked, please contact customer support for assistance.",
//   addBank: "Add Bank",
//   bankError1:
//     "To Change or Delete bank information please contact Customer Support",
//   million: "million",
//   depAndWid: "Deposit Withdraw",

//   language: "Language",
//   "My Account": "My Account",
//   logout: "Logout",
//   bankErrMessage:
//     "No bank information available. Please add a bank to continue.",
//   withdrawStep1: "Balance",
//   withdrawStep2: "Choose Bank",
//   depositFirstErrMessage:
//     "Your account has not been activated, please deposit first.",
// temporaryLockWithdraw:
//     "Your account's withdrawal function is temporarily locked, please contact Customer Support.",
//   advanceDeposit: "Advance Deposit Required",
//   noPromotionFound: "No Promotion Found",
//   getPromotionErr: "Please contact Customer Service to get it",
//   surityMessage: "Are you sure that you want to",
//   exit: "exit",
//   noStay: "No, stay",
//   yesLeave: "Yes, leave",
//   bankManagement: "Bank Management",
//   joinedDate: "Joined Date",

//   editProfile: "Edit Profile",
//   resetPassword: "Reset Password",
//   allWallet: "All Wallet",
//   missionDiary: "Mission Diary",

//   collectPoints: "Collect Points",
//   bankAccManagement: "Bank Account Management",
//   helpAndSupp: "Help & suppport",
//   deposits: "Deposits",
//   withdrawals: "Withdrawals",
//   faqs: "FAQs",
//   userAgreement: "User Agreement",
//   privacyPolicy: "Privacy Policy",
//   others: "Others",
//   registerAgent: "Register Agent",
//   loginModalErrorMessage:
//     "Your account is inactive. Please contact the customer support to activate your account.",

//   userNameError1:
//     "Username must be 8-12 characters long and contain only lowercase letters and numbers.",
//   phoneError1: "Phone number must be 10 digits",
//   agentIdMinLengthError:
//     "agent id field must not be greater than 50 characters.",
//   defaultUserNameMessage:
//     "Minimum 8, Maximum 12 Characters. No Special Characters or Space Allowed.",
//   defaultPasswordMessage:
//     "Minimum 8, Maximum 18 Characters. No Special Characters Allowed.",
//   defaultFullNameMessage:
//     "Full name without any special characters. Eg: Alden Reyes",
//   defaultPhoneMessage: "Enter 10 digit phone number",
//                 },
//             },
//             4: {
//                 translation: {
//                     specialcharerror: "Không chứa ký tự đặc biệt, dấu cách",
//                     india: "Ấn Độ",
//                     pakistan: "pa-ki-xtan",
//                     MoneyFooter: "Giao dịch",
//                     TransactionHeader: "Giao dịch",
//                     passwordError:"Mật khẩu không được có ký tự đặc biệt và phải chứa chữ thường, chữ hoa, số và độ dài từ 8 đến 18",
//                     maxamterror: "Vui lòng chọn trên 150,000",
//                     minamterror: "Vui lòng chọn dưới 100,000,000",
//                     withdrawforbidden:
//                         "Không thể rút tiền. Vui lòng liên hệ bộ phận Hỗ trợ khách hàng",
//                     adminforbidden:
//                         "Không thể đăng nhập vào trò chơi. Vui lòng liên hệ với bộ phận hỗ trợ khách hàng",
//                     nothingtowithdraw: "Không có gì để rút",
//                     selectmaxerror: "vui lòng chọn Tối đa 10",

//                     uppercaseerror: "Không nên viết hoa",
//                     specialcharactererror: "Không cho phép ký tự đặc biệt",
//                     selecterror: "Chọn ít nhất 8",
//                     maxerror: "tối đa 12",
//                     userexisterror: "Tên người dùng đã tồn tại",
//                     nameMinLengthError: "Tên phải có ít nhất 3 ký tự",
//                     nameMaxLengthError: "Tên không được vượt quá 255 ký tự",
//                     selectminerror: "vui lòng chọn Tối thiểu 10",
//                     phoneerror: "Điện thoại đã được lấy",
//                     invalidcode: "Mã xác nhận không hợp lệ",
//                     phnregistererror:
//                         "Số điện thoại này đã được đăng ký vui lòng liên hệ CSKH để được hỗ trợ",
//                     accountactivatemsg:
//                         "Tài khoản của bạn đã được tạo thành công. Để kích hoạt tài khoản vui lòng nạp tiền",

//                     networkerror: "lỗi mạng",
//                     successevents: "Ứng dụng",
//                     notransaction: "Không có giao dịch",
//                     gamechicken: "Đá gà",
//                     casino: "Sòng bài",
//                     sport: "Thể Thao",
//                     esports: "Thể thao điện tử",
//                     lottery: "Xổ Số",
//                     account: "Tài Khoản",
//                     someerror: "Một số lỗi, hãy thử lại",
//                     balance: "THĂNG BẰNG",
//                     nogames: "không có trò chơi",
//                     transferevrythingtomainacc: "Chuyển mọi thứ vào tài khoản chính",
//                     addbankacc: "Thêm tài khoản ngân hàng",
//                     bank: "Ngân Hàng",
//                     accnumber: "Số Tài Khoản",
//                     enteraccnumber: "Vui lòng nhập số tài khoản",
//                     transfer: "chuyển khoản",
//                     name: "Tên Tài Khoản",
//                     accnameerr: "Tên tài khoản viết IN HOA, không dấu, không có số ",
//                     accnumbererr:
//                         "Số tài khoản phải có tối thiểu 3 ký tự và chỉ bao gồm số.",

//                     selectbank: "Vui lòng chọn ngân hàng",
//                     invoiceimage: "Hình ảnh hóa đơn",
//                     optional: "Không bắt buộc",
//                     remove: "Di dời",
//                     transfermessage:
//                         "Vui lòng chỉ nhấn nút dưới đây sau khi đã chuyển khoản",
//                     status: "Trạng thái",
//                     minamounterror: "Vui lòng chọn trên 150,000",
//                     addbankerror: "Vui lòng thêm ngân hàng ",
//                     bankactivationerror: "Tài khoản của bạn chưa được kích hoạt",
//                     pendingreqerror:
//                         "Bạn có yêu cầu đang chờ xử lý. Bạn không thể tạo yêu cầu mới khi đang có yêu cầu chờ xử lý. Vui lòng yêu cầu lại sau.",
//                     insufficientbalance: "Số dư không đủ",
//                     //login page
//                     confirm: "Xác nhận",
//                     withdrawmin: "Rút Min",
//                     withdrawmax: "Rút Max",
//                     mybankcard: "Thẻ ngân hàng của tôi",
//                     accountexist: "Bạn đã có tài khoản ?",
//                     pwdnomatch: "Mật khẩu không phù hợp.",
//                     selectlang: "Chọn Ngôn Ngữ",
//                     pwdrepeat: "Mật khẩu (Lặp lại)",
//                     pwderror2: "   Mật khẩu phải có số (0~ 9)",
//                     pwderror: "Mật khẩu phải có chữ IN HOA",
//                     dealercode: "Mã đại lý",
//                     Log_in: "Đăng Nhập",
//                     Register: "Đăng ký",
//                     phone: "Số điện thoại",
//                     password: "Mật khẩu",
//                     example: 'Ví dụ: daga 123123 (ký tự" ',
//                     d: "Đ",
//                     iswritteninCAPITALnoaccents: '" viết hoa, không dấu)',
//                     enterphonenumber: "Nhập số điện thoại của bạn",
//                     retreiveaccinfo:
//                         "Đang lấy thông tin tài khoản, vui lòng chờ trong giây lát!",
//                     donothavacc: "Bạn chưa có tài khoản?",
//                     //error messages
//                     phonepwderror:
//                         "Tên người dùng hoặc mật khẩu không khớp. Vui lòng kiểm tra lại",
//                     servererror:
//                         "Đã xảy ra lỗi trong quá trình kết nối tới hệ thống. Vui lòng liên hệ Chăm sóc khách hàng",
//                     invalidphone:
//                         "Tài khoản bạn vừa nhập không tồn tại. Vui lòng tạo tài khoản hoặc liên hệ Chăm sóc khách hàng để được hỗ trợ",
//                     popuperror:
//                         "Bạn đã nhập sai mật khẩu quá 5 lần. Vui lòng liên hệ Chăm sóc khách hàng",
//                     //profile page
//                     info: "Thông tin",
//                     recharge: "Nạp Tiền",
//                     withdrawmoney: "Rút Tiền",
//                     transaction: "Giao Dịch",
//                     version: "Phiên bản :",
//                     transferpoints: "điểm trung chuyển",
//                     rechargeinfo: "Thông tin nạp tiền",
//                     errormessage: "Đã xảy ra lỗi, vui lòng liên hệ Chăm sóc khách hàng",
//                     rejecterror:
//                         "Tài khoản chưa kích hoạt hoặc Quý khách chưa chuyển khoản",
//                     //home page

//                     cricketleague: "giải đấu cricket",
//                     rightnow: "Cuộc Ngay",
//                     homepage: "Trang Chủ",
//                     support: "Hỗ Trợ 24/7",
//                     support1: "Hỗ Trợ",
//                     total: "Toàn Bộ.",
//                     nogamesfound: "Không tìm thấy trò chơi nào",
//                     accountbalance: "Số dư tài khoản",
//                     transfercontents: "Nội dung chuyển khoản",
//                     playnow: "Bắt đầu chơi",
//                     cancel: "Hủy bỏ",
//                     transferallpoints: "chuyển tất cả các điểm",
//                     paymentchannels: "Các kênh thanh toán",
//                     amounttodeposit: " Số tiền cần nạp ",
//                     exchangerate: "Tỷ lệ đổi:",
//                     mindepositerror: "Số tiền gửi tối thiểu từ 150,000 VNĐ trở lên",
//                     maxdepositerror: "Số tiền gửi vượt quá giới hạn 90 triệu VNĐ",
//                     loadmin: "Nạp Min:",
//                     loadmax: "Nạp Max",
//                     next: "Tiếp theo",
//                     point: "điểm",
//                     youwillget: "Bạn sẽ nhận được",
//                     completed: "Hoàn Tất",
//                     prev: "trước",
//                     rechargebank: "Ngân hàng nạp tiền",
//                     receiveraccountname: "Tên tài khoản nhận",
//                     reason: "Lý do",
//                     depositamt: "Số tiền nạp",
//                     withdrawamt: "Số Tiền Rút",
//                     loading: "Đang tải",
//                     note: "Lưu ý",
//                     depositinfo: "Thông tin tiền gửi",
//                     entercodecorrect: "Điền chính xác mã",
//                     gotocontentmessage: 'vào mục "Nội dung/Lời nhắn',
//                     contenterror:
//                         "Thiếu NỘI DUNG CHUYỂN KHOẢN, SVW38 sẽ không thể nhận ra khoản chuyển từ Quý khách",
//                     copy: "sao chép",
//                     username: "tên tài khoản",
//                     singlename: "tên",
//                     pwderror1: "Mật khẩu phải có chữ thường",
//                     pwdlength: " Có độ dài từ 10 ký tự trở lên",
//                     en: "",
//                     vn: "",
//                     ch: "",
//                     currency: "Tiền Tệ",
//                     selectcurrency: "Chọn Tiền Tệ",
//                     verification: "Mã xác nhận",

//                     amount: "Số tiền",
//                     day: "Ngày",
//                     error: "lỗi",
//                     approved: "Đã phê duyệt",
//                     waitforprogress: "Chờ xử lý",
//                     rejected: "Từ chối",
//                     bankname: "tên ngân hàng",
//                     bankaccountname: "tên tài khoản ngân hàng",
//                     bankaccountnum: "số tài khoản ngân hàng",
//                     processing: "Đang xử lý",
//                     addnewtransaction: "Xóa và Tạo giao dịch mới",
//                     ok: "Được rồi",
//                     fileformaterror:
//                         "Định dạng ảnh không phù hợp. Vaccnameerrui lòng liên CSKH để được hỗ trợ ",
//                     depositprocesserror:
//                         "Đã có lỗi trong quá trình nạp tiền. Vui lòng liên hệ Chăm sóc khách hàng",
//                     depositAmount: "Số tiền nạp",
//                     reality: "Thực tế",
//                     copy: "Sao chép",
//                     depositPageTitle:
//                         "Để » NẠP TIỀN « Chuyển khoản cho chúng tôi theo thông tin bên dưới đây",
//                     bankDeposit: "Ngân hàng nạp tiền",
//                     recieveingAccountName: "Tên tài khoản nhận",
//                     accNumber: "Số tài khoản",
//                     depositAmount: "Số tiền nạp",
//                     transfercontent: "Nội dung chuyển khoản",
//                     depositText1: "Lưu ý: Điền chính xác mã",
//                     depositText2: `vào mục "Nội dung/Lời nhắn"`,
//                     depositText3: `Thiếu NỘI DUNG CHUYỂN KHOẢN, SVW38 sẽ không thể nhận ra khoản
//           chuyển từ Quý khách`,
//                     activeAccount: `Kích hoạt tài khoản`,

//                     comeBack: `Quay lại`,
//                     promotion: `Khuyến mãi`,
//                     enterAmountErr: `Vui lòng nhập số tiền`,
//                     minWidthdrawAmmErrPart1: `Số tiền rút tối thiểu từ`,
//                     minWidthdrawAmmErrPart2: `trở lên`,
//                     maxWidthdrawAmmErrPart1: `Số tiền rút vượt quá giới hạn`,
//                     maxWidthdrawAmmErrPart2: `triệu`,
//                     notEnoughBalanceErr: `Bạn không có đủ số dư để thực hiện giao dịch này`,
//                     deleteAndCreateNewTrans: `Xóa và Tạo giao dịch mới`,
//                     customerSupport: `Hỗ trợ khách hàng`,
//                     withdrawFeatureLock: `Tính năng rút tiền của bạn đã bị khoá, vui lòng liên hệ CS để được hỗ trợ.`,
//                     addBank: `Thêm ngân hàng`,
//                     bankError1: `Để Thay đổi hoặc Xóa thông tin ngân hàng vui lòng liên hệ Chăm sóc
//           khách hàng`,
//                     million: `triệu`,
//                     depAndWid: "Nạp rút",
//                     language: "Ngôn ngữ",
//                     account: "Tài khoản",
//                     logout: "Đăng xuất",

//                     bankErrMessage:
//                         "Chưa có thông tin ngân hàng. Vui lòng thêm ngân hàng để tiếp tục.",
//                     withdrawStep1: "Số dư",
//                     withdrawStep2: "Chọn ngân hàng",
//                     depositFirstErrMessage:
//                         "Tài khoản của bạn chưa được kích hoạt vui lòng gửi tiền trướ",
//                     temporaryLockWithdraw:
//                         "Tài khoản của bạn đang tạm khóa chức năng rút tiền, vui lòng liên hệ Chăm sóc khách hàng",
//                     advanceDeposit: "Yêu cầu đặt cọc trước",

//                     noPromotionFound: "Không tìm thấy khuyến mãi",
//                     getPromotionErr: "Vui lòng liên hệ Dịch vụ Khách hàng để nhận được",

//                     surityMessage: "Bạn có chắc chắn rằng bạn muốn",
//                     exit: "thoát ra",
//                     noStay: "Không, ở lại",
//                     yesLeave: "Vâng, rời đi",

//                     bankManagement: "Quản lý ngân hàng",
//                     joinedDate: "Ngày tham gia",
//                     editProfile: "Chỉnh sửa Hồ sơ",
//                     resetPassword: "Đặt lại Mật khẩu",
//                     allWallet: "Tất cả Ví",
//                     missionDiary: "Nhật ký Nhiệm vụ",
//                     collectPoints: "Thu thập Điểm",
//                     bankAccManagement: "Quản lý Tài khoản Ngân hàng",
//                     helpAndSupp: "Trợ giúp & Hỗ trợ",
//                     deposits: "Gửi tiền",
//                     withdrawals: "Rút tiền",
//                     faqs: "Câu hỏi Thường gặp",
//                     userAgreement: "Thỏa thuận Người dùng",
//                     privacyPolicy: "Chính sách Riêng tư",
//                     others: "Khác",
//                     registerAgent: "Đăng ký Đại lý",
//                     loginModalErrorMessage:
//                         "Tài khoản của bạn đang không hoạt động. Vui lòng liên hệ với dịch vụ khách hàng để kích hoạt tài khoản của bạn.",
//                     userNameError1:
//                         "Tên người dùng phải dài từ 8-12 ký tự và chỉ bao gồm chữ thường và số.",
//                     phoneError1: "Số điện thoại phải có 10 chữ số",
//                     agentIdMinLengthError: "Trường id đại lý không được vượt quá 50 ký tự."

//                 },
//             },
//             2: {
//                 translation: {
//                     specialcharerror: "विशेष वर्ण, रिक्त स्थान शामिल नहीं है",
//                     india: "भारत",
//                     pakistan: "पाकिस्तान",
//                     MoneyFooter: "लेन-देन",
//                     TransactionHeader: "लेन-देन",
//                     maxamterror: "कृपया 150,000 से अधिक चुनें",
//                     minamterror: "कृपया 100,000,000 से नीचे चुनें",
//                     withdrawforbidden:
//                         "निकालने में असमर्थ। कृपया ग्राहक सहायता से संपर्क करें",
//                     adminforbidden:
//                         "गेम में लॉग इन करने में असमर्थ। कृपया ग्राहक सहायता से संपर्क करें",
//                     nothingtowithdraw: "वापस लेने के लिए कुछ नहीं",
//                     selectmaxerror: "कृपया अधिकतम 10 का चयन करें",
//                     uppercaseerror: "अपरकेस नहीं होना चाहिए",
//                     specialcharactererror: "कोई खास अक्षर की अनुमति नहीं है",
//                     selecterror: "कम से कम 8 का चयन करें",
//                     maxerror: "अधिकतम 12",
//                     userexisterror: "उपयोगकर्ता नाम मौजूद है",
//                     nameMinLengthError: "नाम कम से कम 3 अक्षर का होना चाहिए",
//                     nameMaxLengthError: "नाम 255 अक्षरों से अधिक नहीं होना चाहिए",
//                     selectminerror: "कृपया न्यूनतम 10 का चयन करें",
//                     phoneerror: "फ़ोन पहले ही ले लिया गया है",
//                     invalidcode: "अवैध सत्यापन संकेत",
//                     phnregistererror:
//                         "यह फोन नंबर पंजीकृत किया गया है, कृपया समर्थन के लिए ग्राहक सेवा से संपर्क करें",
//                     accountactivatemsg:
//                         "आपका खाता सफलतापूर्वक बना दिया गया है। अपने खाते को सक्रिय करने के लिए कृपया जमा करें",

//                     networkerror: "नेटवर्क त्रुटि",
//                     notransaction: "कोई लेन-देन नहीं",
//                     gamechicken: "खेलमुर्गी",
//                     casino: "कैसीनो",
//                     sport: "खेल",
//                     esports: "ई-खेल",
//                     lottery: "लॉटरी",
//                     support1: "सहायता",
//                     someerror: "कुछ त्रुटि, पुन: प्रयास करें",
//                     error: "गलती",
//                     transfer: "स्थानांतरण",
//                     nogames: "खेलना बंद",
//                     balance: "संतुलन",
//                     transferevrythingtomainacc: "मुख्य खाते में सब कुछ स्थानांतरित करें",
//                     addbankacc: "एक बैंक खाता जोड़ें",
//                     bank: "किनारा ",
//                     accnumber: "खाता संख्या",
//                     enteraccnumber: "कृपया अपना खाता नंबर दर्ज करें",
//                     name: "खाता नाम",
//                     accnameerr:
//                         "खाता नाम बड़े अक्षरों में, कोई उच्चारण नहीं, कोई संख्या नहीं",
//                     accnumbererr:
//                         "खाता संख्या कम से कम 3 अंकों की होनी चाहिए और केवल संख्याओं में होनी चाहिए।",

//                     selectbank: "कृपया एक बैंक चुनें",
//                     invoiceimage: "चालान छवि",
//                     optional: "वैकल्पिक",
//                     remove: "निकालना",
//                     transfermessage:
//                         "कृपया ट्रांसफर करने के बाद ही नीचे दिए गए बटन को दबाएं",
//                     fileformaterror:
//                         "छवि प्रारूप मेल नहीं खाता। कृपया सहायता के लिए ग्राहक सेवा से संपर्क करें",
//                     depositprocesserror:
//                         "जमा प्रक्रिया के दौरान एक त्रुटि हुई थी। कृपया कस्टमर केयर से संपर्क करें",
//                     status: "दर्जा",
//                     minamounterror: "कृपया 150,000 से अधिक चुनें ",
//                     addbankerror: "   कृपया एक बैंक जोड़ें",
//                     bankactivationerror: "आपका खाता सक्रिय नहीं किया गया है",
//                     pendingreqerror:
//                         "आपका एक अनुरोध लंबित है। लंबित अनुरोध होने पर आप नया अनुरोध नहीं बना सकते हैं। कृपया बाद में पुनः अनुरोध करें।",
//                     insufficientbalance: "अपर्याप्त शेषराशि ",
//                     //login page
//                     day: "दिन",
//                     withdrawamt: "निकाली गयी राशि",
//                     confirm: "पुष्टि करना",
//                     withdrawmin: "न्यूनतम निकासी",
//                     withdrawmax: "मैक्स को वापस लें",
//                     amount: "पैसे की राशि",
//                     mybankcard: "मेरा बैंक कार्ड",
//                     rejecterror:
//                         "आपका खाता सक्रिय नहीं किया गया है या आपने स्थानांतरण नहीं किया है",

//                     accountexist: "क्या पहले से आपका खाता है ?",
//                     verification: "सत्यापन",
//                     pwdnomatch: "पासवर्ड मैच नहीं कर रहा है।",
//                     selectcurrency: "मुद्रा चुनें",
//                     currency: "मुद्रा",
//                     en: "",
//                     vn: "",
//                     ch: "",
//                     selectlang: "भाषा चुने",
//                     pwdrepeat: "पासवर्ड (दोहराएँ)",
//                     pwdlength: "लंबाई में 10 वर्ण या अधिक हों",
//                     pwderror2: "पासवर्ड में नंबर होने चाहिए (0~9)",
//                     pwderror: "पासवर्ड में बड़े अक्षर होने चाहिए",
//                     pwderror1: "पासवर्ड में छोटे अक्षर होने चाहिए",
//                     username: "उपयोगकर्ता नाम",
//                     singlename: "नाम",
//                     Log_in: "लॉग इन करें",

//                     Register: "पंजीकरण करवाना",
//                     phone: "फ़ोन नंबर",
//                     password: "पासवर्ड",
//                     example: 'उदाहरण : दागा 123123 (अक्षर" ',
//                     d: "डी",
//                     iswritteninCAPITALnoaccents:
//                         '" राजधानी में लिखा है, कोई उच्चारण नहीं)',
//                     enterphonenumber: "अपना फोन नंबर डालें",
//                     retreiveaccinfo:
//                         "खाता जानकारी पुनर्प्राप्त कर रहा है, कृपया एक क्षण प्रतीक्षा करें!",
//                     donothavacc: "कोई खाता नहीं है?",
//                     //error messages
//                     phonepwderror:
//                         "उपयोगकर्ता नाम या पासवर्ड मेल नहीं खाता है। कृपया फिर से जांचें।",
//                     servererror:
//                         "सिस्टम से कनेक्ट करते समय एक त्रुटि हुई। कृपया कस्टमर केयर से संपर्क करें",
//                     invalidphone:
//                         "आपके द्वारा अभी दर्ज किया गया खाता मौजूद नहीं है। कृपया एक खाता बनाएँ या समर्थन के लिए ग्राहक सेवा से संपर्क करें",
//                     popuperror:
//                         "आपने 5 से अधिक बार गलत पासवर्ड दर्ज किया है। कृपया कस्टमर केयर से संपर्क करें",
//                     //profile page
//                     info: "जानकारी",
//                     recharge: "फिर से दाम लगाना",
//                     withdrawmoney: "पैसे निकाले",
//                     transaction: "लेन-देन",
//                     version: "संस्करण :",

//                     //home page

//                     cricketleague: "क्रिकेट लीग",
//                     rightnow: "अभी",
//                     homepage: "होम पेज",
//                     support: "24/7 समर्थन",
//                     account: "खाता",
//                     total: "कुल।",
//                     nogamesfound: "कोई गेम नहीं मिला",
//                     transferpoints: "स्थानांतरण बिंदु",
//                     accountbalance: "खाते में शेष",

//                     playnow: "अब खेलते हैं",
//                     cancel: "रद्द करना",
//                     transferallpoints: "सभी बिंदुओं को स्थानांतरित करें",
//                     paymentchannels: "भुगतान चैनल",
//                     amounttodeposit: " जमा करने के लिए खाता ",
//                     exchangerate: "विनिमय दर:",
//                     mindepositerror: "150,000 वीएनडी या अधिक से न्यूनतम जमा",
//                     maxdepositerror: "जमा राशि VND 90 मिलियन की सीमा से अधिक है",
//                     loadmin: "लोड न्यूनतम",
//                     loadmax: "भार अधिकतम",
//                     next: "अगला",
//                     point: "बिंदु",
//                     youwillget: "आपको मिल जायेगा",
//                     rechargeinfo: "रिचार्ज की जानकारी",
//                     completed: "पुरा होना।",
//                     errormessage: "कुछ गलत हुआ, कृपया ग्राहक सेवा से संपर्क करें",
//                     prev: "पहले का",
//                     rechargebank: "रिचार्ज बैंक",
//                     receiveraccountname: "प्राप्तकर्ता खाते का नाम",

//                     depositamt: "जमा राशि",
//                     loading: "लोड हो रहा है",
//                     note: "टिप्पणी",
//                     depositinfo: "जमा जानकारी",
//                     entercodecorrect: "कोड सही दर्ज करें",
//                     gotocontentmessage: '"सामग्री/संदेश" पर जाएं।',
//                     contenterror:
//                         "स्थानांतरण सामग्री के बिना, SVW38 आपसे स्थानांतरण को पहचानने में सक्षम नहीं होगा",
//                     copy: "प्रतिलिपि",
//                     dealercode: "डीलर कोड",
//                     approved: " अनुमत",
//                     waitforprogress: "प्रगति की प्रतीक्षा कर रहा है",
//                     rejected: "अस्वीकार करना",
//                     processing: "प्रसंस्करण",
//                     transfercontents: "स्थानांतरण सामग्री",
//                     ok: "ठीक",
//                     addnewtransaction: "हटाएं और नया लेन-देन बनाएं",
//                     reason: "कारण",
//                     bankname: "बैंक का नाम",
//                     bankaccountname: "बैंक खाते का नाम",
//                     bankaccountnum: "बैंक खाता संख्या",
//                     successevents: "अनुप्रयोग",
//                     depositAmount: "जमा राशि",
//                     reality: "वास्तविकता",
//                     copy: "प्रतिलिपि",
//                     depositPageTitle:
//                         "»पैसे जमा करने के लिए«, नीचे दी गई जानकारी का उपयोग करके हमें धनराशि भेजें।",
//                     bankDeposit: "बैंक जमा",
//                     recieveingAccountName: "प्राप्त करने वाले खाते का नाम",
//                     accNumber: "खाता संख्या",
//                     depositAmount: "जमा राशि",
//                     transfercontent: "हस्तांतरण सामग्री",
//                     depositText1: "नोट: सटीक कोड दर्ज करें",
//                     depositText2: `"सामग्री/संदेश" क्षेत्र में`,
//                     depositText3: `हस्तांतरण सामग्री के बिना, SVW38 ग्राहक से लेनदेन की पहचान नहीं कर पाएगा`,
//                     activeAccount: `खाता सक्रिय करें`,
//                     comeBack: "वापस आएं",
//                     promotion: "प्रोमोशन",
//                     enterAmountErr: "कृपया राशि दर्ज करें",
//                     minWidthdrawAmmErrPart1: "न्यूनतम निकासी राशि",
//                     minWidthdrawAmmErrPart2: "से अधिक",
//                     maxWidthdrawAmmErrPart1: "निकासी राशि सीमा से अधिक",
//                     maxWidthdrawAmmErrPart2: "मिलियन",
//                     notEnoughBalanceErr:
//                         "इस लेन-देन को पूरा करने के लिए आपके पास पर्याप्त बैलेंस नहीं है",
//                     deleteAndCreateNewTrans: "हटाएं और नया लेन-देन बनाएं",
//                     customerSupport: "ग्राहक सहायता",
//                     withdrawFeatureLock:
//                         "आपकी निकासी सुविधा बंद कर दी गई है, कृपया सहायता के लिए ग्राहक सेवा से संपर्क करें।",
//                     addBank: "बैंक जोड़ें",
//                     bankError1:
//                         "बैंक जानकारी बदलने या हटाने के लिए कृपया ग्राहक सहायता से संपर्क करें",
//                     million: "मिलियन",
//                     depAndWid: "जमा निकासी",
//                     language: "भाषा",
//                     account: "खाता",
//                     logout: "लॉगआउट",
//                     bankErrMessage:
//                         "बैंक की जानकारी उपलब्ध नहीं है। कृपया जारी रखने के लिए बैंक जोड़ें।",
//                     withdrawStep1: "शेष राशि",
//                     withdrawStep2: "बैंक चुनें",
//                     depositFirstErrMessage:
//                         "आपका खाता सक्रिय नहीं हुआ है, कृपया पहले धनराशि जमा करें।",
//                     temporaryLockWithdraw:
//                         "आपके खाते की निकासी सुविधा अस्थायी रूप से बंद है, कृपया ग्राहक सहायता से संपर्क करें।",
//                     advanceDeposit: "अग्रिम जमा आवश्यक है",
//                     noPromotionFound: "कोई प्रोमोशन नहीं मिला",
//                     getPromotionErr:
//                         "कृपया इसे प्राप्त करने के लिए ग्राहक सेवा से संपर्क करें",
//                     surityMessage: "क्या आप निश्चित हैं कि आप चाहते हैं",
//                     exit: "बाहर जाएं",
//                     noStay: "नहीं, रुकें",
//                     yesLeave: "हाँ, छोड़ें",
//                     bankManagement: "बैंक प्रबंधन",
//                     joinedDate: "शामिल होने की तारीख",
//                     editProfile: "प्रोफ़ाइल संपादित करें",
//                     resetPassword: "पासवर्ड रीसेट करें",
//                     allWallet: "सभी वॉलेट",
//                     missionDiary: "मिशन डायरी",
//                     collectPoints: "अंक एकत्र करें",
//                     bankAccManagement: "बैंक खाता प्रबंधन",
//                     helpAndSupp: "सहायता और समर्थन",
//                     deposits: "जमा",
//                     withdrawals: "निकासी",
//                     faqs: "पूछे जाने वाले प्रश्न",
//                     userAgreement: "उपयोगकर्ता समझौता",
//                     privacyPolicy: "गोपनीयता नीति",
//                     others: "अन्य",
//                     registerAgent: "एजेंट रजिस्टर करें",
//                     loginModalErrorMessage:
//                         "आपका खाता निष्क्रिय है। कृपया अपने खाते को सक्रिय करने के लिए ग्राहक सहायता से संपर्क करें।",
//                     userNameError1:
//                         "उपयोगकर्ता नाम 8-12 वर्ण लंबा होना चाहिए और केवल लोअरकेस अक्षर और संख्याएं ही शामिल होनी चाहिए।",
//                     phoneError1: "फ़ोन नंबर 10 अंकों का होना चाहिए",
//                     agentIdMinLengthError: "एजेंट आईडी फ़ील्ड 50 अक्षरों से अधिक नहीं होनी चाहिए।"

//                 },
//             },
//         },
//     });

// export default i18n;
