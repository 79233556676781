import React from "react";
import banner1 from "../../../assets/Banner@2x.png";
// import banner2 from "../../../assets/banner/Banner2.webp";
// import banner3 from "../../../assets/banner/Banner3.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Banner = () => {
  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      autoPlay={true}
      infiniteLoop
      stopOnHover
      showStatus={false}
      // axis="Y"
      axis="horizontal"
      interval={3000}
      transitionTime={500}
    >
      <div className="p-7">
        <img className="mt-5 rounded-lg" src={banner1} alt="banner" />
      </div>
      <div className="p-7">
        <img className="mt-5 rounded-lg" src={banner1} alt="banner" />
      </div>
      <div className="p-7">
        <img className="mt-5 rounded-lg" src={banner1} alt="banner" />
      </div>
    </Carousel>
  );
};

export default Banner;
