import React, { useEffect } from "react";
import styles from "./TermsAndCondition.module.css";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Terms and Conditions
        </h1>
        <h2 className={`text-xl font-semibold mb-2 ${styles.termsConditions}`}>
          Table of Contents
        </h2>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
        >
          <li>General Terms</li>
          <li>The Rights and Obligations of the Parties</li>
          <li>Conditions of Bet Acceptance</li>
          <li>Special Terms</li>
          <li>Financial Limitations</li>
          <li>Overask</li>
          <li>Rules Regarding Opening a Gaming Account and Depositing Funds</li>
          <li>Rules for Winnings Payout and Withdrawal</li>
          <li>Inactive Accounts</li>
          <li>Communication with Customer Support Service of BW9</li>
          <li>Cash Out</li>
        </ol>
      </div>
      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          General Terms
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
        >
          <li>
            These Terms and Conditions (
            <span className="italic">
              hereinafter referred to as the "rules"
            </span>
            ) regulate the provision of services on BW9.io, including a public
            adhesion agreement on gambling services between BW9 (the Betting
            company) and the Client (bettor). The agreement is considered
            concluded when the Client registers online on the website.
          </li>
          <li>
            BW9 provides Clients with services for participating in bets on
            sports and other events presented on the website. Clients can create
            betting odds and manage their gaming accounts. All bets are accepted
            according to the current rules and{" "}
            <span className="italic">any</span> special rules for different
            sports.
          </li>
          <li>
            By registering, the Client accepts these rules, agrees to comply
            with them, and acknowledges all applicable charges and commissions.
            The Client also consents to receiving email and SMS notifications
            about new services. If the Client disagrees with the rules, they
            must stop using the services provided by BW9.
          </li>
          <li>
            BW9 can amend these rules, provisions, and payout methods without
            prior personal notice. The terms of previously placed wagers remain
            unchanged, but subsequent wagers are subject to the changed rules.
          </li>
          <li>
            Registration,opening a deposit account, and wagering are allowed
            only for persons 18 years of age or older. Clients are responsible
            for ensuring that online betting is allowed in their countries and{" "}
            <span className="italic">for</span> reporting winnings and losses to
            their local authorities if necessary. By registering, the Client
            guarantees they are at least 18 years old and the rightful owner of
            the funds they place on their gaming account.
          </li>
          <li>
            BW9 assumes the information provided by the Client during
            registration is accurate. BW9 accepts no responsibility if the
            Client provides incorrect information. To prevent cheating and
            conflicts, BW9 may request personal identification documents from
            Clients to verify the information provided during registration.
          </li>
          <li>
            {" "}
            If fraudulent activities are suspected, especially regarding
            financial transactions and wagering, individuals found guilty will
            be held accountable, potentially facing criminal prosecution. If BW9
            discovers that a Client is underage or has engaged in fraudulent or
            illegal activities, it may block the Client's gaming account and
            investigate according to the rules.
          </li>
          <li>
            {" "}
            Opening an account and making a deposit will be done according to
            the appropriate rules.
          </li>
          <li> Payouts are made according to the appropriate rules.</li>
          <li>
            {" "}
            Clients are fully responsible for maintaining the confidentiality of
            their password and account information. BW9 ensures the
            confidentiality of the Client's information but disclaims
            responsibility if it is disclosed to a third party. Operations
            conducted using the Client's login and password are considered valid
            and official, limited only by the account balance. If the Client
            suspects their information has been compromised, they should contact
            BW9 to request a password change.
          </li>
          <li>
            {" "}
            BW9 does not accept responsibility for damage or losses caused by
            the use of the site or its contents, including misuse, connectivity
            issues, communication failures, or errors.
          </li>
          <li>
            {" "}
            Rules specific to each sport take priority over general rules.
          </li>
          <li>
            {" "}
            Although BW9 strives for accuracy, all data on current events are
            for informational purposes only. BW9 accepts no responsibility for
            inaccuracies in scores or times of matches. Clients should always
            use alternative sources of information.
          </li>
          <li>
            {" "}
            BW9 prohibits registration, deposits, and bets from citizens and/or
            residents of certain countries, including Afghanistan, Aruba,
            Australia, Austria, Belarus, Belgium, Bonaire, Curacao, Czech
            Republic, Estonia, France, Germany, Hungary, Iran, Iraq, Ireland,
            Israel, Italy, Kahnawake Mohawk Territory (Canada), Latvia, North
            Korea, Ontario (Canada), Pakistan, Russia, Saba, Serbia, Singapore,
            Slovakia, Slovenia, Spain, St. Maarten, Statia, Sweden, The
            Netherlands, The Republic of Cyprus, occupied territories of Ukraine
            (including but not limited to Crimea, occupied parts of Donetsk,
            Luhansk, Zaporizhzhia, and Kherson regions), United Arab Emirates,
            United Kingdom, USA.
          </li>
          <li>
            {" "}
            Singapore law prohibits the provision of remote gambling services to
            customers physically present in Singapore. BW9 is not obliged to
            inform Clients about such legal provisions or advise on them.
          </li>
          <li>
            {" "}
            In force majeure circumstances beyond the control of the parties,
            including actions of public authorities, natural disasters, and
            others, parties are exempted from their obligations. If such
            circumstances last for more than 30 days, either party can terminate
            the agreement with 15 days' notice. Proof of force majeure is a
            document issued by the Chamber of Commerce. Such circumstances do
            not constitute grounds for BW9 to refuse to refund funds from the
            Client’s gaming account.
          </li>
          <li>
            {" "}
            BW9 reserves the right to refuse registration, deposits, and bets
            from persons who are citizens and/or residents of countries
            sanctioned by government agencies, who are residents of states with
            gaps in anti-money laundering strategies, or who have previously
            been excluded from gambling.
          </li>
          <li>
            {" "}
            All design elements, text, graphics, music, sounds, pictures,
            videos, and other materials on the website are protected by
            copyright and proprietary rights, owned by BW9 or used under
            license. Clients do not gain any intellectual property rights by
            using BW9 services.
          </li>
          <li>
            {" "}
            The agreement is terminated when BW9 notifies the Client of all
            final settlements.
          </li>
          <li>
            {" "}
            Clients may not assign their obligations under the rules to any
            other person or entity.
          </li>
          <li>
            {" "}
            BW9 reserves the right to assign or transfer its rights and
            obligations under the rules without notifying the Client.
          </li>
          <li>
            {" "}
            In all matters not covered by the rules, the parties will be guided
            by the laws of Curacao.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          The Rights and Obligations of the Parties
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="23"
        >
          <li>
            <span className="font-semibold">BW9 shall:</span>
            <ul className={`list-disc list-inside ml-4 ${styles.list}`}>
              <li>
                Register the Client and assign a unique gaming account number.
              </li>
              <li>
                Provide the Client with the ability to manage a gaming account.
              </li>
              <li>Maintain the confidentiality of the Client's information.</li>
              <li>
                Provide 24-hour technical support via email and telephone.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold">BW9 has the right to:</span>
            <ul className={`list-disc list-inside ml-4 ${styles.list}`}>
              <li>
                Suspend services if the Client fails to comply with the rules.
              </li>
              <li>Amend the rules unilaterally.</li>
            </ul>
          </li>
          <li>
            <span className="font-semibold">The Client shall:</span>
            <ul className={`list-disc list-inside ml-4 ${styles.list}`}>
              <li>Read and understand the rules.</li>
              <li>
                Keep their gaming account number and password confidential.
              </li>
              <li>Comply with local tax laws related to gambling winnings.</li>
            </ul>
          </li>
          <li>
            <span className="font-semibold">The Client has the right to:</span>
            <ul className={`list-disc list-inside ml-4 ${styles.list}`}>
              <li>Receive a complete list of bets from BW9.</li>
              <li>
                Refuse to play with BW9 once every 90 days with prior notice.
              </li>
              <li>
                Set limits on their gaming account or request self-exclusion.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold">The Client guarantees that</span>{" "}
            they are not physically present in any restricted territory while
            using BW9 services.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Conditions of Bet Acceptance
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="28"
        >
          <li>
            {" "}
            Bets are accepted based on the line, which is the list of upcoming
            events with their odds.
          </li>
          <li>
            {" "}
            Line changes can occur after any bet, but the terms for already
            placed bets remain unchanged.
          </li>
          <li>
            {" "}
            Bets must be placed before the event starts. Bets placed after the
            event starts are invalid unless they are live bets.
          </li>
          <li>
            {" "}
            The date and time of events in the line are informative. Actual
            event times are used for bet settlement.
          </li>
          <li>
            {" "}
            BW9 is not responsible for spelling errors in player names, team
            names, or event locations.
          </li>
          <li>
            {" "}
            BW9 reserves the right to invalidate bets if there are obvious
            errors in the betting line.
          </li>
          <li>
            {" "}
            Bets cannot be changed or canceled after they are placed and
            verified.
          </li>
          <li>
            {" "}
            Technical issues are no reason to change or cancel a bet if the
            wager has been registered on the server.
          </li>
          <li>
            {" "}
            Loss of a password does not justify changing or canceling a bet or
            payout request.
          </li>
          <li>
            {" "}
            Each Client is entitled to one gaming account unless otherwise
            approved by BW9.
          </li>
          <li> Clients cannot allow others to use their gaming accounts.</li>
          <li>
            {" "}
            BW9 reserves the right to apply limitations or refuse bets without
            explanation.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Special Terms
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="40"
        >
          <li>
            {" "}
            If an event is postponed for more than 36 hours, all bets on the
            event are settled at the odds of 1.
          </li>
          <li>
            {" "}
            If a match is transferred to a neutral ground, the wagers stay
            unchanged. If it is transferred to the opponent's ground, the wagers
            are refunded.
          </li>
          <li>
            {" "}
            The original result of an event remains for betting purposes, even
            if the result is later changed or canceled.
          </li>
          <li>
            {" "}
            If more than one player (team) is announced as the competition
            winner, bets on these players (teams) are settled at the odds of 1.
          </li>
          <li>
            {" "}
            BW9 announces actual results based on official records and other
            sources. In case of discrepancies, BW9 makes the final decision.
          </li>
          <li>
            {" "}
            BW9 reserves the right to cancel bets if it suspects the bet was
            placed after the outcome was known or if the Client gained an unfair
            advantage.
          </li>
          <li>
            {" "}
            BW9 uses its own data for live event bets settlement. If results
            cannot be determined, these events are settled at the odds of 1.
          </li>
          <li>
            {" "}
            Clients should check their betting slips for accuracy. Incorrect
            slips may be refunded, and bets canceled.
          </li>
          <li>
            {" "}
            If a Client incurs a negative balance due to revaluation of bets,
            the account will be blocked until the balance is corrected.
          </li>
          <li>
            {" "}
            Complaints about disputed issues must be submitted via email within
            10 days of the event's result. After 10 days, no complaints will be
            accepted.
          </li>
          <li>
            {" "}
            BW9 treats all complaints seriously and aims to resolve them fairly
            and timely. Clients must provide clear information about their
            complaints.
          </li>
          <li>
            {" "}
            The Customer Support Team aims to respond to complaints within 30
            days. Complex issues may take longer.
          </li>
          <li>
            {" "}
            BW9 records all communications with Clients for protection and
            customer care purposes.
          </li>
          <li>
            {" "}
            Clients should contact the Curacao Commissioner only if they believe
            BW9 is in breach of the license.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Financial Limitations
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="54"
        >
          <li>
            {" "}
            Maximum odds in parlays are 2000. Winnings will be calculated as if
            the odds were 2000 if they exceed this.
          </li>
          <li>
            {" "}
            The maximum bet amount depends on the event and sport. BW9 can
            change this amount without notice.
          </li>
          <li>
            {" "}
            The maximum payout for one bet is the equivalent of 1,000,000 USD.
          </li>
          <li>
            {" "}
            Clients must wager at least 70% of their deposited amount to make a
            withdrawal.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Overask
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="58"
        >
          <li>
            {" "}
            Clients can place an "Overask" bet exceeding the maximum amount if
            their account balance is sufficient. Minimum Overask amount is 100
            USD.
          </li>
          <li>
            Overask bets will be reviewed within 5 minutes and either accepted
            or rejected.
          </li>
          <li>
            {" "}
            Maximum payout of an Overask is the equivalent of 1,000,000 USD.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Rules Regarding Opening a Gaming Account and Depositing Funds
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="61"
        >
          <li>
            {" "}
            To open a gaming account, Clients must register on the website and
            deposit funds via accepted methods.
          </li>
          <li>
            {" "}
            Clients set a password during registration, which can be changed
            anytime.
          </li>
          <li>
            {" "}
            Transactions on the gaming account will be performed in the
            specified currency.
          </li>
          <li> BW9 does not offer credit for gaming transactions.</li>
          <li>
            {" "}
            Deposits are credited to the gaming account as soon as the payment
            provider notifies BW9 of the successful deposit.
          </li>
          <li>
            {" "}
            Payment is considered made when funds are received in BW9's
            accounts.
          </li>
          <li>
            {" "}
            Clients pay all costs of money transfers. Receipts should be kept
            for 3 years.
          </li>
          <li>
            {" "}
            BW9 is not responsible for delays due to factors beyond its control.
          </li>
          <li>
            {" "}
            Credit/Debit Card deposits must match the personal data registered
            to the gaming account.
          </li>
          <li>
            {" "}
            Clients are not required to have a bank account in the currency of
            the gaming account for card deposits.
          </li>
          <li> Payment cards must be enabled for online transactions.</li>
          <li> Transaction times and commissions may vary.</li>
          <li>
            {" "}
            BW9 is not responsible for additional commissions and limitations of
            financial intermediaries.
          </li>
          <li>
            {" "}
            BW9 reserves the right to cancel deposits if fraud is suspected.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Rules for Winnings Payout and Withdrawal
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="75"
        >
          <li>
            {" "}
            Winnings from settled bets are credited immediately after the event
            results are announced.
          </li>
          <li>
            {" "}
            Clients can request withdrawals at any time. Payouts will be made in
            the same currency used during registration.
          </li>
          <li>
            {" "}
            Withdrawals are performed only after verifying the gaming account
            holder's identity.
          </li>
          <li>
            {" "}
            Clients bear all costs for fund transfers. Settlements are made
            according to the applicable rules.
          </li>
          <li>
            {" "}
            For withdrawals exceeding 100 USD, Clients must pass a verification
            procedure by providing necessary documents.
          </li>
          <li>
            {" "}
            Clients are responsible for providing authentic information for
            withdrawal orders.
          </li>
          <li>
            {" "}
            Current withdrawal terms, conditions, and fees are displayed on the
            "Withdrawal" page.
          </li>
          <li>
            {" "}
            VISA or MasterCard withdrawals do not require a bank account in the
            currency of the gaming account.
          </li>
          <li>
            {" "}
            Funds are credited to the Client's bank account within 3 business
            days.
          </li>
          <li>
            {" "}
            BW9 has a strict anti-fraud policy and reserves the right to suspend
            accounts and report fraudulent activities to authorities.
          </li>
          <li>
            {" "}
            BW9 may invalidate bets and refuse to fulfill obligations if unfair
            events are suspected.
          </li>
          <li>
            {" "}
            BW9 reserves the right to close accounts if it loses trust in the
            Client.
          </li>
          <li>
            {" "}
            BW9 is not responsible for technical failures or losses due to
            third-party actions.
          </li>
          <li>
            {" "}
            Clients must wager at least 70% of the deposited amount to make a
            withdrawal.
          </li>
          <li>
            {" "}
            BW9 reserves the right to withdraw funds and impose commissions and
            limits.
          </li>
          <li> BW9 may verify account holder's identity at any time.</li>
          <li>
            {" "}
            If verification documents are not provided within 30 days, BW9
            reserves the right to deduct the Client's balance.
          </li>
          <li> Additional proofs of identity may be requested at any time.</li>
          <li>
            {" "}
            BW9 may cancel bets and return funds if verification is not
            completed satisfactorily.
          </li>
          <li>
            {" "}
            BW9 is not responsible for providing Client information to
            government agencies. Clients allow BW9 to collect and use personal
            data.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Inactive Accounts
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="95"
        >
          <li>
            {" "}
            An inactive account is one with no successful deposits, bets, or
            withdrawals for over 12 months.
          </li>
          <li>
            {" "}
            Maintenance of an active account is free, but an inactive account
            incurs an administrative fee.
          </li>
          <li>
            {" "}
            The account owner will be notified of inactivity and the first
            charge for servicing the account.
          </li>
          <li>
            {" "}
            An inactive account can be activated by making a deposit, placing a
            bet, or withdrawing funds.
          </li>
          <li>
            {" "}
            The fee for servicing inactive accounts is 5 Euro or equivalent in
            the gaming account currency.
          </li>
          <li>
            {" "}
            BW9 reserves the right to close an inactive account once the balance
            is zero.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Communication with Customer Support Service of BW9
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="101"
        >
          <li>
            Clients must communicate respectfully with Customer Support and
            follow the operator's instructions.
          </li>
          <li>
            {" "}
            Clients should not use offensive language or contact support for
            unrelated issues.
          </li>
          <li>
            {" "}
            If a Client violates these rules, the operator will warn them and
            demand compliance.
          </li>
          <li>
            {" "}
            Continued violations may result in temporary suspension of the
            Client's right to contact support.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          Cash Out
        </h1>
        <ol
          className={`list-decimal list-inside ml-4 space-y-1 ${styles.list}`}
          start="105"
        >
          <li>
            {" "}
            The "CashOut" option allows Clients to settle their bets before the
            outcome is known.
          </li>
          <li>
            {" "}
            The "CashOut" amount depends on the course of events and changes in
            odds.
          </li>
          <li> "CashOut" is available only for unsettled bets.</li>
          <li> "CashOut" is active for both Pre-match and Live betting.</li>
          <li>
            {" "}
            "CashOut" is available separately for each bet in a combination.
          </li>
          <li>
            {" "}
            "CashOut" is available only for the whole combination, not for
            separate events.
          </li>
          <li> "CashOut" is available only if the markets are still open.</li>
          <li> "CashOut" can be applied in the "Account History" tab.</li>
          <li>
            {" "}
            The settlement amount will be displayed next to each bet for which
            "CashOut" is available.
          </li>
          <li>
            {" "}
            BW9 does not guarantee the "CashOut" option will always be
            available.
          </li>
          <li>
            {" "}
            If a "CashOut" request is successful, the bet is settled instantly,
            and funds are credited to the gaming account.
          </li>
          <li>
            {" "}
            BW9 reserves the right to accept or reject any "CashOut" request.
          </li>
        </ol>
      </div>

      <div className="p-10">
        <hr className="border-t border-gray-300 my-4" />
      </div>

      <div className="p-6">
        <h1 className={`text-2xl font-bold mb-4 ${styles.termsConditions}`}>
          In case of discrepancies between the rules in different languages, the
          rules in English will apply. Terms & Conditions updated on May 20,
          2024..
        </h1>
      </div>
    </>
  );
};

export default TermsAndCondition;
