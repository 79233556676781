export default function TimeConverter(serverdate) {
    const date = serverdate?.toISOString([])
    const utcDateWithoutMillis = date?.slice(0, -5) + "Z";
    const utcDate = new Date(utcDateWithoutMillis);

    // Step 2:
    const offsetMinutes = utcDate?.getTimezoneOffset();

    // Step 3:
    const localTime = new Date(utcDate?.getTime() - offsetMinutes * 60 * 1000);

    // Display Local Time
    const localTimeString = localTime?.toLocaleString();

    return localTimeString;
}

export function convertLocalTimeToUTC(date) {
    const d = new Date(date);

    const year = d.getUTCFullYear();
    const month = ('0' + (d.getUTCMonth() + 1)).slice(-2); // Months are 0-based
    const day = ('0' + d.getUTCDate()).slice(-2);
    const hours = ('0' + d.getUTCHours()).slice(-2);
    const minutes = ('0' + d.getUTCMinutes()).slice(-2);
    const seconds = ('0' + d.getUTCSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function convertUTCToLocalTime(dateTimeString) {
    if (!dateTimeString || dateTimeString == 'NA') {
        return;
    }

    // const isValidDate = (date) => !isNaN(Date.parse(date));
    // if (!isValidDate(dateTimeString)) {
    //   return;
    // }

    let d;
    if (dateTimeString?.includes('T')) {
        d = new Date(dateTimeString);
    } else {
        const parts = dateTimeString?.split(' ');
        const datePart = parts[0];
        const timePart = parts[1];
        const [year, month, day] = datePart?.split('-');
        const [hours, minutes, seconds] = timePart?.split(':');
        d = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
    }

    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2); // Months are 0-based
    const day = ('0' + d.getDate()).slice(-2);
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    const seconds = ('0' + d.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}