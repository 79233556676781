import styles from "./QuestPopup.module.css";
import closeIcon from "../../../assets/Close@2x.png";
import QuestHub from "./QuestHub";
import { useState } from "react";
import backIcon from '../../../assets/quest hub/Arrow Left@2x.png';
import PreviousQuest from "./PreviousQuest";

const QuestPopup = ({ closeModal }) => {

  const [activeTab, setActiveTab] = useState({ questHub: true, previousQuest: false });

  return (
    <div className={`${styles.modalOverlay} fixed z-50 inset-0 `}>
      <div className={styles.modalWrapper} style={{maxHeight: activeTab?.questHub ? '639px' : '750px'}}>
        <div className={styles.titleSection}>
          <div className="flex items-center gap-5">
            {activeTab?.previousQuest &&
              <div className="max-w-[9px] w-full max-h-[15px] h-full cursor-pointer" onClick={()=>setActiveTab({questHub: true, previousQuest: false})}>
                <img src={backIcon} alt="back" className="imgStyle" />
              </div>
            }
            <div className={styles.title}>{activeTab?.questHub ? 'Quest Hub' : 'Previous Quest'}</div>
          </div>
          <div
            className="max-w-[15px] w-full max-h-[15px] h-full cursor-pointer"
            onClick={closeModal}
          >
            <img src={closeIcon} alt="close" />
          </div>
        </div>
        {activeTab?.questHub &&
          <QuestHub setActiveTab={setActiveTab} />
        }

        {activeTab?.previousQuest &&
          <PreviousQuest/>
        }

      </div>
    </div>
  );
};
export default QuestPopup;
