import React, { useEffect, useRef, useState } from "react";
import usFlag from "../../../assets/images/flags/US.png";
import vnFlag from "../../../assets/images/flags/Viet.png";
import phFlag from "../../../assets/images/flags/Phili.png";
import bdFlag from "../../../assets/images/flags/Bangladesh.png";
import { HiCheck } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    i18n.changeLanguage(language);
    setIsOpen(false); // Close dropdown after selecting language
  };

  const getFlag = (language) => {
    switch (language) {
      case "vi":
        return vnFlag;
      case "ph":
        return phFlag;
      case "bn":
        return bdFlag;
      case "en":
      default:
        return usFlag;
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={{ background: "#3D3279" }}
        className="inline-flex justify-center w-full px-2 py-2 text-sm font-medium text-gray-700 rounded-lg shadow-sm "
      >
        <img
          src={getFlag(selectedLanguage)}
          alt="Selected language flag"
          className="w-5 h-5"
        />
      </button>

      {isOpen && (
        <div
          className="absolute right-0 mt-2 origin-top-right rounded-md shadow-lg w-32 lg:w-52 ring-1 ring-black ring-opacity-5 focus:outline-none"
          style={{
            background: "#14132D",
            boxShadow: "0px 3px 17px #14132D",
            border: "1px solid #8C8BDE",
            borderRadius: "13px",
            zIndex: 1001,
          }}
        >
          <div className="py-1">
            <button
              onClick={() => handleLanguageChange("vi")}
              className="flex items-center justify-between w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-500 hover:rounded-t-md"
            >
              <span className="flex items-center text-white text-xs lg:text-[14px]">
                <img
                  src={vnFlag}
                  alt="Vietnamese flag"
                  className="w-5 h-5 mr-2"
                />
                Tiếng Việt
              </span>
              {selectedLanguage === "vi" && (
                <HiCheck className="w-5 h-5 font-black text-white" />
              )}
            </button>
            <button
              onClick={() => handleLanguageChange("ph")}
              className="flex items-center justify-between w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-500"
            >
              <span className="flex items-center text-white text-xs lg:text-[14px]">
                <img
                  src={phFlag}
                  alt="Filipino flag"
                  className="w-5 h-5 mr-2"
                />
                Filipino (Tagalog)
              </span>
              {selectedLanguage === "ph" && (
                <HiCheck className="w-5 h-5 font-black text-white" />
              )}
            </button>
            <button
              onClick={() => handleLanguageChange("en")}
              className="flex items-center justify-between w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-500"
            >
              <span className="flex items-center text-white text-xs lg:text-[14px]">
                <img src={usFlag} alt="US flag" className="w-5 h-5 mr-2" />
                English
              </span>
              {selectedLanguage === "en" && (
                <HiCheck className="w-5 h-5 font-black text-white" />
              )}
            </button>
            <button
              onClick={() => handleLanguageChange("bn")}
              className="flex items-center justify-between w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-500 hover:rounded-b-md"
            >
              <span className="flex items-center text-white text-xs lg:text-[14px]">
                <img src={bdFlag} alt="US flag" className="w-5 h-5 mr-2" />
                Bangla
              </span>
              {selectedLanguage === "bn" && (
                <HiCheck className="w-5 h-5 font-black text-white" />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
